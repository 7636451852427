import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import { Observable, ReplaySubject } from 'rxjs';
import { LearnerInvite } from 'src/app/auth/learner-registration/models/learner-invite';
import {
  LearnerInvitationVerifyDto,
  LearnerPortalRegistrationDto,
} from 'src/app/auth/learner-registration/models/learner-portal-registration-dto';
import { OperationResult, OperationResultWithValue } from '../../models/operation-result';
import { ActivityLogEntry } from '../../models/activity-log-entry';
import { CaseHistoryItem, CaseSummary } from '../../models/case';
import { FileDocument } from '../../models/file-document';
import { FamilyMember, FinalEcoRatingPkStatus, Learner, LearnerCreate, LearnerReadonly, LearnerSummary } from '../../models/learner';
import { PaginatedList } from '../../models/paginated-list';
import { addPagination, PaginationRequest } from '../../models/pagination-request';
import { Principal } from '../../models/principal';
import { Ao } from '../../models/ao';

@Injectable({
  providedIn: 'root',
})
export class LearnerService {
  private baseUrl = 'api/learners';
  private learnerSummaryChange = new ReplaySubject<LearnerSummary>(1);
  private _learnerSummary: LearnerSummary;

  learnerSummaryChange$: Observable<LearnerSummary> = this.learnerSummaryChange.asObservable();

  get learnerSummary(): LearnerSummary {
    return this._learnerSummary;
  }

  set learnerSummary(value: LearnerSummary) {
    this._learnerSummary = value;
    this.learnerSummaryChange.next(value);
  }

  constructor(private http: HttpClient) {}

  static isAtLeast(age: number, dob: Date) {
    const now = new Date();
    const ageYearsAgo = new Date().setFullYear(now.getFullYear() - age);
    return dob.valueOf() < ageYearsAgo;
  }

  static isUnder(age: number, dob: Date | string) {
    return dayjs().diff(dayjs(dob), 'year') < age;
  }

  static isUnderOrEqual(age: number, dob: Date | string) {
    return dayjs().diff(dayjs(dob), 'year') <= age;
  }

  static atLeastAndUnder(atLeastAge: number, andUnderAge: number, dob: Date) {
    return LearnerService.isUnder(andUnderAge, dob) && LearnerService.isAtLeast(atLeastAge, dob);
  }

  static goingToBeUnder(age: number, dob: Date | string, futureDate: Date | string) {
    const agebyFutureDate = Math.abs(dayjs(dob).diff(dayjs(futureDate), 'year'));
    return { result: agebyFutureDate < age, agebyFutureDate };
  }

  public getAgeInMonths(dob: Date | string) {
    return dayjs().startOf('day').diff(dayjs(dob).startOf('day'), 'months');
  }

  public getAgeInDays(dob: Date | string) {
    return dayjs().startOf('day').diff(dayjs(dob).startOf('day'), 'days');
  }

  getLearnerSummary(learnerId: string) {
    return this.http.get<LearnerSummary>(`${this.baseUrl}/${learnerId}`);
  }

  getLearnerSummaryForSearchResult(learnerId: string, isSif: string, zoneId: string) {
    return this.http.get<LearnerSummary | OperationResultWithValue<LearnerSummary>>(`${this.baseUrl}/${learnerId}`, {
      params: { isSearchResult: 'true', isSif, zoneId },
    });
  }

  getLearnerSummaryForSifSearchResult(sifRecord: any) {
    return this.http.post<OperationResultWithValue<LearnerSummary>>(`${this.baseUrl}/sif`, sifRecord);
  }

  getReadonly(learnerId: string) {
    return this.http.get<LearnerReadonly>(`${this.baseUrl}/${learnerId}/readonly`);
  }

  addLearner(learner: LearnerCreate) {
    return this.http.post<LearnerSummary>(this.baseUrl, learner);
  }

  getLearnerHistory(learnerId: string, filters) {
    return this.http.post<CaseHistoryItem[]>(`${this.baseUrl}/${learnerId}/history`, filters);
  }

  getActivityLog(id: string) {
    return this.http.get<ActivityLogEntry[]>(`${this.baseUrl}/${id}/activity`);
  }

  getFamilyEmailString(familyMembers: FamilyMember[]) {
    const emails = familyMembers?.map((fm) => fm.email);
    return 'mailto:'.concat(emails?.join(','));
  }

  getPrincipal(learnerId: string) {
    return this.http.get<Principal>(`${this.baseUrl}/${learnerId}/principal`);
  }

  getAreaOffice(learnerId: string) {
    return this.http.get<OperationResultWithValue<Ao>>(`${this.baseUrl}/${learnerId}/area-office`);
  }

  updateKindergartenStartDate(learnerId: string, startDate: Date, updateIepServiceDate = false) {
    return this.http.put(`${this.baseUrl}/${learnerId}/kindergarten-start-date`, {
      startDate,
      updateIepServiceDate,
    });
  }

  getIndividualHealthPlanDocuments(learnerId: string) {
    return this.http.get<FileDocument[]>(`${this.baseUrl}/${learnerId}/individual-health-plan-documents`);
  }

  getFamilyMember(learnerId: string, id: string) {
    return this.http.get<FamilyMember>(`${this.baseUrl}/${learnerId}/familyMember/${id}`);
  }

  getFamilyMemberFirstName(fullName: string) {
    if (fullName.indexOf(',') > 0) {
      const fullNameSplit = fullName.split(',');
      if (fullNameSplit.length > 1) {
        return fullNameSplit[1].trim();
      }
    } else {
      const fullNameSplit = fullName.split(' ');
      if (fullNameSplit.length > 0) {
        return fullNameSplit[0].trim();
      }
    }
    return fullName;
  }

  getFamilyMemberLastName(fullName: string) {
    if (fullName.indexOf(',') > 0) {
      const fullNameSplit = fullName.split(',');
      if (fullNameSplit.length > 0) {
        return fullNameSplit[0].trim();
      }
    } else {
      const fullNameSplit = fullName.split(' ');
      if (fullNameSplit.length > 1) {
        return fullNameSplit[1].trim();
      }
    }
    return fullName;
  }

  getFamilyMembers(learnerId: string) {
    return this.http.get<FamilyMember[]>(`${this.baseUrl}/${learnerId}/familyMembers`);
  }

  updateFamilyMember(learnerId: string, editingFamilyMember: FamilyMember) {
    return this.http.put<void>(`${this.baseUrl}/${learnerId}/familyMember/${editingFamilyMember.id}`, editingFamilyMember);
  }

  addFamilyMember(learnerId: string, familyMember: FamilyMember) {
    return this.http.post<FamilyMember>(`${this.baseUrl}/${learnerId}/familyMember/`, familyMember);
  }

  deleteFamilyMember(learnerId: string, id: string) {
    return this.http.put(`${this.baseUrl}/${learnerId}/familyMember/delete/${id}`, null);
  }

  inviteToPortal(learnerId: string, email: string) {
    return this.http.post<any>(`${this.baseUrl}/invite`, { learnerId: learnerId, email: email });
  }

  getCases(learnerId: string, intakeType?: string) {
    let params = new HttpParams();
    if (intakeType) {
      params = params.append('intakeType', intakeType);
    }

    return this.http.get<CaseSummary[]>(`${this.baseUrl}/${learnerId}/cases`, {
      params,
    });
  }

  getActiveCases(learnerId: string, intakeType?: string) {
    let params = new HttpParams();
    params = params.append('onlyActive', 'true');
    if (intakeType) {
      params = params.append('intakeType', intakeType);
    }

    return this.http.get<CaseSummary[]>(`${this.baseUrl}/${learnerId}/cases`, {
      params,
    });
  }

  getByCaseId(caseId: string) {
    return this.http.get<LearnerSummary>(`${this.baseUrl}/case/${caseId}`);
  }

  updateLearnerFinalEcoRatingPkStatus(learnerId: string, finalEcoRatingPkStatus: FinalEcoRatingPkStatus) {
    return this.http.put(`${this.baseUrl}/${learnerId}/final-eco-rating-pk-status`, { finalEcoRatingPkStatus });
  }

  getLearnersToBeDestroyed(pagination?: PaginationRequest) {
    const params = addPagination(pagination);
    return this.http.get<PaginatedList<Learner>>(`${this.baseUrl}/age-destroy-data`, { params });
  }

  updateExceptionDestroyDataBatch(learners: { id: string; isExceptionDestroyData: boolean }[]) {
    return this.http.put<void>(`${this.baseUrl}/age-destroy-data/batch`, learners);
  }

  changeStateId(learnerId: string, newStateId: string) {
    return this.http.put<OperationResult>(`${this.baseUrl}/update-state-id/${learnerId}/${newStateId}`, null);
  }

  updateMigrationDetails(learnerId: string, eligibleInLegacySystem: boolean, movingFromOutOfState: boolean) {
    return this.http.put<OperationResult>(`${this.baseUrl}/update-migration-details/${learnerId}`, {
      eligibleInLegacySystem,
      movingFromOutOfState,
    });
  }

  updateDomicileAndHostLocation(learnerId: string, locations: any) {
    return this.http.put<OperationResult>(`${this.baseUrl}/${learnerId}/domicile-host`, locations);
  }

  updateAgeOfMajorityLetterSentStatus(learnerId: string, letterSent: boolean) {
    return this.http.put(`${this.baseUrl}/${learnerId}/age-of-majority-letter-status`, { letterSent });
  }

  getLearnerInvite(inviteId: string) {
    return this.http.get<LearnerInvite>(`${this.baseUrl}/invite/${inviteId}`);
  }

  validateInvitation(invitationId: string) {
    return this.http.post<OperationResultWithValue<LearnerInvitationVerifyDto>>(
      `${this.baseUrl}/validate-invitation/${invitationId}`,
      null,
      {
        params: { invitationId: invitationId },
      }
    );
  }

  confirmInvitation(familyMemberPortalRegistrationDto: LearnerPortalRegistrationDto) {
    return this.http.post<OperationResultWithValue<LearnerInvitationVerifyDto>>(
      `${this.baseUrl}/confirm-invitation`,
      familyMemberPortalRegistrationDto
    );
  }

  updateIvrsStatus(learnerId: string, status: boolean) {
    return this.http.put(`${this.baseUrl}/${learnerId}/ivrs-status`, { status });
  }

  hasNonLegacyIep(learnerId: string) {
    return this.http.get<OperationResultWithValue<boolean>>(`${this.baseUrl}/${learnerId}/has-non-legacy-iep`);
  }
}
