import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SpinnerService } from '../spinner/spinner.service';
import { OperationResult, OperationResultWithValue } from '../../models/operation-result';
import {
  FamilyMemberPortalRegistrationDto,
  FamilyMemberSettingsDto,
  InvitationVerifyDto,
} from '../../../auth/family-member-registration/models/family-member-portal-registration-dto';
import {
  FamilyMemberConsentDto,
  FamilyMemberDocumetDto,
  FamilyMemberLearnerDto,
  FamilyMemberValidateChildDto,
} from '../../../portal/model/family-member-dto';
import { NotificationService } from '../notification.service';

@Injectable({
  providedIn: 'root',
})
export class FamilyMemberService {
  private baseUrl = 'api/family-members';

  constructor(private http: HttpClient, private spinnerService: SpinnerService, private readonly notificationService: NotificationService) {
    spinnerService.registerIgnoredRequestUrlMatcher(new RegExp(/^api\/family-members.*/));
  }

  inviteFamilymember(familyMemberId: string) {
    return this.http.post<OperationResult>(`${this.baseUrl}/invite/${familyMemberId}`, null, {
      params: { familyMemberId: familyMemberId },
    });
  }

  validateInvitation(invitationId: string) {
    return this.http.post<OperationResultWithValue<InvitationVerifyDto>>(`${this.baseUrl}/validate-invitation/${invitationId}`, null, {
      params: { invitationId: invitationId },
    });
  }

  confirmInvitation(familyMemberPortalRegistrationDto: FamilyMemberPortalRegistrationDto) {
    return this.http.post<OperationResultWithValue<InvitationVerifyDto>>(
      `${this.baseUrl}/confirm-invitation`,
      familyMemberPortalRegistrationDto
    );
  }

  validateChild(validateChildDto: FamilyMemberValidateChildDto) {
    return this.http.post<OperationResult>(`${this.baseUrl}/validate-child`, validateChildDto);
  }

  getLearners() {
    return this.http.get<OperationResultWithValue<FamilyMemberLearnerDto[]>>(`${this.baseUrl}/learners`);
  }

  getRecentForFamilyMember() {
    return this.http.get<OperationResultWithValue<FamilyMemberDocumetDto[]>>(`${this.baseUrl}/recent-documents`);
  }

  getPendingSignatureConsents() {
    return this.http.get<OperationResultWithValue<FamilyMemberConsentDto[]>>(`${this.baseUrl}/pending-signature-consents`);
  }

  excludeDocument(documentId: string) {
    return this.http.post<OperationResult>(`${this.baseUrl}/exclude-document/${documentId}`, null);
  }

  saveAccessPreference(settings: FamilyMemberSettingsDto) {
    return this.http.post<OperationResult>(`${this.baseUrl}/update-access-preference`, settings);
  }

  doesLearnerHasTheRight() {
    return this.http.get<OperationResultWithValue<boolean>>(`${this.baseUrl}/learner-has-the-right`);
  }

  revokeAccess(familyMemberId: string) {
    return this.http.post<OperationResultWithValue<boolean>>(`${this.baseUrl}/${familyMemberId}/revokeAccess`, null);
  }

  reactivateAccess(familyMemberId: string) {
    return this.http.post<OperationResult>(`${this.baseUrl}/${familyMemberId}/reactivateAccess`, null);
  }

  handleError(errorTitle: string, error: any): void {
    let errorMessage = '';
    if (error && error.error && error.error.errors) {
      errorMessage = error.error.errors.map((e) => e.description)?.join(',');
    } else if (error && error.errors) {
      errorMessage = error.errors.map((e) => e.description)?.join(',');
    } else if (error && error.message) {
      errorMessage = error.message;
    } else {
      errorMessage = JSON.stringify(error);
    }

    this.notificationService.errorWithAction(errorTitle, 'Why?', () => {
      this.notificationService.alert(errorMessage, errorTitle);
    });
  }
}
