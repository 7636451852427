import { DatePipe } from '@angular/common';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { ActivatedRoute, Router } from '@angular/router';
import dayjs from 'dayjs';
import { forkJoin, of, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { AppPermissions } from 'src/app/permissions';
import { DialogComingSoonComponent } from 'src/app/shared/components/coming-soon/coming-soon.component';
import {
  IfspIncompleteItemAction,
  IncompleteDataReportItem,
} from 'src/app/shared/components/incomplete-data-report/incomplete-data-report-item';
import { DeleteConfirmationComponent } from 'src/app/shared/modals/delete-confirmation-modal/delete-confirmation-modal.component';
import { ChangeTrackerService } from 'src/app/shared/services/change-tracker.service';
import { RoutingService } from 'src/app/shared/services/routing.service';
import { SpinnerService } from 'src/app/shared/services/spinner/spinner.service';
import { openDocumentWindow } from 'src/app/shared/windowHelpers';
import { AreYouSureComponent } from '../../../shared/components/are-you-sure-modal/are-you-sure.component';
import { FeatureFlagService } from '../../../shared/components/feature-flags/feature-flag.service';
import { shortDateFormat } from '../../../shared/dateTimeHelpers';
import { ViewMoreModalData } from '../../../shared/modals/view-more-modal/view-more-modal.component';
import { CaseSummaryByIfspId, FamilyMeetingRead } from '../../../shared/models/case';
import { LearnerSummary } from '../../../shared/models/learner';
import { CaseService } from '../../../shared/services/case/case.service';
import { ConsentFormService } from '../../../shared/services/consent-form/consent-form.service';
import { NotificationService } from '../../../shared/services/notification.service';
import { PdfOutputs, ReportingService } from '../../../shared/services/reporting/reporting.service';
import { needsLabelOffset, openViewMore } from '../../../shared/tableHelpers';
import { IfspModificationModalComponent } from '../../modals/ifsp-modification-modal/ifsp-modification-modal.component';
import { IfspPeriodicAnnualReview, IfspReviewType, IfspStatus, IfspView } from '../../models/ifsp';
import { IfspMedicalOtherServiceAddUpdateDto } from '../../models/ifsp-medicalotherservice-models';
import { IfspModification } from '../../models/ifsp-modification';
import { OutcomeReviewDto } from '../../models/outcome-models';
import { IfspModificationService } from '../../services/ifsp-modification.service';
import { IfspServicesService } from '../../services/ifsp-service.service';
import { IfspService } from '../../services/ifsp.service';
import { OutcomeService } from '../../services/outcome.service';

@Component({
  selector: 'app-ifsp-details',
  templateUrl: './ifsp-details.component.html',
  styleUrls: ['./ifsp-details.component.scss'],
  providers: [ChangeTrackerService],
})
export class IfspDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('consent', { read: ElementRef })
  private consentPanelElem: ElementRef;
  @ViewChild('consent') private consentPanel: MatExpansionPanel;
  @ViewChild('lateReason') private lateReasonPanel: MatExpansionPanel;
  @ViewChild('lateReason', { read: ElementRef })
  private lateReasonPanelElem: ElementRef;

  private subscriptions: Subscription = new Subscription();
  pdfOutputs = PdfOutputs;
  ifspId: string;
  caseId: string;
  caseSummary: CaseSummaryByIfspId;
  learner: LearnerSummary;
  ifspModifications: IfspModification[];
  modification: IfspModification = null;
  dataLoaded = false;
  pageTitle: string;
  pageTitleColor: string;
  isAccordionsExpanded = false;
  ifspSixMonthReviewMeeting: FamilyMeetingRead;
  incompleteItems: IncompleteDataReportItem[];
  learnerId: string;
  evaluationId: string;
  ifspPeriodicAnnualReviews: IfspPeriodicAnnualReview[];
  permissions: AppPermissions;
  cancelMod: AppPermissions.CancelIFSPModification;
  outcomeReviews: OutcomeReviewDto[] = [];
  ifspView: IfspView;
  canFinalizeIfspModification = false;
  isOutcomesAccordionExpanded = false;

  shortDateFormat = shortDateFormat;

  providerFormGroup: FormGroup;
  activeCall = false;

  get hasOpenModification() {
    return this.modification !== null;
  }

  get hasModifiedItems() {
    return this.modification?.hasModifiedItems;
  }

  get needsNewConsent() {
    return this.incompleteItems?.filter((x) => x.action === 'consent').length > 0;
  }

  get hasFinalizedEvaluation() {
    return this.incompleteItems?.filter((x) => x.action === IfspIncompleteItemAction.EvaluationOverview)?.length === 0;
  }

  get canCancelModification() {
    return (
      (!this.hasModifiedItems && this.hasOpenModification && !this.hasAnnualReview) ||
      (this.authService.isAllowed(AppPermissions.CancelIFSPModification) && !!this.modification)
    );
  }

  get incompleteItemsAmount() {
    if (!this.incompleteItems) {
      return '';
    }
    return `${this.incompleteItems.length} Notices`;
  }

  get hasAnnualReview() {
    return (
      this.ifspPeriodicAnnualReviews &&
      this.ifspPeriodicAnnualReviews.length > 0 &&
      this.hasOpenModification &&
      this.modification &&
      this.ifspPeriodicAnnualReviews.filter((x) => x.ifspModificationId === this.modification.id)
    );
  }

  private _dataItemsLoaded = false;
  get dataItemsLoaded(): boolean {
    return this._dataItemsLoaded;
  }

  get canPushToPortal() {
    return this.hasOpenModification && this.featureFlagService.featureOn('familyPortalEnabled');
  }

  @ViewChild('plodPanel') plodPanel: MatExpansionPanel;
  @ViewChild('plodPanel', { read: ElementRef })
  private plodPanelElem: ElementRef;
  @ViewChild('outcomesPanel') outcomesPanel: MatExpansionPanel;
  @ViewChild('servicesPanel') servicesPanel: MatExpansionPanel;

  @ViewChild('annualReview') private annualReviewPanel: MatExpansionPanel;
  @ViewChild('annualReview', { read: ElementRef })
  private annualReviewPanelElem: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public authService: AuthService,
    private caseService: CaseService,
    private ifspModificationService: IfspModificationService,
    private ifspService: IfspService,
    private ifspServicesService: IfspServicesService,
    private ifspOutcomesService: OutcomeService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
    private routingService: RoutingService,
    private consentFormService: ConsentFormService,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private outcomeService: OutcomeService,
    private spinnerService: SpinnerService,
    private reportingService: ReportingService,
    private featureFlagService: FeatureFlagService
  ) {
    spinnerService.registerIgnoredRequestUrlMatcher(new RegExp(/^api\/ifsp\/.*/));
  }

  async ngOnInit(): Promise<void> {
    this.ifspId = this.route.snapshot.paramMap.get('ifspId');
    this.caseId = this.route.snapshot.paramMap.get('caseId');
    this.getQueryParams();

    this.getQueryParams();

    this.ifspServicesService.openServicesAccordion.subscribe(() => {
      if (this.servicesPanel._getExpandedState() === 'collapsed') {
        this.servicesPanel.toggle();
      }
    });

    this.ifspOutcomesService.openOutcomesAccordion.subscribe(() => {
      if (this.servicesPanel._getExpandedState() === 'collapsed') {
        this.outcomesPanel.toggle();
      }
    });

    this.ifspService.openPlodAccordion$.subscribe(() => {
      if (this.plodPanel._getExpandedState() === 'collapsed') {
        this.plodPanel.toggle();
      }
    });

    const consentUpdate = this.consentFormService.consentsUpdated$.subscribe(async () => {
      await this.getIncompleteItems();
    });

    this.subscriptions.add(consentUpdate);

    this.providerFormGroup = this.fb.group({});

    forkJoin([
      this.caseService.getCaseSummaryByIfsp(this.ifspId).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === HttpStatusCode.NotFound) this.router.navigate(['/not-found']);
          return of(undefined);
        })
      ),
      this.ifspModificationService.getModificationsByIfspId(this.ifspId),
      this.ifspService.getIfspSixMonthReviewMeeting(this.caseId),
      this.ifspService.getReviews(this.ifspId),
      this.outcomeService.getOutcomesForReview(this.ifspId),
      this.ifspService.get(this.ifspId).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === HttpStatusCode.NotFound) this.router.navigate(['/not-found']);
          return of(undefined);
        })
      ),
      // Not sure there's a better way, but we need to validate the case exists
      // in the event they change the id in the URL
      this.caseService.getCaseSummary(this.caseId).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === HttpStatusCode.NotFound) this.router.navigate(['/not-found']);
          return of(undefined);
        })
      ),
    ]).subscribe(async ([learnerResult, ifspModifications, ifspSixMonthReviewMeeting, ifspReviews, outcomes, ifspView, caseSummary]) => {
      if (!learnerResult || !ifspView || !caseSummary) return;
      this.ifspView = ifspView;
      this.ifspModifications = ifspModifications;
      this.ifspSixMonthReviewMeeting = ifspSixMonthReviewMeeting;
      this.ifspPeriodicAnnualReviews = this.ifspService.cleanReviews(ifspReviews.filter((x) => x.ifspReviewType === IfspReviewType.Annual));
      this.evaluationId = learnerResult.evaluationId;
      this.caseSummary = learnerResult;
      this.canFinalizeIfspModification =
        this.authService.isSuperAdmin ||
        (this.authService.isAllowedByCaseId(this.caseId, undefined, AppPermissions.FinalizeIFSPModification) &&
          this.authService.user?.id === this.caseSummary?.caseOwnerId);
      this.learner = learnerResult.learner;
      this.learnerId = learnerResult.learnerId;
      this.pageTitle = 'IFSP - View - ' + learnerResult.learner.fullName;
      this.pageTitleColor = 'blue';
      this.outcomeReviews = outcomes;
      const modification = ifspModifications.find((x) => !x.finalizeDate);
      if (modification) {
        this.modification = modification;
        this.setModification();
      }

      await this.getIncompleteItems();

      const mod = this.ifspModificationService.modificationsUpdated$.subscribe(() => {
        this.getModification();
        this.consentFormService.checkForUpdatedConsent();
      });

      this.subscriptions.add(mod);

      this.dataLoaded = true;
    });

    const outcome = this.ifspOutcomesService.outcomesUpdated$.subscribe(() => {
      this.getModification();
    });

    this.subscriptions.add(outcome);

    const ser = this.ifspServicesService.servicesUpdated$.subscribe(() => {
      this.getModification();
    });

    this.subscriptions.add(ser);

    const serv = this.ifspService.ifspUpdated$.subscribe(() => {
      this.getModification();
    });

    this.subscriptions.add(serv);
  }

  getQueryParams() {
    this.route.queryParams.subscribe((params) => {
      this.isOutcomesAccordionExpanded = (params['expanded'] || '') === 'Outcomes';
    });
  }

  convertToShortDate(date: Date) {
    return this.datePipe.transform(date, shortDateFormat);
  }

  checkLabelOffset(row): boolean {
    return needsLabelOffset(row);
  }

  onViewMore(row: IfspMedicalOtherServiceAddUpdateDto) {
    const modalData: ViewMoreModalData[] = [
      {
        name: 'Type of Service',
        value: row.typeOfService,
      },
      {
        name: 'Agency Name',
        value: row.agencyName,
      },
      {
        name: 'Date Requested',
        value: this.convertToShortDate(row.dateRequested),
      },
      {
        name: 'Referral Date',
        value: this.convertToShortDate(row.referralDate),
      },
      {
        name: 'Estimated Completion Date',
        value: this.convertToShortDate(row.estimatedCompletionDate),
      },
    ];

    openViewMore(this.dialog, modalData);
  }

  scheduleMeeting(): void {
    this.routingService.openScheduleMeeting(this.caseSummary.learnerId);
  }

  scrollAndOpenConsent() {
    this.consentPanel.open();
    this.consentPanelElem.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  gotoProceduralSafeguards() {
    this.router.navigate(['learner-management', this.learnerId, 'documentation']);
  }

  async getIncompleteItems() {
    this.incompleteItems = await this.ifspService.getIncompleteItemsReport(this.ifspId, this.modification?.id).toPromise();
    this._dataItemsLoaded = true;
  }

  async refreshIncompleteItems(e: MouseEvent) {
    e.stopPropagation();
    await this.getIncompleteItems();
  }

  finalizeIfspModification() {
    this.activeCall = true;
    const dialogRef = this.dialog.open(AreYouSureComponent, {
      data: {
        subQuestion: 'Clicking Yes will Finalize and lock the IFSP modification.',
      },
    });
    dialogRef.afterClosed().subscribe((yesImSure) => {
      if (yesImSure) {
        this.ifspService.finalize(this.ifspId, this.modification.id).subscribe({
          next: () => {
            this.onCreateModificationOutput();
            // waiting for PM's approval on the following:
            // this.notificationService.success('The finalized document will be downloaded shortly...', 2000);
            this.routingService.ifspList(this.learnerId);
          },
          complete: () => (this.activeCall = false),
        });
      } else {
        this.activeCall = false;
      }
    });
  }

  onCreateModificationOutput() {
    this.reportingService.createIfspOutput(this.ifspId, true, this.modification.id).subscribe({
      next: (documentId: string) => openDocumentWindow(this.learnerId, documentId),
      error: (err) => this.reportingService.handleOutputError(err),
    });
  }

  refreshData() {
    this.getIncompleteItems();
  }

  getModification() {
    this.ifspModificationService.getModificationsByIfspId(this.ifspId).subscribe((ifspModifications) => {
      this.ifspModifications = ifspModifications;
      const modification = ifspModifications.find((x) => !x.finalizeDate);
      if (modification) {
        this.modification = modification;
        this.ifspModificationService.modificationDoneUpdating.next();
        this.setModification();
      }
    });
  }

  setModification() {
    this.pageTitle = 'IFSP - Modifying';
    this.pageTitleColor = 'red';
  }

  addNew(itemToAdd) {
    if (itemToAdd === 'outcomes') {
      if (this.outcomesPanel._getExpandedState() === 'collapsed') {
        this.outcomesPanel.toggle();
      }
      this.ifspOutcomesService.outcomeAddNew.next();
    } else if (itemToAdd === 'services') {
      if (this.servicesPanel._getExpandedState() === 'collapsed') {
        this.servicesPanel.toggle();
      }
      this.ifspServicesService.serviceAddNew.next();
    }
  }

  onCancelModify() {
    if (this.hasModifiedItems && !this.authService.isSuperAdmin) {
      this.notificationService.error('Modification cannot be cancelled with out a super admin.');
      return;
    }

    if (this.authService.isSuperAdmin) {
      const dialogRef = this.dialog.open(DeleteConfirmationComponent, {
        width: '550px',
        data: {
          question:
            'Clicking Delete will delete the IFSP modification. Any Prior Written Notices will become disassociated. Please fill in the reason for the deletion to continue.',
        },
      });
      dialogRef.afterClosed().subscribe((val) => {
        if (val?.delete) {
          this.ifspModificationService.deleteModification(this.ifspId, this.modification.id, val?.cancelReason).subscribe(() => {
            this.notificationService.success('IFSP Modification canceled.');
            this.routingService.ifspList(this.learnerId);
          });
        }
      });
    } else {
      this.notificationService.confirmation(
        'Are you sure you want to cancel this Modification? Note: Any Prior Written Notices will become disassociated.',
        () => {
          this.ifspModificationService.cancelModification(this.ifspId, this.modification.id).subscribe(() => {
            this.modification = null;
            this.pageTitle = 'IFSP - View';
            this.pageTitleColor = 'blue';
          });
        }
      );
    }
  }

  onModify(section = null) {
    if (
      this.ifspSixMonthReviewMeeting !== null &&
      this.ifspSixMonthReviewMeeting.dateAndTime >= dayjs().startOf('day').subtract(30, 'day').toDate()
    ) {
      this.router.navigate(['ifsp', this.ifspId]);
      return;
    }

    if (!this.hasOpenModification) {
      const dialogRef = this.dialog.open(IfspModificationModalComponent, {
        width: '760px',
        data: {
          ifspId: this.ifspId,
          caseId: this.caseId,
        },
      });
      dialogRef.afterClosed().subscribe((closeResult) => {
        if (closeResult) {
          this.modification = closeResult.value;
        }
      });
    } else {
      if (section === 'plod') {
        const callback = () => {
          if (this.plodPanel._getExpandedState() === 'collapsed') {
            this.plodPanel.toggle();
          }
        };

        if (!this.modification.modifyingPlod) {
          this.modification.modifyingPlod = true;
          this.updateModification(callback);
        } else {
          callback();
        }
      } else if (section === 'outcomes') {
        const callback = () => {
          if (this.outcomesPanel._getExpandedState() === 'collapsed') {
            this.outcomesPanel.toggle();
          }
        };

        if (!this.modification.modifyingOutcomes) {
          this.modification.modifyingOutcomes = true;
          this.updateModification(callback);
        } else {
          callback();
        }
      } else if (section === 'services') {
        const callback = () => {
          if (this.servicesPanel._getExpandedState() === 'collapsed') {
            this.servicesPanel.toggle();
          }
        };

        if (!this.modification.modifyingServices) {
          this.modification.modifyingServices = true;
          this.updateModification(callback);
        } else {
          callback();
        }
      }
    }
  }

  scrollAndOpenLateReason() {
    this.lateReasonPanel.open();
    this.lateReasonPanelElem.nativeElement.scrollIntoView({
      behavior: 'smooth',
    });
  }

  scrollAndOpenPlod() {
    this.plodPanel.open();
    this.plodPanelElem.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  scrollAndOpenAnnualReview() {
    this.annualReviewPanel.open();
    this.annualReviewPanelElem.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  annualReviewCompleted(review) {
    this.getIncompleteItems();
  }

  annualReviewCancelled(review) {
    this.ifspService.getReviews(this.ifspId).subscribe((reviews) => (this.ifspPeriodicAnnualReviews = reviews));
  }

  lateReasonUpdated() {
    this.getIncompleteItems();
  }

  updateModification(callback) {
    this.ifspModificationService.updateModification(this.ifspId, this.modification).subscribe(() => {
      callback();
    });
  }

  onDataHistoryNavigate() {
    this.routingService.dataHistory(this.learnerId);
  }

  onComingSoon(): void {
    this.dialog.open(DialogComingSoonComponent);
  }

  onViewOrModfyingPage() {
    return this.pageTitle.includes('View') || this.pageTitle.includes('Modifying');
  }

  getLastIfspDocumentId(ifsp: IfspView) {
    if (!ifsp?.modifications || ifsp?.modifications?.length == 0 || ifsp.ifspStatus === IfspStatus.Complete) return ifsp?.outputDocumentId;

    return (
      ifsp.modifications.sort((a, b) => {
        return Date.parse(b.finalizeDate) - Date.parse(a.finalizeDate);
      })[0]?.outputDocumentId || ifsp?.outputDocumentId
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
