import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { MatTableModule } from '@angular/material/table';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ChartsModule } from 'ng2-charts';
import { ConsentModule } from '../consent/consent.module';
import { ViewDocumentComponent } from '../consent/family-consent/view-document/view-document.component';
import { IepModule } from '../iep/iep.module';
import { IfspModule } from '../ifsp/ifsp.module';
import { MonitoringProgressModule } from '../monitoring-progress/monitoring-progress.module';
import { AppMatModule } from '../shared/app-mat.module';
import { SharedModule } from '../shared/shared.module';
import { DataHistoryEvaluationComponent } from './data-history/components/data-history-evaluation/data-history-evaluation.component';
import { EcoRatingHistoryComponent } from './data-history/components/eco-rating-history/eco-rating-history.component';
import { DataHistoryPartBAssessmentsComponent } from './data-history/components/part-b/components/data-history-part-b-assessments/data-history-part-b-assessments.component';
import { DataHistoryPartBAttendanceBehaviorComponent } from './data-history/components/part-b/components/data-history-part-b-attendance-behavior/data-history-part-b-attendance-behavior.component';
import { DataHistoryPartBProgressMonitoringComponent } from './data-history/components/part-b/components/data-history-part-b-progress-monitoring/data-history-part-b-progress-monitoring.component';
import { DataHistoryPartBSecondaryTransitionComponent } from './data-history/components/part-b/components/data-history-part-b-secondary-transition/data-history-part-b-secondary-transition.component';
import { DataHistoryPartBComponent } from './data-history/components/part-b/data-history-part-b.component';
import { DataHistoryPartCDiagnosisComponent } from './data-history/components/part-c/components/data-history-part-c-diagnosis/data-history-part-c-diagnosis.component';
import { DataHistoryPartCEcoDescriptionComponent } from './data-history/components/part-c/components/data-history-part-c-eco-description/data-history-part-c-eco-description.component';
import { EcoOutputByIfspComponent } from './data-history/components/part-c/components/data-history-part-c-eco-description/eco-output-by-ifsp/eco-output-by-ifsp.component';
import { DataHistoryPartCEligibilityComponent } from './data-history/components/part-c/components/data-history-part-c-eligibility/data-history-part-c-eligibility.component';
import { DataHistoryPartCOtherServicesComponent } from './data-history/components/part-c/components/data-history-part-c-other-services/data-history-part-c-other-services.component';
import { OtherServicesIfspViewComponent } from './data-history/components/part-c/components/data-history-part-c-other-services/other-services-ifsp-view/other-services-ifsp-view.component';
import { DataHistoryPartCProgressMonitoringComponent } from './data-history/components/part-c/components/data-history-part-c-progress-monitoring/data-history-part-c-progress-monitoring.component';
import { DataHistoryPartCComponent } from './data-history/components/part-c/data-history-part-c.component';
import { DataHistoryComponent } from './data-history/data-history.component';
import { DocumentationComponent } from './documentation/documentation.component';
import { DownloadableFormsListComponent } from './documentation/downloadable-forms-list/downloadable-forms-list.component';
import { PrintDocumentsModalComponent } from './documentation/print-documents-modal/print-documents-modal.component';
import { EcProgramsComponent } from './early-childhood/ec-programs/ec-programs.component';
import { EvaluationHistoryTableComponent } from './evaluation/components/evaluation-history-table/evaluation-history-table.component';
import { EvaluationComponent } from './evaluation/evaluation.component';
import { FamilyContactConsentComponent } from './family-contact-consent/family-contact-consent.component';
import { FamilyContactInfoViewMoreComponent } from './family-contact-consent/family-contact-info-view-more/family-contact-info-view-more.component';
import { FamilyContactInfoComponent } from './family-contact-consent/family-contact-info/family-contact-info.component';
import { FamilyContactLetterComponent } from './family-contact-consent/family-contact-letter/family-contact-letter.component';
import { FamilyContactLogComponent } from './family-contact-consent/family-contact-log/family-contact-log.component';
import { FamilyContactLogAddComponent } from './family-contact-consent/family-contact-log/modals/family-contact-log-add.component';
import { FamilyDocumentsComponent } from './family-contact-consent/family-documents/family-documents.component';
import { LearnerInfoComponent } from './family-contact-consent/learner-info/learner-info.component';
import { MeetingHistoryComponent } from './family-contact-consent/meeting-history/meeting-history.component';
import { SurrogateContactInfoComponent } from './family-contact-consent/surrogate-contact-info/surrogate-contact-info.component';
import { FamilyMeetingComponent } from './family-meeting/family-meeting.component';
import { ParticipantsListComponent } from './family-meeting/participants-list/participants-list.component';
import { NoticeOfMeetingPrintComponent } from './family-meeting/print/notice-of-meeting-print/notice-of-meeting-print.component';
import { PurposeOfMeetingListComponent } from './family-meeting/purpose-of-meeting-list/purpose-of-meeting-list.component';
import { IepDataComponent } from './iep-data/iep-data.component';
import { PkTokTransitionModalComponent } from './iep-data/pk-tok-transition-modal/pk-tok-transition-modal.component';
import { IfspAddServiceLogComponent } from './ifsp-data/components/ifsp-add-service-log/ifsp-add-service-log.component';
import { IfspDataMonitoringProgressComponent } from './ifsp-data/components/ifsp-data-monitoring-progress/ifsp-data-monitoring-progress.component';
import { CommentComponent } from './ifsp-data/components/ifsp-data-monitoring-progress/tables/comment/comment.component';
import { PhaseLineComponent } from './ifsp-data/components/ifsp-data-monitoring-progress/tables/phase-line/phase-line.component';
import { PlanStatusComponent } from './ifsp-data/components/ifsp-data-monitoring-progress/tables/plan-status/plan-status.component';
import { ProgressDataComponent } from './ifsp-data/components/ifsp-data-monitoring-progress/tables/progress-data/progress-data.component';
import { IfspFgiViewComponent } from './ifsp-data/components/ifsp-fgi-view/ifsp-fgi-view.component';
import { IfspServiceLogsComponent } from './ifsp-data/components/ifsp-service-logs/ifsp-service-logs.component';
import { IfspDataComponent } from './ifsp-data/ifsp-data.component';
import { LateIfspReasonServiceComponent } from './ifsp-data/late-ifsp-service-reason/late-ifsp-service-reason.component';
import { ActivityTableComponent } from './learner-dashboard/activity-table/activity-table.component';
import { CaseOwnerHeaderComponent } from './learner-dashboard/case-owner-header/case-owner-header.component';
import { EcoFinalScorePkPartBComponent } from './learner-dashboard/eco-final-score-pk-part-b/eco-final-score-pk-part-b.component';
import { EcoRatingWrapperComponent } from './learner-dashboard/eco-rating-wrapper/eco-rating-wrapper.component';
import { ExitingSectionComponent } from './learner-dashboard/exiting-section/exiting-section.component';
import { LearnerDashboardComponent } from './learner-dashboard/learner-dashboard.component';
import { LearnerInviteModalComponent } from './learner-dashboard/learner-invite-modal/learner-invite-modal.component';
import { ManageLearnerStateComponent } from './learner-dashboard/manage-learner-state/manage-learner-state.component';
import { ManageReevaluationDueDateComponent } from './learner-dashboard/manage-reevaluation-due-date/manage-reevaluation-due-date.component';
import { MoveInDetailsReasonModalComponent } from './learner-dashboard/move-in-details/move-in-details-reason-modal/move-in-details-reason-modal.component';
import { MoveInDetailsComponent } from './learner-dashboard/move-in-details/move-in-details.component';
import { LearnerManagementHeaderComponent } from './learner-management-header/learner-management-header.component';
import { LearnerManagementNavComponent } from './learner-management-header/learner-management-nav/learner-management-nav.component';
import { LearnerManagementRoutingModule } from './learner-management-routing.module';
import { LearnerManagementComponent } from './learner-management.component';
import { CommunityContactsComponent } from './postsecondary-summary/community-contacts/community-contacts.component';
import { NonAchieveMemberComponent } from './postsecondary-summary/non-achieve-member/non-achieve-member.component';
import { PostSecondarySummaryLlwComponent } from './postsecondary-summary/post-secondary-summary-llw/post-secondary-summary-llw.component';
import { PostsecondarySummaryComponent } from './postsecondary-summary/postsecondary-summary.component';
import { QuickLinksComponent } from './quick-links/quick-links.component';
import { StAreaOfNeedComponent } from './secondary-transition/components/st-area-of-need/st-area-of-need.component';
import { StAssessmentExpectationComponent } from './secondary-transition/components/st-assessment-expectation/st-assessment-expectation.component';
import { StCourseStudyComponent } from './secondary-transition/components/st-course-study/st-course-study.component';
import { StEffectDisabilityComponent } from './secondary-transition/components/st-effect-disability/st-effect-disability.component';
import { StSpecialFactorsComponent } from './secondary-transition/components/st-special-factors/st-special-factors.component';
import { StStrengthSkillPreferenceComponent } from './secondary-transition/components/st-strength-skill-preference/st-strength-skill-preference.component';
import { StTransferOfRightsComponent } from './secondary-transition/components/st-transfer-of-rights/st-transfer-of-rights.component';
import { SecondaryTransitionComponent } from './secondary-transition/secondary-transition.component';
import { LearnerVrStatusComponent } from './learner-dashboard/learner-vr-status/learner-vr-status.component';
import { VrCaseOwnerComponent } from './learner-dashboard/vr-case-owner-header/vr-case-owner-header.component';
import { ManageMigrationDetailsComponent } from './learner-dashboard/manage-migration-details/manage-migration-details.component';

@NgModule({
  declarations: [
    FamilyContactConsentComponent,
    FamilyContactInfoComponent,
    FamilyContactInfoViewMoreComponent,
    FamilyContactLogComponent,
    FamilyContactLogAddComponent,
    FamilyMeetingComponent,
    LearnerManagementComponent,
    LearnerDashboardComponent,
    DataHistoryComponent,
    DataHistoryPartBComponent,
    DataHistoryPartCComponent,
    FamilyContactLetterComponent,
    ParticipantsListComponent,
    EvaluationComponent,
    FamilyDocumentsComponent,
    ViewDocumentComponent,
    DocumentationComponent,
    IfspDataComponent,
    IepDataComponent,
    QuickLinksComponent,
    NoticeOfMeetingPrintComponent,
    ActivityTableComponent,
    EcProgramsComponent,
    MeetingHistoryComponent,
    SecondaryTransitionComponent,
    StAssessmentExpectationComponent,
    StStrengthSkillPreferenceComponent,
    StSpecialFactorsComponent,
    StEffectDisabilityComponent,
    StCourseStudyComponent,
    StAreaOfNeedComponent,
    StTransferOfRightsComponent,
    LearnerManagementHeaderComponent,
    LearnerManagementNavComponent,
    DownloadableFormsListComponent,
    IfspServiceLogsComponent,
    LateIfspReasonServiceComponent,
    IfspDataMonitoringProgressComponent,
    IfspAddServiceLogComponent,
    PrintDocumentsModalComponent,
    DataHistoryPartBAssessmentsComponent,
    DataHistoryPartBAttendanceBehaviorComponent,
    DataHistoryPartBSecondaryTransitionComponent,
    DataHistoryPartBProgressMonitoringComponent,
    DataHistoryEvaluationComponent,
    EvaluationHistoryTableComponent,
    PostsecondarySummaryComponent,
    NonAchieveMemberComponent,
    PostSecondarySummaryLlwComponent,
    PlanStatusComponent,
    ProgressDataComponent,
    PhaseLineComponent,
    CommentComponent,
    PkTokTransitionModalComponent,
    CommunityContactsComponent,
    ExitingSectionComponent,
    CaseOwnerHeaderComponent,
    PurposeOfMeetingListComponent,
    EcoRatingWrapperComponent,
    DataHistoryPartCEligibilityComponent,
    DataHistoryPartCProgressMonitoringComponent,
    DataHistoryPartCDiagnosisComponent,
    DataHistoryPartCEcoDescriptionComponent,
    DataHistoryPartCOtherServicesComponent,
    EcoOutputByIfspComponent,
    OtherServicesIfspViewComponent,
    SurrogateContactInfoComponent,
    EcoRatingHistoryComponent,
    MoveInDetailsComponent,
    MoveInDetailsReasonModalComponent,
    EcoFinalScorePkPartBComponent,
    ManageLearnerStateComponent,
    IfspFgiViewComponent,
    LearnerInviteModalComponent,
    ManageReevaluationDueDateComponent,
    LearnerInfoComponent,
    ManageReevaluationDueDateComponent,
    LearnerVrStatusComponent,
    VrCaseOwnerComponent,
    ManageMigrationDetailsComponent,
  ],
  imports: [
    AppMatModule,
    ChartsModule,
    CommonModule,
    ConsentModule,
    FormsModule,
    LearnerManagementRoutingModule,
    MatTableModule,
    ReactiveFormsModule,
    SharedModule,
    MatChipsModule,
    IepModule,
    MonitoringProgressModule,
    AngularSvgIconModule,
    IfspModule,
  ],
  exports: [FamilyContactInfoComponent, FamilyContactLogComponent, FamilyContactConsentComponent],
})
export class LearnerManagementModule {}
