import { getTreeMultipleDefaultNodeDefsError } from '@angular/cdk/tree';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import dayjs from 'dayjs';
import { forkJoin, merge, of } from 'rxjs';
import { catchError, pairwise, startWith } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { IepStatus, IepView } from 'src/app/iep/models/iep';
import { yesNoOptions } from 'src/app/shared/formHelpers';
import { CaseSummary } from 'src/app/shared/models/case';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { CaseService } from 'src/app/shared/services/case/case.service';
import { BaseComponent } from '../../../../shared/components/base-component/base-component';
import { maxFrequencyValue, shortDateFormat } from '../../../../shared/dateTimeHelpers';
import { ServiceActivityType, ServiceActivityTypeCategory } from '../../../../shared/models/service-activity-type';
import { DeactivationService } from '../../../../shared/services/deactivation.service';
import { NotificationService } from '../../../../shared/services/notification.service';
import { UserService } from '../../../../shared/services/user/user.service';
import { IepActivitySupportDto } from '../../../models/iep-activity-support';
import { IepAmendment } from '../../../models/iep-amendment';
import { IepServiceDto, ServiceActivityTypeQuestion } from '../../../models/iep-service';
import { IepAmendmentService } from '../../../services/iep-amendment.service';
import { IepGoalService } from '../../../services/iep-goal.service';
import { IepService } from '../../../services/iep.service';
import { ESYGoalType, ExpectationType, IepESYGoal, IepESYScheduleOfService } from '../../esy';
import { ESYService } from '../../esy-service';
import { AdjustEsyModalComponent } from './components/adjust-esy-modal/adjust-esy-modal.component';

@Component({
  selector: 'app-esy-goals',
  templateUrl: './esy-goals.component.html',
  styleUrls: ['./esy-goals.component.scss'],
})
export class EsyGoalsComponent extends BaseComponent implements OnInit {
  yesNoOptions = yesNoOptions;
  shortDateFormat = shortDateFormat;
  minDate: Date;
  caseSummary: CaseSummary;
  iepId: string;
  goalType = ESYGoalType;
  formGroup = new FormGroup({});
  esyGoals: IepESYGoal[];
  esyGoalsForChildComponent: IepESYGoal[];
  serviceOptions: KeyValuePair[];
  activityOptions: KeyValuePair[];
  providerOptions: KeyValuePair[];
  displayedColumns = ['actions', 'date', 'time'];
  dataSource = new MatTableDataSource<IepESYScheduleOfService>([]);
  supportActivityVisible = false;
  serviceVisible = false;
  updatingService = false;
  updatedServiceId: string;
  serviceForEdit: IepServiceDto;
  updatingSupport = false;
  updatedSupportId: string;
  supportForEdit: IepActivitySupportDto;
  providers = [];
  activities = [];
  services: ServiceActivityType[];
  allIepGoals = [];
  allPses;
  dataLoaded = false;
  amendments: IepAmendment[] = [];
  readonly = false;
  activeCall = false;
  iep: IepView;

  get readonlyUser() {
    return this.authService.IsReadOnly;
  }

  get amendment() {
    return this.amendments?.length > 0 ? this.amendments[0] : null;
  }

  get amendmentId() {
    return this.amendment?.id;
  }

  get amendmentIsFinalized() {
    return this.amendment?.finalizeDate !== null;
  }

  get lastFinalizedDate() {
    if (this.amendments && this.amendments.length > 0) {
      const latest = this.amendments.reduce((r, a) => {
        return r.finalizeDate > a.finalizeDate ? r : a;
      });
      return latest?.finalizeDate;
    }
    return null;
  }

  get hasAmendment() {
    return this.amendmentId && this.amendmentId !== null;
  }

  get hasOpenAmendment() {
    return !!this.amendment && !this.amendmentIsFinalized;
  }

  get noGoalsAvailable() {
    return (
      this.dataLoaded &&
      this.allIepGoals.length === 0 &&
      !this.allPses?.livingExpectations &&
      !this.allPses?.learningExpectations &&
      !this.allPses?.workingExpectations
    );
  }
  get noPSEsAvailable() {
    return (
      this.dataLoaded &&
      (this.allIepGoals.length > 0 ||
        this.allPses?.livingExpectations ||
        this.allPses?.learningExpectations ||
        this.allPses?.workingExpectations) &&
      (!this.esyGoals || this.esyGoals.length === 0)
    );
  }

  get childFirstName() {
    return this.caseSummary?.learner?.firstName;
  }

  get minProjectedStartDate() {
    return dayjs().toDate();
  }

  get iepIsFinalized() {
    return this.iep.iepStatus !== IepStatus.Draft;
  }

  serviceFrequencyPeriodOptions: KeyValuePair[] = [new KeyValuePair('Minutes', 'Minutes'), new KeyValuePair('Hours', 'Hours')];
  supportFrequencyPeriodOptions: KeyValuePair[] = [
    new KeyValuePair('Daily', 'Daily'),
    new KeyValuePair('Weekly', 'Weekly'),
    new KeyValuePair('Monthly', 'Monthly'),
    new KeyValuePair('As outlined in the description', 'As outlined in the description'),
  ];

  instructionalSupportTypeOptions: KeyValuePair[] = [new KeyValuePair('Individual', 'Individual'), new KeyValuePair('Shared', 'Shared')];
  directConsultOptions: KeyValuePair[] = [new KeyValuePair('Direct', 'Direct'), new KeyValuePair('Consult', 'Consult')];
  transportationServiceOptions: KeyValuePair[] = [
    new KeyValuePair('Attendant service', 'Attendant service'),
    new KeyValuePair('Specially equipped vehicle', 'Specially equipped vehicle'),
    new KeyValuePair('Special route in district', 'Special route in district'),
    new KeyValuePair('Special route out of district', 'Special route out of district'),
    new KeyValuePair('Other', 'Other'),
  ];
  paraprofessionalSupportOptions: KeyValuePair[] = [
    new KeyValuePair('BehaviorSafety', 'Behavior/Safety'),
    new KeyValuePair('Health', 'Health'),
    new KeyValuePair('Physical', 'Physical'),
  ];

  get serviceActivityTypeQuestion(): typeof ServiceActivityTypeQuestion {
    return ServiceActivityTypeQuestion;
  }

  minProjectedEndDate(goal) {
    let returnDate = null;
    if (this.formGroup.get(goal.id + '_service')?.get('projectedStartDate')?.value) {
      returnDate = dayjs(this.formGroup.get(goal.id + '_service')?.get('projectedStartDate')?.value)
        .add(1, 'day')
        .toDate();
    }

    return goal ? returnDate || dayjs().toDate() : returnDate;
  }

  maxProjectedStartDate(goal) {
    let returnDate = null;
    if (this.formGroup.get(goal.id + '_service')?.get('projectedEndDate')?.value) {
      returnDate = dayjs(this.formGroup.get(goal.id + '_service')?.get('projectedEndDate')?.value)
        .subtract(1, 'day')
        .toDate();
    }

    return returnDate;
  }

  hasParaprofessionalSupportHealth(goalId) {
    return this.formGroup.get(goalId + '_paraprofessionalSupports')?.value?.includes('Health');
  }

  hasParaprofessionalSupportBehaviorSafety(goalId) {
    return this.formGroup.get(goalId + '_paraprofessionalSupports')?.value?.includes('BehaviorSafety');
  }

  hasTransportationServicesOther(goalId) {
    return this.formGroup
      .get(goalId + '_service')
      .get('transportationServices')
      .value?.includes('Other');
  }

  hasTransportationServicesAttendant(goalId) {
    return this.formGroup
      .get(goalId + '_service')
      .get('transportationServices')
      .value?.includes('Attendant service');
  }

  showParaprofessionalSupportQuestions(goalId) {
    return this.serviceHasQuestion(this.serviceActivityTypeQuestion.ParaprofessionalSupports, goalId);
  }

  serviceHasQuestion(question: ServiceActivityTypeQuestion, goalId: string) {
    const service = this.services.find((x) => x.id === this.formGroup.get(goalId + '_service').get('serviceTypeId').value);
    if (service) {
      const questionIndex = service.additionalQuestions.findIndex((x) => x.question === question);
      if (questionIndex > -1) {
        return true;
      }
    }
    return false;
  }

  showDirectConsultQuestions(goalId) {
    return this.serviceHasQuestion(this.serviceActivityTypeQuestion.DirectConsult, goalId);
  }

  showInstructionalSupportTypeQuestions(goalId) {
    return this.serviceHasQuestion(this.serviceActivityTypeQuestion.InstructionalSupportType, goalId);
  }

  showTransportationServiceQuestions(goalId) {
    return this.serviceHasQuestion(this.serviceActivityTypeQuestion.TransportationServices, goalId);
  }

  constructor(
    private caseService: CaseService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private iepEsyService: ESYService,
    private iepService: IepService,
    private userService: UserService,
    private iepGoalService: IepGoalService,
    private notificationService: NotificationService,
    private iepAmendmentService: IepAmendmentService,
    deactivationService: DeactivationService,
    private authService: AuthService
  ) {
    super(deactivationService);
  }

  ngOnInit(): void {
    this.iepId = this.route.parent.snapshot.paramMap.get('iepId');
    this.minDate = new Date();

    forkJoin([
      this.caseService.getCaseSummaryNoLocations(this.route.parent.snapshot.paramMap.get('caseId')),
      this.iepService.getPartBSASTypes(ServiceActivityTypeCategory.Service, ServiceActivityTypeCategory.Activity),
      this.iepGoalService.getCompletedIepGoals(this.iepId),
      this.iepAmendmentService.getAmendmentsByIepId(this.iepId),
      this.iepService.get(this.route.parent.snapshot.paramMap.get('iepId')),
    ]).subscribe(async ([caseSummary, servicesAndActivities, iepGoals, iepAmendments, iep]) => {
      this.caseSummary = caseSummary;
      this.amendments = iepAmendments;
      this.iep = iep;
      const serviceTypes = servicesAndActivities.filter((x) => x.partBType === ServiceActivityTypeCategory.Service);
      const activityTypes = servicesAndActivities.filter((x) => x.partBType === ServiceActivityTypeCategory.Activity);
      this.activities = activityTypes;
      this.services = serviceTypes;

      this.activityOptions = activityTypes.map((x) => new KeyValuePair(x.id, x.label));

      this.userService
        .getServiceActivityProviders(this.caseSummary?.learnerId)
        .pipe(
          catchError(() => {
            return of([]);
          })
        )
        .subscribe((providers) => (this.providers = providers));

      await this.loadESYGoals(true);
      this.allIepGoals = iepGoals;

      this.serviceOptions = serviceTypes.map((x) => new KeyValuePair(x.id, x.label));

      this.trackChanges(this.esyGoals);

      const pses = await this.iepEsyService.getESYPse(this.iepId).toPromise();
      this.allPses = pses;
      this.dataLoaded = true;
    });
  }

  uiGoalUpdate(goals) {
    this.initializeServiceControls(goals);
    this.initializeSupportControls(goals);
    this.esyGoals = goals;
  }

  async loadESYGoals(initControls) {
    const esyGoals = await this.iepEsyService.getESYGoals(this.iepId, this.amendmentId).toPromise();
    this.esyGoals = esyGoals;
    this.esyGoalsForChildComponent = esyGoals;
    if (initControls) {
      this.initializeServiceControls(this.esyGoals);
      this.initializeSupportControls(this.esyGoals);
    }
  }

  amendGoal(goal) {
    const esyGoal = this.getESYGoal(goal.id);
    this.iepEsyService.startESYGoalAmendment(esyGoal.id, this.iepId, this.amendmentId, true).subscribe(async () => {
      await this.loadESYGoals(false);
    });
  }

  isESYGoalEnded(goal) {
    return goal.goalType !== 'Goal'
      ? this.getPSEGoal(goal.expectationType).amendmentEndDate !== null
      : this.getESYGoal(goal.id).amendmentEndDate !== null;
  }

  getESYGoal(esyGoalId) {
    return this.esyGoals.find((x) => x.id === esyGoalId);
  }

  getPSEGoal(expectationType: ExpectationType) {
    return this.esyGoals.find((x) => x.expectationType === expectationType);
  }

  allowFormEdit(isGoal: boolean, goal: any) {
    if (isGoal) {
      return !this.getESYGoal(goal.id)?.iepIsFinalized || (this.hasOpenAmendment && this.getESYGoal(goal.id)?.amendingGoalDetails);
    } else {
      return (
        !this.getPSEGoal(goal.expectationType)?.iepIsFinalized ||
        (this.hasOpenAmendment && this.getPSEGoal(goal.expectationType)?.amendingGoalDetails)
      );
    }
  }

  showCompareOutput(isGoal: boolean, goal: any) {
    if (isGoal) {
      return this.getESYGoal(goal.id).amendingGoalDetails || this.formGroup.get(goal.id)?.get('amendingGoal')?.value === true;
    } else {
      return (
        this.getPSEGoal(goal.expectationType)?.amendingGoalDetails ||
        this.formGroup.get(goal.pseSummaryId + '_' + goal.expectationType)?.get('amendingGoal')?.value === true
      );
    }
  }

  getServiceTypeLabel(value) {
    return this.serviceOptions.find((x) => x.key === value)?.value;
  }

  getActivityTypeLabel(value) {
    return this.activityOptions.find((x) => x.key === value)?.value;
  }

  goalSelected(esyGoalId: string) {
    return this.route.snapshot.paramMap.get('goalId') === esyGoalId;
  }

  initializeServiceControls(goals: IepESYGoal[]) {
    goals.forEach((goal) => {
      const newFormGroup = new FormGroup({
        description: new FormControl(null, Validators.required),
        serviceTypeId: new FormControl(null, Validators.required),
        frequencyNumber: new FormControl(null, Validators.required),
        frequencyIncrement: new FormControl(null, [Validators.required, Validators.min(0)]),
        frequencyPeriod: new FormControl(null, Validators.required),
        providers: new FormControl([], Validators.required),
        otherProvider: new FormControl(false),
        otherProviderName: new FormControl(null),
        otherProviderAgency: new FormControl(null),
        otherProviderRole: new FormControl(null),
        location: new FormControl(null, Validators.required),
        scheduleServicesDate: new FormControl(null),
        scheduleServicesTime: new FormControl(null, Validators.min(0)),
        transportationNeeded: new FormControl(null, Validators.required),
        transportationServicesDescription: new FormControl(null),
        hasHealthCarePlan: new FormControl(null),
        directConsult: new FormControl(null),
        instructionalSupportType: new FormControl(null),
        transportationServices: new FormControl(null),
        hasBehaviorInterventionPlan: new FormControl(null),
        projectedStartDate: new FormControl(null, Validators.required),
        projectedEndDate: new FormControl(null, Validators.required),
        paraprofessionalSupports: new FormControl(null),
        serviceVisible: new FormControl(false),
        supportActivityVisible: new FormControl(false),
      });
      this.subscriptions.add(
        newFormGroup
          .get('serviceTypeId')
          .valueChanges.pipe(startWith(''), pairwise())
          .subscribe(([prev, next]) => {
            if (prev === next) {
              return;
            }
            this.providerOptions = this.providers
              .filter((x) => x.services.map((s) => s.id).includes(next))
              .map((x) => new KeyValuePair(x.id, x.fullName));
            this.formGroup.get(`${goal.id}_service`).get('providers').setValue([]);
          })
      );
      this.subscriptions.add(
        merge(newFormGroup.get('frequencyNumber').valueChanges, newFormGroup.get('frequencyPeriod').valueChanges).subscribe((value) => {
          const frequencyNumber = newFormGroup.get('frequencyNumber');
          const frequencyPeriod = newFormGroup.get('frequencyPeriod');
          const maxFrequency = maxFrequencyValue(frequencyPeriod.value?.toLowerCase(), 'per day');
          frequencyNumber.clearValidators();
          frequencyNumber.setValidators([Validators.required, Validators.min(0), Validators.max(maxFrequency)]);
          frequencyNumber.updateValueAndValidity({ emitEvent: false });
        })
      );
      this.subscriptions.add(
        newFormGroup.get('otherProvider').valueChanges.subscribe((v) => {
          const prov = newFormGroup.get('providers');
          const otherName = newFormGroup.get('otherProviderName');
          if (v) {
            prov.disable();
            prov.patchValue([]);
            otherName.addValidators(Validators.required);
          } else {
            prov.enable();
            otherName.clearValidators();
          }
          otherName.updateValueAndValidity();
          prov.updateValueAndValidity();
        })
      );
      this.formGroup.addControl(goal.id + '_service', newFormGroup);
    });
  }

  initializeSupportControls(goals: IepESYGoal[]) {
    goals.forEach((goal) => {
      const newFormGroup = new FormGroup({
        activitySupportTypeId: new FormControl(null, Validators.required),
        description: new FormControl('', Validators.required),
        frequencyPeriod: new FormControl('', Validators.required),
        providers: new FormControl([], Validators.required),
        otherProvider: new FormControl(false),
        otherProviderName: new FormControl(null),
        otherProviderAgency: new FormControl(null),
        otherProviderRole: new FormControl(null),
        serviceVisible: new FormControl(false),
        supportActivityVisible: new FormControl(false),
      });
      this.subscriptions.add(
        newFormGroup
          .get('activitySupportTypeId')
          .valueChanges.pipe(startWith(''), pairwise())
          .subscribe(([prev, next]) => {
            if (prev === next) {
              return;
            }
            this.providerOptions = this.providers
              .filter((x) => x.services.map((s) => s.id).includes(next))
              .map((x) => new KeyValuePair(x.id, x.fullName));
            this.formGroup.get(`${goal.id}_support`).get('providers').setValue([]);
          })
      );
      this.subscriptions.add(
        newFormGroup.get('otherProvider').valueChanges.subscribe((v) => {
          const prov = newFormGroup.get('providers');
          const otherName = newFormGroup.get('otherProviderName');
          if (v) {
            prov.disable();
            prov.patchValue([]);
            otherName.addValidators(Validators.required);
          } else {
            prov.enable();
            otherName.clearValidators();
          }
          otherName.updateValueAndValidity();
          prov.updateValueAndValidity();
        })
      );
      this.formGroup.addControl(goal.id + '_support', newFormGroup);
    });
  }
  addToScheduleTable(goalId: string, e: Event) {
    e.preventDefault();

    const date = this.formGroup.get(goalId + '_service').get('scheduleServicesDate');
    const time = this.formGroup.get(goalId + '_service').get('scheduleServicesTime');

    if (date.value && time.value) {
      const schedule = {
        date: date.value,
        time: time.value,
      };
      this.dataSource.data = this.dataSource.data.concat(schedule);

      date.setValue(null);
      time.setValue(null);
    }
  }

  submitService(iepESYGoalId: string, close = false, updating = false) {
    this.activeCall = true;
    const formGroup = this.formGroup.get(iepESYGoalId + '_service');
    const providers = [];
    if (formGroup.get('otherProvider').value !== true) {
      formGroup.get('providers').value.forEach((id) => {
        const provider = this.providers.find((x) => x.id == id);
        if (provider) {
          providers.push({ userId: provider.id });
        }
      });
    }

    const service: IepServiceDto = {
      id: updating ? this.updatedServiceId : null,
      iepESYGoalId,
      iepId: this.iepId,
      serviceTypeId: formGroup.get('serviceTypeId').value,
      description: formGroup.get('description').value,
      frequencyNumber: formGroup.get('frequencyNumber').value,
      frequencyIncrement: formGroup.get('frequencyIncrement').value,
      frequencyPeriod: formGroup.get('frequencyPeriod').value,
      providers,
      otherProvider: formGroup.get('otherProvider').value,
      otherProviderName: formGroup.get('otherProviderName').value,
      otherProviderAgency: formGroup.get('otherProviderAgency').value,
      otherProviderRole: formGroup.get('otherProviderRole').value,
      projectedStartDate: formGroup.get('projectedStartDate').value,
      projectedEndDate: formGroup.get('projectedEndDate').value,
      agencyId: null,
      location: formGroup.get('location').value,
      scheduleServices: this.dataSource.data,
      transportationNeeded: formGroup.get('transportationNeeded').value,
      transportationServicesDescription: formGroup.get('transportationServicesDescription').value,
      paraprofessionalSupports: formGroup.get('paraprofessionalSupports').value
        ? formGroup.get('paraprofessionalSupports').value?.join(',')
        : null,
      hasHealthCarePlan: formGroup.get('hasHealthCarePlan').value,
      directConsult: formGroup.get('directConsult').value,
      instructionalSupportType: formGroup.get('instructionalSupportType').value,
      transportationServices: formGroup.get('transportationServices') ? formGroup.get('transportationServices').value?.join(',') : null,
      hasBehaviorInterventionPlan: formGroup.get('hasBehaviorInterventionPlan').value,
      amendmentId: this.amendmentId,
    };

    if (this.formGroup.get(iepESYGoalId + '_service').valid && !updating) {
      this.iepEsyService.addESYService(this.iepId, service).subscribe(
        (res) => {
          this.activeCall = false;
          this.resetFormValues(service.iepESYGoalId);
          if (close) {
            this.formGroup
              .get(iepESYGoalId + '_service')
              .get('serviceVisible')
              .patchValue(false);
          }
          this.serviceForEdit = null;
          setTimeout(() => {
            this.refreshGoals();
            if (!close) {
              this.formGroup
                .get(iepESYGoalId + '_service')
                .get('serviceVisible')
                .patchValue(getTreeMultipleDefaultNodeDefsError);
            }
          }, 1500);
        },
        (err) => {
          this.notificationService.error('Could not add service');
        }
      );
    }

    if (this.formGroup.get(iepESYGoalId + '_service').valid && updating) {
      this.iepEsyService.updateESYService(this.iepId, service).subscribe(
        (res) => {
          this.activeCall = false;
          this.updatingService = false;
          this.resetFormValues(iepESYGoalId);
          this.formGroup
            .get(iepESYGoalId + '_service')
            .get('serviceVisible')
            .patchValue(false);
          this.serviceForEdit = null;
          setTimeout(() => {
            this.refreshGoals();
          }, 1500);
        },
        (err) => {
          this.notificationService.error('Could not add service');
        }
      );
    }
  }

  resetFormValues(iepEsyGoalId: string, form = 'service') {
    if (form === 'service') {
      this.formGroup.get(iepEsyGoalId + '_service').reset();

      this.formGroup
        .get(iepEsyGoalId + '_service')
        .get('providers')
        .setValue([]);

      this.formGroup.updateValueAndValidity();

      this.dataSource.data = [];
    }

    if (form === 'support') {
      this.formGroup.get(iepEsyGoalId + '_support').reset();
      this.formGroup
        .get(iepEsyGoalId + '_support')
        .get('providers')
        .setValue([]);
    }
  }

  submitSupport(iepESYGoalId: string, close = false, updating = false) {
    this.activeCall = true;
    const formGroup = this.formGroup.get(iepESYGoalId + '_support');
    const providers = [];
    if (formGroup.get('otherProvider').value !== true) {
      formGroup.get('providers').value.forEach((id) => {
        const provider = this.providers.find((x) => x.id == id);
        if (provider) {
          providers.push({ userId: provider.id });
        }
      });
    }

    const activity = this.activities.find((x) => x.id === formGroup.get('activitySupportTypeId').value);

    const supportActivity = {
      id: updating ? this.updatedSupportId : null,
      iepESYGoalId,
      iepId: this.iepId,
      activitySupportTypeId: formGroup.get('activitySupportTypeId').value,
      activitySupportType: activity,
      description: formGroup.get('description').value,
      frequencyPeriod: formGroup.get('frequencyPeriod').value,
      providers,
      otherProvider: formGroup.get('otherProvider').value,
      otherProviderName: formGroup.get('otherProviderName').value,
      otherProviderAgency: formGroup.get('otherProviderAgency').value,
      otherProviderRole: formGroup.get('otherProviderRole').value,
      amendmentId: this.amendmentId,
    } as IepActivitySupportDto;

    if (this.formGroup.get(iepESYGoalId + '_support').valid && !updating) {
      this.iepEsyService.addESYSupport(this.iepId, supportActivity).subscribe((res) => {
        this.activeCall = false;
        this.resetFormValues(supportActivity.iepESYGoalId, 'support');
        if (close) {
          this.formGroup
            .get(iepESYGoalId + '_support')
            .get('supportActivityVisible')
            .patchValue(false);
        }
        this.supportForEdit = null;
        setTimeout(() => {
          this.refreshGoals();
          if (!close) {
            this.formGroup
              .get(iepESYGoalId + '_support')
              .get('supportActivityVisible')
              .patchValue(true);
          }
        }, 1500);
      });
    }

    if (this.formGroup.get(iepESYGoalId + '_support').valid && updating) {
      this.iepEsyService.updateESYSupport(this.iepId, supportActivity).subscribe((res) => {
        this.activeCall = false;
        this.updatingSupport = false;
        this.resetFormValues(iepESYGoalId, 'support');
        this.formGroup
          .get(iepESYGoalId + '_support')
          .get('supportActivityVisible')
          .patchValue(false);
        this.supportForEdit = null;
        setTimeout(() => {
          this.refreshGoals();
        }, 1500);
      });
    }
  }

  onOpenSupportActivity(formGroup) {
    formGroup.get('supportActivityVisible').patchValue(true);
  }

  onCancelSupportActivity(iepGoalId, formGroup) {
    formGroup.get('supportActivityVisible').patchValue(false);
    this.supportForEdit = null;
    this.updatingSupport = false;
    this.resetFormValues(iepGoalId, 'support');
  }

  onOpenService(formGroup) {
    formGroup.get('serviceVisible').patchValue(true);
  }

  onCancelService(iepGoalId, formGroup) {
    formGroup.get('serviceVisible').patchValue(false);
    this.updatingService = false;
    this.serviceForEdit = null;
    this.resetFormValues(iepGoalId);
  }

  onScheduledServiceRemove(serviceTime) {
    this.notificationService.confirmation('Are you sure you want to delete this service time?', () => {
      this.dataSource.data = this.dataSource.data.filter((x) => x !== serviceTime);
    });
  }

  refreshGoals() {
    forkJoin([
      this.iepEsyService.getESYGoals(this.iepId, this.amendmentId),
      this.iepGoalService.getCompletedIepGoals(this.iepId),
    ]).subscribe(([esyGoals, iepGoals]) => {
      this.initializeServiceControls(esyGoals);
      this.initializeSupportControls(esyGoals);
      this.esyGoals = esyGoals;
      this.allIepGoals = iepGoals;
      this.iepEsyService.esyGoalsUpdated.next(this.esyGoals);
    });
  }

  async onEditService(service: IepServiceDto) {
    this.formGroup
      .get(service.iepESYGoalId + '_service')
      ?.get('serviceVisible')
      .patchValue(true);
    this.updatingService = true;
    this.updatedServiceId = service.id;
    this.serviceForEdit = service;

    this.dataSource.data = service.scheduleServices;

    if (!this.formGroup.get(service.iepESYGoalId + '_service')) {
      await this.loadESYGoals(true);
    }

    this.formGroup.get(service.iepESYGoalId + '_service').patchValue({
      description: service.description,
      serviceTypeId: service.serviceTypeId,
      frequencyNumber: service.frequencyNumber,
      frequencyIncrement: service.frequencyIncrement,
      frequencyPeriod: service.frequencyPeriod,
      providers: service.providers.map((x) => x.userId),
      otherProvider: service.otherProvider,
      otherProviderName: service.otherProviderName,
      otherProviderAgency: service.otherProviderAgency,
      otherProviderRole: service.otherProviderRole,
      location: service.location,
      transportationNeeded: service.transportationNeeded,
      transportationServicesDescription: service.transportationServicesDescription,
      hasHealthCarePlan: service.hasHealthCarePlan,
      directConsult: service.directConsult,
      instructionalSupportType: service.instructionalSupportType,
      transportationServices: service.transportationServices?.split(','),
      hasBehaviorInterventionPlan: service.hasBehaviorInterventionPlan,
      projectedStartDate: service.projectedStartDate,
      projectedEndDate: service.projectedEndDate,
      paraprofessionalSupports: service.paraprofessionalSupports?.split(','),
    });
    this.formGroup
      .get(service.iepESYGoalId + '_service')
      .get('projectedStartDate')
      .markAsTouched();
    this.formGroup
      .get(service.iepESYGoalId + '_service')
      .get('projectedEndDate')
      .markAsTouched();
  }

  async onEditSupport(support: IepActivitySupportDto) {
    this.formGroup
      .get(support.iepESYGoalId + '_support')
      .get('supportActivityVisible')
      .patchValue(true);
    this.updatingSupport = true;
    this.updatedSupportId = support.id;
    this.supportForEdit = support;

    if (!this.formGroup.get(support.iepESYGoalId + '_support')) {
      await this.loadESYGoals(true);
    }

    this.formGroup.get(support.iepESYGoalId + '_support').patchValue({
      activitySupportTypeId: support.activitySupportTypeId,
      description: support.description,
      frequencyPeriod: support.frequencyPeriod,
      providers: support.providers.map((x) => x.userId),
      otherProvider: support.otherProvider,
      otherProviderName: support.otherProviderName,
      otherProviderAgency: support.otherProviderAgency,
      otherProviderRole: support.otherProviderRole,
    });
  }

  openEsyAdjustModal(goal: IepESYGoal) {
    const dialogRef = this.dialog.open(AdjustEsyModalComponent, {
      width: '850px',
      data: {
        childFirstName: this.childFirstName,
        amendments: this.amendments,
        amendmentId: this.amendmentId,
        amendmentIsFinalized: this.amendmentIsFinalized,
        lastFinalizedDate: this.lastFinalizedDate,
        hasAmendment: this.hasAmendment,
        hasOpenAmendment: this.hasOpenAmendment,
        showCompareOutput: this.showCompareOutput(goal.goalType === this.goalType.Goal, goal),
        esyGoal: goal,
      },
    });
    dialogRef.afterClosed().subscribe((updatedGoal) => {
      if (updatedGoal) {
        this.esyGoals = this.esyGoals.filter((x) => x.id !== updatedGoal.id);
        this.esyGoals = this.esyGoals.concat(updatedGoal);
        this.iepEsyService.updateESYGoals(this.iepId, this.esyGoals, this.amendmentId).subscribe((res) => {
          this.notificationService.success('ESY Goal Adjusted.');
          this.refreshGoals();
        });
      }
    });
  }

  frequencyWording(goal: IepESYGoal) {
    if (goal.monitoringFrequencyTime) {
      return goal.monitoringFrequencyTime > 1 ? 'times' : 'time';
    } else {
      return goal.iepGoal.frequencyAmount > 1 ? 'times' : 'time';
    }
  }

  getFrequencyText(service) {
    return `${service?.frequencyNumber || '-'} ${service?.frequencyPeriod || '-'} ${service?.frequencyIncrement || '-'} time(s)`;
  }

  getProviderNames(providers, byUserId = false) {
    if (byUserId) {
      return !!providers
        ? this.providers
            .filter((x) => providers.includes(x.id))
            .map((x) => `${x.fullName || ''}`)
            .join(', ')
        : null;
    } else {
      return providers ? providers.map((x) => `${x.firstName} ${x.lastName || ''}`).join(', ') : null;
    }
  }

  trackChanges(goals: IepESYGoal[]) {
    goals.forEach((goal) => {
      this.formGroup
        .get(goal.id + '_service')
        .get('serviceTypeId')
        .valueChanges.subscribe(() => {
          this.resetAdditionalQuestions(goal.id);

          setTimeout(() => {
            if (this.showParaprofessionalSupportQuestions(goal.id)) {
              this.formGroup
                .get(goal.id + '_service')
                .get('paraprofessionalSupports')
                ?.setValidators([Validators.required]);
            } else {
              this.formGroup
                .get(goal.id + '_service')
                .get('paraprofessionalSupports')
                ?.clearValidators();
            }
            this.formGroup.get('paraprofessionalSupports')?.updateValueAndValidity();

            this.formGroup.get('paraprofessionalSupports')?.valueChanges.subscribe((result) => {
              if (this.hasParaprofessionalSupportBehaviorSafety(goal.id)) {
                this.formGroup
                  .get(goal.id + '_service')
                  .get('hasBehaviorInterventionPlan')
                  .setValidators([Validators.required]);
              } else {
                this.formGroup
                  .get(goal.id + '_service')
                  .get('hasBehaviorInterventionPlan')
                  .clearValidators();
                this.formGroup
                  .get(goal.id + '_service')
                  .get('hasBehaviorInterventionPlan')
                  .setValue(null);
              }
              this.formGroup
                .get(goal.id + '_service')
                .get('hasBehaviorInterventionPlan')
                .updateValueAndValidity();

              if (this.hasParaprofessionalSupportHealth(goal.id)) {
                this.formGroup
                  .get(goal.id + '_service')
                  .get('hasHealthCarePlan')
                  .setValidators([Validators.required]);
              } else {
                this.formGroup
                  .get(goal.id + '_service')
                  .get('hasHealthCarePlan')
                  .clearValidators();
                this.formGroup
                  .get(goal.id + '_service')
                  .get('hasHealthCarePlan')
                  .setValue(null);
              }
              this.formGroup
                .get(goal.id + '_service')
                .get('hasHealthCarePlan')
                .updateValueAndValidity();
            });
          }, 200);

          if (this.showDirectConsultQuestions(goal.id)) {
            this.formGroup
              .get(goal.id + '_service')
              .get('directConsult')
              .setValidators([Validators.required]);
          } else {
            this.formGroup
              .get(goal.id + '_service')
              .get('directConsult')
              .clearValidators();
          }

          if (this.showInstructionalSupportTypeQuestions(goal.id)) {
            this.formGroup
              .get(goal.id + '_service')
              .get('instructionalSupportType')
              .setValidators([Validators.required]);
          } else {
            this.formGroup
              .get(goal.id + '_service')
              .get('instructionalSupportType')
              .clearValidators();
          }

          if (this.showTransportationServiceQuestions(goal.id)) {
            this.formGroup
              .get(goal.id + '_service')
              .get('transportationServices')
              .setValidators([Validators.required]);
          } else {
            this.formGroup
              .get(goal.id + '_service')
              .get('transportationServices')
              .clearValidators();
          }
        });

      this.formGroup
        .get(goal.id + '_service')
        .get('transportationServices')
        .valueChanges.subscribe((result) => {
          if (this.hasTransportationServicesOther(goal.id) || this.formGroup.get(goal.id + '_service').get('transportationNeeded')?.value) {
            this.formGroup
              .get(goal.id + '_service')
              .get('transportationServicesDescription')
              .setValidators([Validators.required]);
          } else {
            this.formGroup
              .get(goal.id + '_service')
              .get('transportationServicesDescription')
              .clearValidators();
          }
          if (this.hasTransportationServicesAttendant(goal.id)) {
            this.formGroup
              .get(goal.id + '_service')
              .get('instructionalSupportType')
              .setValidators([Validators.required]);
          } else {
            this.formGroup
              .get(goal.id + '_service')
              .get('instructionalSupportType')
              .clearValidators();
          }
        });
      this.formGroup
        .get(goal.id + '_service')
        .get('transportationNeeded')
        .valueChanges.subscribe((result) => {
          if (result || this.hasTransportationServicesOther(goal.id)) {
            this.formGroup
              .get(goal.id + '_service')
              .get('transportationServicesDescription')
              .setValidators([Validators.required]);
          } else {
            this.formGroup
              .get(goal.id + '_service')
              .get('transportationServicesDescription')
              .clearValidators();
          }
          this.formGroup
            .get(goal.id + '_service')
            .get('transportationServicesDescription')
            .updateValueAndValidity({ emitEvent: false });
        });
    });
  }

  resetAdditionalQuestions(goalId) {
    this.resetAdditionalQuestionValidators(goalId);
    if (this.formGroup.get('paraprofessionalSupports')) {
      this.formGroup.get('paraprofessionalSupports')?.setValue([], { emitEvent: false });
    }
    this.formGroup
      .get(goalId + '_service')
      .get('hasBehaviorInterventionPlan')
      .setValue(null, { emitEvent: false });
    this.formGroup
      .get(goalId + '_service')
      .get('hasHealthCarePlan')
      .setValue(null, { emitEvent: false });
    this.formGroup
      .get(goalId + '_service')
      .get('directConsult')
      .setValue(null, { emitEvent: false });
    this.formGroup
      .get(goalId + '_service')
      .get('instructionalSupportType')
      .setValue(null, { emitEvent: false });
    this.formGroup
      .get(goalId + '_service')
      .get('transportationServices')
      .setValue([], { emitEvent: false });
    this.formGroup
      .get(goalId + '_service')
      .get('transportationServicesDescription')
      .setValue(null, { emitEvent: false });
  }

  resetAdditionalQuestionValidators(goalId) {
    this.formGroup.get('paraprofessionalSupports')?.clearValidators();
    this.formGroup
      .get(goalId + '_service')
      .get('hasBehaviorInterventionPlan')
      .clearValidators();
    this.formGroup
      .get(goalId + '_service')
      .get('hasHealthCarePlan')
      .clearValidators();
    this.formGroup
      .get(goalId + '_service')
      .get('directConsult')
      .clearValidators();
    this.formGroup
      .get(goalId + '_service')
      .get('instructionalSupportType')
      .clearValidators();
    this.formGroup
      .get(goalId + '_service')
      .get('transportationServices')
      .clearValidators();
    this.formGroup
      .get(goalId + '_service')
      .get('transportationServicesDescription')
      .clearValidators();
  }
}
