import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppPermissions } from '../permissions';
import { CaseService } from '../shared/services/case/case.service';
import { AuthService, Roles } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(protected authService: AuthService, protected router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.authService.isAuthenticated) {
      return of(true);
    }
    return this.authService.authenticate(state.url).pipe(map(() => true));
  }
}

// TODO: route this to a `/forbidden` route and make a nice page explaining
// what happened
export const forbiddenRedirect = (router: Router) => router.createUrlTree(['/unauthorized']);

@Injectable({
  providedIn: 'root',
})
export class GenericPermissionsGuard extends AuthGuard {
  constructor(protected caseService: CaseService, protected authService: AuthService, protected router: Router) {
    super(authService, router);
  }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    // Do not use this route guard if there's not a caseId in the url
    // Permissions are by case
    const baseAuth = super.canActivate(next, state);
    if (baseAuth instanceof Observable) {
      const caseId = next.paramMap.get('caseId');
      const reevaluationId = next.paramMap.get('reevaluationId');
      let additionalRequirement = undefined;
      if (next?.data?.additionalRequirement) {
        additionalRequirement = new Map<string, string[]>([
          [next?.data?.additionalRequirementGroup, next?.data?.additionalRequirementRoles],
        ]);
      }

      return baseAuth
        .pipe(
          map(
            () =>
              this.authService.isAllowedByCaseId(caseId, additionalRequirement, next.data.permission) ||
              this.authService.isAllowedBySpecificPermissions(reevaluationId, next.data.permission) ||
              forbiddenRedirect(this.router)
          )
        )
        .toPromise();
    }
  }
}

// Links to off-limits areas of the app should never be exposed anyway...
// but just in case they do (a copy-pasted link) these would prevent them from
// getting into a weird place the UI
export abstract class UserPermissionsGuard extends AuthGuard {
  abstract authCheck: () => boolean;

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const baseAuth = super.canActivate(next, state);
    if (baseAuth instanceof Observable) {
      return baseAuth.pipe(map(() => this.authCheck() || forbiddenRedirect(this.router)));
    }
  }
}

@Injectable({
  providedIn: 'root',
})
export abstract class CustomDataRoleGuard extends AuthGuard {
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const roles: Roles[] = next.data?.roles;
    const baseAuth = super.canActivate(next, state);
    if (baseAuth instanceof Observable) {
      return baseAuth.pipe(map(() => (roles.length > 0 && this.authService.isAllowedByRoles(roles)) || forbiddenRedirect(this.router)));
    }
  }
}

@Injectable({
  providedIn: 'root',
})
export class ManageAllUsersGuard extends UserPermissionsGuard {
  authCheck = () => this.authService.isAllowed(AppPermissions.ManageAllUsers);
}

@Injectable({
  providedIn: 'root',
})
export class SuperAdminGuard extends UserPermissionsGuard {
  authCheck = () => this.authService.isSuperAdmin;
}

@Injectable({
  providedIn: 'root',
})
export class UberAdminGuard extends UserPermissionsGuard {
  authCheck = () => this.authService.isUberAdmin;
}

@Injectable({
  providedIn: 'root',
})
export class SuperAdminOrAchieveDataLeadGuard extends UserPermissionsGuard {
  authCheck = () => this.authService.isSuperAdmin || this.authService.isAchieveDataLead;
}

@Injectable({
  providedIn: 'root',
})
export class SuperAdminOrAchieveDataLeadTechGuard extends UserPermissionsGuard {
  authCheck = () =>
    this.authService.isSuperAdmin ||
    this.authService.isLeaUserManager ||
    ((this.authService.isAchieveDataLead || this.authService.isDataTechnician) && this.authService.canAssignPermissions);
}

@Injectable({
  providedIn: 'root',
})
export class VRManagementOrSuperAdminTechGuard extends UserPermissionsGuard {
  authCheck = () => this.authService.isSuperAdmin || this.authService.isAllowedStatewide(AppPermissions.VRManagement);
}

@Injectable({
  providedIn: 'root',
})
export class ViewCalendarGuard extends UserPermissionsGuard {
  authCheck = () => this.authService.isAllowed(AppPermissions.ViewCalendarPartB, AppPermissions.ViewCalendarPartC);
}

@Injectable({
  providedIn: 'root',
})
export class EarlyACCESSFamilyEngagementReportingGuard extends UserPermissionsGuard {
  authCheck = () => this.authService.isEarlyACCESSFamilyEngagementReporting;
}

@Injectable({
  providedIn: 'root',
})
export class SpecialEducationFamilyEngagementReportingGuard extends UserPermissionsGuard {
  authCheck = () => this.authService.isSpecialEducationFamilyEngagementReporting;
}
@Injectable({
  providedIn: 'root',
})
export class FamilyEngagementAdministratorReportingGuard extends UserPermissionsGuard {
  authCheck = () => this.authService.isFamilyEngagementAdministrator;
}
@Injectable({
  providedIn: 'root',
})
export class MdPermissionsGuard extends AuthGuard {
  constructor(protected caseService: CaseService, protected authService: AuthService, protected router: Router) {
    super(authService, router);
  }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    // Do not use this route guard if there's not a caseId in the url
    // Permissions are by case
    const baseAuth = super.canActivate(next, state);
    if (baseAuth instanceof Observable) {
      const caseId = next.paramMap.get('caseId');
      const mdId = next.paramMap.get('mdId');
      return baseAuth
        .pipe(
          map(
            () =>
              this.authService.isAllowedByCaseId(caseId, undefined, AppPermissions.ReadMD) ||
              this.authService.isAllowedByCaseId(caseId, undefined, AppPermissions.EditMD) ||
              this.authService.isAllowedBySpecificPermissions(mdId, AppPermissions.EditMD) ||
              forbiddenRedirect(this.router)
          )
        )
        .toPromise();
    }
  }
}

@Injectable({
  providedIn: 'root',
})
export class GenericAuthGuard extends AuthGuard {
  constructor(protected authService: AuthService, protected router: Router) {
    super(authService, router);
  }
  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    const baseAuth = super.canActivate(next, state);
    if (baseAuth instanceof Observable) {
      const check = next.data?.check;
      if (check && typeof check === 'function') {
        return baseAuth.pipe(map(() => check(this.authService) || forbiddenRedirect(this.router))).toPromise();
      }
      return baseAuth.toPromise();
    }
  }
}

@Injectable({
  providedIn: 'root',
})
export class PortalPermissionsGuard extends AuthGuard {
  constructor(protected authService: AuthService, protected router: Router) {
    super(authService, router);
  }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    // Do not use this route guard if there's not a learnerId in the url
    const baseAuth = super.canActivate(next, state);
    if (baseAuth instanceof Observable) {
      let grantted = true;

      if (this.authService.isPortalUser) {
        const learnerId = next.paramMap.get('learnerId');
        grantted = this.authService.isAllowedByPortalLearnerId(learnerId);
      }

      return baseAuth.pipe(map(() => grantted || forbiddenRedirect(this.router))).toPromise();
    }
  }
}

@Injectable({
  providedIn: 'root',
})
export class PortalAuthGuard extends AuthGuard {
  constructor(protected authService: AuthService, protected router: Router) {
    super(authService, router);
  }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    const baseAuth = super.canActivate(next, state);
    if (baseAuth instanceof Observable) {
      return baseAuth.pipe(map(() => this.authService.isPortalUser || forbiddenRedirect(this.router))).toPromise();
    }
  }
}

// This will prevent Achieve users from accessing ivrs only pages
@Injectable({
  providedIn: 'root',
})
export class VrAuthGuard extends AuthGuard {
  constructor(protected authService: AuthService, protected router: Router) {
    super(authService, router);
  }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    const baseAuth = super.canActivate(next, state);
    if (baseAuth instanceof Observable) {
      return baseAuth
        .pipe(map(() => this.authService.isSuperAdmin || this.authService.isVrUser || forbiddenRedirect(this.router)))
        .toPromise();
    }
  }
}

// This will prevent ivrs users from accessing Achieve only pages
@Injectable({
  providedIn: 'root',
})
export class NonVrAuthGuard extends AuthGuard {
  constructor(protected authService: AuthService, protected router: Router) {
    super(authService, router);
  }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    const baseAuth = super.canActivate(next, state);
    if (baseAuth instanceof Observable) {
      return baseAuth.pipe(map(() => !this.authService.isVrUser || forbiddenRedirect(this.router))).toPromise();
    }
  }
}

@Injectable({
  providedIn: 'root',
})
export class InquiryLogAuthGuard extends AuthGuard {
  constructor(protected authService: AuthService, protected router: Router) {
    super(authService, router);
  }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    const baseAuth = super.canActivate(next, state);
    if (baseAuth instanceof Observable) {
      return baseAuth
        .pipe(
          map(
            () =>
              this.authService.isSuperAdmin ||
              this.authService.isInquiryLogEditor ||
              this.authService.isInquiryLogViewer ||
              forbiddenRedirect(this.router)
          )
        )
        .toPromise();
    }
  }
}
