import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { IntakeSections } from 'src/app/evaluation/early-access-intake/intake-sections/intake-sections';
import { IntakeService } from 'src/app/evaluation/services/intake.service';
import { AppPermissions } from 'src/app/permissions';
import { ReevaluationService } from 'src/app/reevaluation/services/reevaluation.service';
import { FeatureFlagService } from 'src/app/shared/components/feature-flags/feature-flag.service';
import { ProceduralSafeguardsService } from 'src/app/shared/components/procedural-safeguards/procedural-safeguards.service';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import {
  DialogData,
  UploadDocumentationModalComponent,
  UploadResult,
} from 'src/app/shared/modals/upload-documentation-modal/upload-documentation-modal.component';
import { CaseSummary, IntakeType } from 'src/app/shared/models/case';
import { ConsentForm, ConsentFormType, consentFormTitlesByType } from 'src/app/shared/models/fiie-consent/consent-form';
import { FamilyConsentStatus } from 'src/app/shared/models/fiie-consent/family-consent-status';
import { FiieConsentReadForm } from 'src/app/shared/models/fiie-consent/fiie-consent-read';
import { FileDocument } from 'src/app/shared/models/file-document';
import { FormKey } from 'src/app/shared/models/form-key';
import { SignedFormTypes } from 'src/app/shared/models/form-types';
import { ConsentFormService } from 'src/app/shared/services/consent-form/consent-form.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { RoutingService } from 'src/app/shared/services/routing.service';
import { NewWindowConfig, openDocumentWindow, openNewWindow } from 'src/app/shared/windowHelpers';
import { SignedFormService } from 'src/app/signed-forms/signed-form.service';
import { AuthService } from '../../auth/auth.service';
import { KeyValuePair } from '../../shared/models/key-value-pair';
import { LearnerService } from '../../shared/services/learner/learner.service';
import { AddConsentDialogResult, FamilyConsentAddComponent } from './modals/family-consent-add/family-consent-add.component';
import { UpdateConsentFormComponent } from './update-consent-form/update-consent-form.component';
import { UpdateConsentStatusNotesComponent } from './update-consent-status-notes/update-consent-status-notes.component';
import { ParentType } from '../../shared/models/parent-type';

export interface FamilyConsentParent {
  id: string;
  caseId: string;
  type: ConsentFormType;
  notes: string;
  dateSigned: Date;
  dateReceived: Date;
  status: string;
  statusId: string;
  hasDocuments?: boolean;
  documents: FileDocument[];
  history: FamilyConsentChild[];
  signedBy?: string;
  learnerId: string;
  ifspId: string;
  reevaluationConsentId: string;
  reevaluationFacilitatorId: string;
  iepId: string;
  parentsProvidedFiieConsentDate?: Date;
  createdOn: Date;
  aeaId?: string;
  districtId?: string;
  aeaOrDistrictName?: string;
  signedFormId?: string;
  isComplete: boolean;
}

interface FamilyConsentChild {
  notes: string;
  date: Date;
  status: string;
  statusId: string;
  hasDocuments?: boolean;
  documents: FileDocument[];
}

@Component({
  selector: 'app-family-consent',
  templateUrl: './family-consent.component.html',
  styleUrls: ['./family-consent.component.scss'],
})
export class FamilyConsentComponent implements OnInit, OnChanges {
  @Input() learnerId: string;
  @Input() caseId: string;
  @Input() draftReevaluationId: string;
  @Input() isCaseOwner: boolean;
  @Input() types: ConsentFormType[];
  @Input() canAddNew = true;
  @Input() inCard = false;
  @Input() psgProvided: boolean;
  @Input() maxAmount: number;
  @Input() cases: Array<CaseSummary>;

  @Output() consentFormsLoaded = new EventEmitter<ConsentForm[]>();
  @Output() documentUploaded = new EventEmitter<void>();

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  shortDateFormat = shortDateFormat;
  consentFormType = ConsentFormType;
  consentStatus = FamilyConsentStatus;
  availConsentFormTypeOptions: KeyValuePair[] = [];
  learnerHasWorkableCase = false;
  SignedFormTypes = SignedFormTypes;
  permissions = AppPermissions;
  requestForFiieConsentAdditionalRequirements = new Map<string, string[]>([['TeamMember', ['AeaEdit']]]);

  get fiieConsentFormId(): string {
    return this.cases.find((x) => !!x.fiieConsentFormId)?.fiieConsentFormId;
  }

  get isSuperAdmin() {
    return this.authService.isSuperAdmin;
  }

  get isPortalUser() {
    return this.authService.isPortalUser;
  }

  get hasEditPermission() {
    return this.cases?.some((x) => this.authService.isAllowedByCaseId(x.caseId, undefined, AppPermissions.EditFamilyConsent));
  }

  get isActivePartBCase() {
    return this.cases.find((x) => x.isActive && x.exitFinalizedOn === null && x.intakeType == IntakeType.PartB)?.id != null;
  }

  constructor(
    private readonly dialog: MatDialog,
    private readonly consentFormService: ConsentFormService,
    private readonly proceduralSafeguardsService: ProceduralSafeguardsService,
    private readonly intakeService: IntakeService,
    private readonly notificationService: NotificationService,
    public readonly routingService: RoutingService,
    private readonly learnerService: LearnerService,
    readonly authService: AuthService,
    private readonly featureFlagService: FeatureFlagService,
    private signedFormService: SignedFormService,
    private readonly reevaluationService: ReevaluationService
  ) {}

  filteredFamilyConsentData: FamilyConsentParent[] = [];
  untouchedFamilyConsentData: FamilyConsentParent[] = [];
  consentTypeFilterOptions: KeyValuePair[] = [];
  consentTypeSelected = new FormControl();

  familyConsentData: FamilyConsentParent[] = [];
  consentFormTitlesByType = consentFormTitlesByType;
  showPaginator = false;

  consentTypeDescSortPosition = null;
  notesDescSortPosition = null;
  statusDescSortPosition = null;
  dateDescSortPosition = null;

  async ngOnInit(): Promise<void> {
    this.proceduralSafeguardsService.proceduralSafeguardsUpdated$.subscribe(async () => {
      //Refresh on procedural safeguard changes to support part c eval and assessment consent pending->request
      await this.populateConsentTable();
    });
    this.consentFormService.consentsUpdated$.subscribe(async () => {
      await this.populateConsentTable();
    });
    await this.populateConsentTable();
    await this.loadAvailableConsentTypes();
    this.setupConsentTypeFilter();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.cases?.currentValue !== changes.cases?.previousValue) {
      this.caseId = null;
      this.learnerHasWorkableCase = this.cases.find((x) => x.isActive && x.exitFinalizedOn === null)?.id != null;
    }
  }

  refresh = async (signedFormId: string, caseId: string) => {
    //Update the single consent form if found, otherwise perform full refresh
    const consentIndex = this.filteredFamilyConsentData.findIndex((cf) => cf.signedFormId == signedFormId);
    if (consentIndex != -1) {
      const consentForm = this.filteredFamilyConsentData[consentIndex];

      const updated = await this.consentFormService.getConsentForm(caseId, consentForm.id).toPromise();
      const updatedFormParent = this.convertToFamilyConsentParent(updated);
      this.filteredFamilyConsentData[consentIndex] = updatedFormParent;
    } else {
      await this.populateConsentTable();
    }
  };

  setupConsentTypeFilter() {
    this.consentTypeSelected.valueChanges.subscribe((val) => {
      if (this.familyConsentData.length === 0) {
        this.familyConsentData = this.filteredFamilyConsentData;
      }
      if (val) {
        this.filteredFamilyConsentData = this.familyConsentData.filter((x) => x.type === val);
      } else {
        this.filteredFamilyConsentData = this.familyConsentData;
      }
    });
  }

  convertToFamilyConsentParent(consentForm: ConsentForm): FamilyConsentParent {
    return {
      id: consentForm?.id,
      caseId: consentForm.caseId,
      type: consentForm.type,
      learnerId: consentForm.learnerId,
      statusId: consentForm.lastStatus?.id,
      status: consentForm.lastStatus?.status,
      notes: consentForm.lastStatus?.comments,
      dateSigned: consentForm.lastStatus?.dateSigned,
      dateReceived: consentForm.lastStatus?.dateReceived,
      documents: consentForm.lastStatus?.documents,
      history: [],
      ifspId: consentForm.ifspId,
      iepId: consentForm.iepId,
      reevaluationConsentId: consentForm.reevaluationConsentId,
      reevaluationFacilitatorId: consentForm.reevaluationFacilitatorId,
      parentsProvidedFiieConsentDate: consentForm.parentsProvidedFiieConsentDate,
      createdOn: consentForm.createdOn,
      aeaId: consentForm.aeaId,
      districtId: consentForm.districtId,
      aeaOrDistrictName: consentForm.aeaOrDistrictName,
      signedFormId: consentForm.signedFormId,
      isComplete: consentForm.isComplete,
    };
  }

  async populateConsentTable(): Promise<void> {
    let consentForms = new Array<ConsentForm>();
    if (this.learnerId) {
      consentForms = await this.consentFormService.getConsentFormsForLearner(this.learnerId, this.types, this.maxAmount).toPromise();
    } else if (this.caseId) {
      consentForms = await this.consentFormService.getConsentForms(this.caseId, this.types, this.maxAmount).toPromise();
    }

    this.consentFormsLoaded.emit(consentForms);
    this.consentTypeSelected.setValue(null);
    this.familyConsentData = new Array<FamilyConsentParent>();
    this.filteredFamilyConsentData = new Array<FamilyConsentParent>();

    const uniqueTypes = new Set<string>();

    consentForms.forEach((consentForm) => {
      const consentFormParent = this.convertToFamilyConsentParent(consentForm);

      uniqueTypes.add(consentForm.type);

      consentForm.statuses?.forEach((statusChange) => {
        const change = {
          notes: statusChange.comments,
          status: statusChange.status,
          statusId: statusChange?.id,
          date: statusChange.dateSigned,
          documents: statusChange.documents,
        };
        consentFormParent.history.push(change);
      });
      consentFormParent.history.shift(); // The first history is the parent
      this.filteredFamilyConsentData.push(consentFormParent);
      this.untouchedFamilyConsentData = this.filteredFamilyConsentData.slice();
    });
    uniqueTypes.forEach((x) => {
      this.consentTypeFilterOptions.push(new KeyValuePair(x, consentFormTitlesByType[x]));
    });
  }

  checkCaseOwner(caseId: string): boolean {
    return this.isCaseOwner || this.cases?.some((x) => x.id === caseId && x.caseOwnerId === this.authService.user?.id);
  }

  checkReevaluationConsent(consentForm) {
    return consentForm.type === 'Reevaluation' && consentForm.reevaluationFacilitatorId === this.authService.user?.id;
  }

  openDialog(e: Event, consentForm: FamilyConsentParent) {
    e.stopPropagation();
    this.trigger.closeMenu();
    const dialogRef: MatDialogRef<UpdateConsentFormComponent, AddConsentDialogResult> = this.dialog.open(UpdateConsentFormComponent, {
      width: '400px',
      data: {
        caseId: consentForm.caseId,
        title: consentFormTitlesByType[this.consentFormType[consentForm.type]],
        consentFormId: consentForm.id,
        currentStatus: consentForm.status,
        createdOn: consentForm.createdOn,
        parentsProvidedFiieConsentDate: consentForm.parentsProvidedFiieConsentDate,
        consentFormType: consentForm.type,
        dateReceived: consentForm.dateReceived,
      },
    });

    dialogRef.afterClosed().subscribe(async (statusModel) => {
      if (consentForm.type === ConsentFormType.FiieConsent || consentForm.type === ConsentFormType.InitialFamilyEvaluationAndAssessment) {
        this.learnerService.learnerSummary = {
          ...this.learnerService.learnerSummary,
          showEvaluation: this.learnerService.learnerSummary.showEvaluation || statusModel.status === FamilyConsentStatus.Approved,
        };
      } else if (consentForm.type === ConsentFormType.Reevaluation) {
        this.loadAvailableConsentTypes();
      }
    });
  }

  openNotesDialog(e: Event, caseId: string, statusId: string, notes: string) {
    this.trigger.closeMenu();
    const dialogRef = this.dialog.open(UpdateConsentStatusNotesComponent, {
      width: '400px',
      data: { caseId: caseId, id: statusId, comments: notes },
    });

    dialogRef.afterClosed().subscribe(async () => {});
  }

  uploadConsentIntakeDocument(e: Event, caseId: string, consentFormType: ConsentFormType) {
    e.stopPropagation();
    this.trigger.closeMenu();

    const dialogRef = this.dialog.open(UploadDocumentationModalComponent, {
      data: {
        title: 'Upload Completed Form',
        fileName: this.getFileName(consentFormType),
        type: consentFormType,
        learnersName: this.learnerService.learnerSummary.fullName,
      },
      width: '728px',
    });

    dialogRef.afterClosed().subscribe((result: UploadResult[]) => {
      if (result) {
        const formData = new FormData();
        result.forEach((element) => {
          formData.append('titles', element.title);
          formData.append('documents', element.file, element.file.name);
          formData.append('types', element.type);
          formData.append('sections', this.getSection(consentFormType));
          formData.append('consents', 'true');
          formData.append('isPHCPs', 'false');
          formData.append('consentFormTypes', consentFormType);
        });
        this.intakeService.uploadDocumentation(formData, caseId).subscribe(async () => {
          await this.finishUploadingMethods();
        });
      }
    });
  }

  uploadConsentStatusDocument(e: Event, status: any, consentFormType: ConsentFormType) {
    e.stopPropagation();
    this.trigger.closeMenu();

    const dialogRef = this.dialog.open(UploadDocumentationModalComponent, {
      data: {
        title: 'Upload Completed Form',
        fileName: this.getFileName(consentFormType),
        type: consentFormType,
        learnersName: this.learnerService.learnerSummary.fullName,
      } as DialogData,
      width: '728px',
    });

    dialogRef.afterClosed().subscribe((result: Array<any>) => {
      if (result) {
        const formData = new FormData();
        result.forEach((element) => {
          formData.append('titles', element.title);
          formData.append('documents', element.file, element.file.name);
          formData.append('types', element.type);
          formData.append('consentFormTypes', consentFormType);
        });
        this.consentFormService.uploadDocumentation(formData, status.caseId, status.statusId).subscribe(() => {
          this.finishUploadingMethods();
        });
      }
    });
  }

  getFileName(type: ConsentFormType) {
    switch (type) {
      case ConsentFormType.FiieConsent: {
        return 'FIIE Consent Form';
      }
      case ConsentFormType.PostReferralScreening: {
        return 'Consent for Post-Referral Screening';
      }
      case ConsentFormType.ReleaseAndExchangeInformation: {
        return 'Consent to Release and Exchange Information';
      }
      default: {
        return null;
      }
    }
  }

  openDocument(documents: any) {
    if (!documents || documents.length === 0) return;

    // open the latest document
    const latestDoc = documents
      .map((d) => d.document)
      .sort((a, b) => {
        return new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime();
      })[0];

    return openDocumentWindow(this.learnerId, latestDoc.id);
  }

  getSection(type: ConsentFormType) {
    switch (type) {
      case ConsentFormType.PostReferralScreening: {
        return IntakeSections.NextStepsPostReferralConsent;
      }
      case ConsentFormType.ReleaseAndExchangeInformation: {
        return IntakeSections.NextStepsInitialConsent;
      }
      default: {
        return IntakeSections.NextSteps;
      }
    }
  }

  async finishUploadingMethods(): Promise<void> {
    await this.populateConsentTable();
    this.documentUploaded.next();
    this.notificationService.success('Upload complete');
  }

  async openSignedForm(signedFormId: string) {
    const pdf = await this.signedFormService.getSignedFormPdf(signedFormId);
    if (!pdf?.url) {
      this.notificationService.alert('Error opening consent form');
      return;
    }

    window.open(pdf.url);
  }

  openReadOnlyFiie(caseId: string) {
    if (this.featureFlagService.featureOn('outputConsentPwnFiieEnabled')) {
      if (this.fiieConsentFormId) {
        this.consentFormService.getFiieConsentForm(this.fiieConsentFormId, caseId).subscribe(async (res: FiieConsentReadForm) => {
          if (res.signedFormId) {
            await this.openSignedForm(res.signedFormId);
          } else {
            this.consentFormService
              .createFiieConsentPdf(caseId, this.fiieConsentFormId)
              .subscribe(async (signedFormId: string) => await this.openSignedForm(signedFormId));
          }
        });
      }
    }
  }

  openAddConsentDialog(e: Event) {
    e.stopPropagation();
    const dialogRef: MatDialogRef<FamilyConsentAddComponent, AddConsentDialogResult> = this.dialog.open(FamilyConsentAddComponent, {
      width: '800px',
      data: {
        availConsentFormTypeOptions: this.availConsentFormTypeOptions,
      },
    });

    dialogRef.afterClosed().subscribe(async (data) => {
      if (data) {
        if (data.type?.value === ConsentFormType.Reevaluation) {
          if (data.externalForm?.isNew) {
            await this.reevaluationService.createReevaluationConsent(data.reevaluationId).toPromise();
          }

          const res = await this.reevaluationService.finalizeReevaluationConsent(data.reevaluationId, data.externalForm).toPromise();
          if (!res.succeeded) {
            this.notificationService.alert(res.errors.toString(), 'Error finalizing Reeval Consent');
            return;
          }

          if (data.externalForm.recommendAssessment === false) {
            this.documentUploaded.emit();
          } else if (data.status !== FamilyConsentStatus.Requested) {
            const reevaluationForm = await this.reevaluationService.getReevaluationConsent(data.reevaluationId, null).toPromise();
            const statusModel = {
              status: data.status,
              consentFormId: reevaluationForm.consentFormId,
              comments: '',
              documents: [],
              dateReceived: data.dateReceived,
              dateSigned: data.dateSigned,
              signedBy: data.signedBy,
            };

            await this.consentFormService.addConsentFormStatus(reevaluationForm.caseId, statusModel).toPromise();
          }
          this.afterAddingConsent();
        } else {
          const consentForm: ConsentForm = {
            caseId: data.type.caseId,
            type: data.type.value,
            aeaId: data.aea?.aeaId,
            districtId: data.district?.districtId,
            agency: data.agency,
            statuses: [
              {
                status: data.status,
                parentType: ParentType[data.parentType],
                dateReceived: data.dateReceived,
                dateSigned: data.dateSigned,
                signedBy: data.signedBy,
                documents: [],
                comments: data.notes,
              },
            ],
            createdOn: new Date(),
            isComplete: false,
          };
          this.consentFormService.createConsentForms(data.type.caseId, consentForm).subscribe(() => {
            if (
              data.type.value === ConsentFormType.FiieConsent ||
              data.type.value === ConsentFormType.InitialFamilyEvaluationAndAssessment
            ) {
              this.learnerService.learnerSummary = {
                ...this.learnerService.learnerSummary,
                showEvaluation: this.learnerService.learnerSummary.showEvaluation || data.status === FamilyConsentStatus.Approved,
              };
            }
            this.afterAddingConsent();
          });
        }
      }
    });
  }

  private async afterAddingConsent() {
    await this.loadAvailableConsentTypes();
    await this.populateConsentTable();
    this.notificationService.success('Consent added');
  }

  canUploadDocument(consentForm: FamilyConsentParent) {
    const allowedStatuses = [
      FamilyConsentStatus.Approved,
      FamilyConsentStatus.Pending,
      FamilyConsentStatus.Requested,
      FamilyConsentStatus.Revoked,
      FamilyConsentStatus.Declined,
    ];
    return consentForm && allowedStatuses.find((v) => v === consentForm.status);
  }

  electronicallySignForm(consentForm: FamilyConsentParent) {
    const formKey = this.mapConsentFormTypeToFormKey(consentForm.type);
    const config: NewWindowConfig = {
      path: '/consent-signature',
      params: {
        learnerId: consentForm.learnerId,
        caseId: consentForm.caseId,
        consentFormId: consentForm.id,
        formKey,
      },
      popup: true,
      isPreview: true,
      width: '1450px',
    };
    openNewWindow(config);
  }

  mapConsentFormTypeToFormKey(formType: ConsentFormType): FormKey {
    switch (formType) {
      case ConsentFormType.InitialFamilyEvaluationAndAssessment:
        return FormKey.EarlyAccessEvaluationAssessmentConsent;
      case ConsentFormType.FiieConsent:
        return FormKey.ConsentForFullAndIndividualEvaluation;
      case ConsentFormType.EarlyAccessServices:
        return FormKey.ConsentForEarlyAccessServices;
      case ConsentFormType.InitialIepServices:
        return FormKey.ConsentForSpecialEducationRelatedServices;
      case ConsentFormType.PostReferralScreening:
        return FormKey.ConsentForEarlyAccessPostReferralScreening;
      case ConsentFormType.ParentGuardianMedicaidReimbursement:
        return FormKey.ParentGuardianMedicaidReimbursement;
    }
  }

  actionsDisabled(consentForm: FamilyConsentParent): boolean {
    return (
      consentForm.status === FamilyConsentStatus.Editing ||
      (!this.psgProvided && this.isActivePartBCase && consentForm.type === ConsentFormType.InitialFamilyEvaluationAndAssessment)
    );
  }

  onSortHeader(column: string) {
    switch (column) {
      case 'consentType':
        this.notesDescSortPosition = null;
        this.statusDescSortPosition = null;
        this.dateDescSortPosition = null;
        this.sortByType('consentTypeDescSortPosition', 'type', true);
        break;
      case 'notes':
        this.consentTypeDescSortPosition = null;
        this.statusDescSortPosition = null;
        this.dateDescSortPosition = null;
        this.sortByType('notesDescSortPosition', 'notes');
        break;
      case 'status':
        this.notesDescSortPosition = null;
        this.consentTypeDescSortPosition = null;
        this.dateDescSortPosition = null;
        this.sortByType('statusDescSortPosition', 'status');
        break;
      case 'date':
        this.notesDescSortPosition = null;
        this.statusDescSortPosition = null;
        this.consentTypeDescSortPosition = null;
        this.sortByType('dateDescSortPosition', 'date');
        break;
    }
  }

  private sortByType(descendingFlag: string, property: string, consentType?: boolean) {
    // true [↑], false [↓], null []
    this[descendingFlag] = this[descendingFlag] === null ? true : this[descendingFlag] === true ? false : null;

    // If it's null set to original list, else sort by ascending or descding by property
    if (this[descendingFlag] === null) {
      this.filteredFamilyConsentData = this.untouchedFamilyConsentData.slice();
    } else {
      this.filteredFamilyConsentData = this.untouchedFamilyConsentData
        .slice()
        .sort((a, b) =>
          this[descendingFlag] === false
            ? (consentType ? consentFormTitlesByType[a[property]]?.toLowerCase() : a[property]?.toLowerCase()) >
              (consentType ? consentFormTitlesByType[b[property]]?.toLowerCase() : b[property]?.toLowerCase())
              ? 1
              : -1
            : this[descendingFlag] === true
            ? (consentType ? consentFormTitlesByType[a[property]]?.toLowerCase() : a[property]?.toLowerCase()) <
              (consentType ? consentFormTitlesByType[b[property]]?.toLowerCase() : b[property]?.toLowerCase())
              ? 1
              : -1
            : null
        );
    }
  }

  private async loadAvailableConsentTypes(): Promise<void> {
    if (this.caseId) {
      const options = await this.consentFormService.getAvailableConsentForms(this.caseId).toPromise();
      this.availConsentFormTypeOptions = options.map(
        (c) => new KeyValuePair({ value: c, caseId: this.caseId }, consentFormTitlesByType[c])
      );
    } else {
      this.availConsentFormTypeOptions = [];
      for (const c of (this.cases ?? []).filter((x) => x.isActive)) {
        const options = await this.consentFormService.getAvailableConsentForms(c.caseId).toPromise();
        for (const o of options)
          if (!this.availConsentFormTypeOptions.some((x) => x.key.value === o)) {
            this.availConsentFormTypeOptions.push(new KeyValuePair({ value: o, caseId: c.caseId }, consentFormTitlesByType[o]));
          }
      }
    }
  }
}
