import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AppPermissions } from 'src/app/permissions';
import { ReevaluationService } from 'src/app/reevaluation/services/reevaluation.service';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { openPdfWindow } from 'src/app/shared/windowHelpers';
import { AuthService } from '../../auth/auth.service';
import { EvaluationService } from '../../evaluation/services/evaluation.service';
import { BaseComponent } from '../../shared/components/base-component/base-component';
import { CaseSummary, EvaluationForLeanerManagement, EvaluationType, IntakeType } from '../../shared/models/case';
import { DeactivationService } from '../../shared/services/deactivation.service';
import { LearnerService } from '../../shared/services/learner/learner.service';
import { NotificationService } from '../../shared/services/notification.service';

@Component({
  selector: 'app-evaluation',
  templateUrl: './evaluation.component.html',
  styleUrls: ['./evaluation.component.scss'],
})
export class EvaluationComponent extends BaseComponent implements OnInit {
  displayedColumns: string[] = ['date', 'type'];
  evaluations = new Array<EvaluationForLeanerManagement>();
  dataSource = new MatTableDataSource<any>();
  shortDateFormat = shortDateFormat;
  learnerId: string;
  evaluationType = EvaluationType;
  cases: CaseSummary[];
  intakeType = IntakeType;
  activeCall = false;
  learnerHasWorkableCase = false;
  permissions = AppPermissions;
  canCreate = false;

  get activeCases(): Array<CaseSummary> {
    return this.cases?.filter((x) => x.isActive && !x.exitFinalizedOn);
  }

  get activeCasePartB(): CaseSummary {
    return this.cases?.find((x) => x.isActive && x.intakeType === IntakeType.PartB);
  }

  get activeCasePartC(): CaseSummary {
    return this.cases?.find((x) => x.isActive && x.intakeType === IntakeType.PartC);
  }

  constructor(
    public readonly authService: AuthService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly evaluationService: EvaluationService,
    private readonly notificationService: NotificationService,
    private readonly reevaluationService: ReevaluationService,
    private readonly learnerService: LearnerService,
    deactivationService: DeactivationService
  ) {
    super(deactivationService);
  }

  async ngOnInit(): Promise<void> {
    this.learnerId = this.route.parent?.snapshot.paramMap.get('learnerId');
    await this.loadData();
  }

  async loadData(): Promise<void> {
    this.cases = await this.learnerService.getCases(this.learnerId).toPromise();
    this.learnerHasWorkableCase = this.cases.find((x) => x.isActive && x.exitFinalizedOn === null)?.id != null;
    this.evaluations = await this.evaluationService.getLearnerEvaluations(this.learnerId).toPromise();

    this.canCreate = this.activeCases.some((x) => {
      if (x.intakeType === IntakeType.PartB) {
        return (
          this.authService.isAllowedByCaseId(x.id, null, AppPermissions.EditEvaluationPartB) ||
          this.authService.isAllowedBySpecificPermissions(this.getActiveEvaluation(x)?.id, AppPermissions.EditEvaluationPartB)
        );
      } else {
        return (
          this.authService.isAllowedByCaseId(x.id, null, AppPermissions.EditEvaluationPartC) ||
          this.authService.isAllowedBySpecificPermissions(this.getActiveEvaluation(x)?.id, AppPermissions.EditEvaluationPartC)
        );
      }
    });

    if (!this.evaluations) {
      return;
    }

    this.dataSource.data = this.evaluations;
  }

  public hasEvaluations(caseSummary: CaseSummary): boolean {
    return this.evaluations.some((x) => x.caseId === caseSummary.id);
  }

  public getActiveEvaluation(caseSummary: CaseSummary): EvaluationForLeanerManagement {
    return this.evaluations.find((x) => !x.isFinalized && x.caseId === caseSummary.id);
  }

  showEvalButton(caseSummary: CaseSummary) {
    return (
      !this.hasEvaluations(caseSummary) &&
      !caseSummary.learner.movingFromOutOfState &&
      !caseSummary.learner.eligibleInLegacySystem &&
      !caseSummary.learner.addingFromOutOfState
    );
  }

  showReevalButton(caseSummary: CaseSummary) {
    if (caseSummary.exitFinalizedOn) {
      return false;
    }

    if (
      caseSummary.learner.movingFromOutOfState ||
      caseSummary.learner.eligibleInLegacySystem ||
      caseSummary.learner.addingFromOutOfState
    ) {
      return caseSummary.intakeType === this.intakeType.PartB && !this.getActiveEvaluation(caseSummary);
    } else {
      return caseSummary.intakeType === this.intakeType.PartB && this.hasEvaluations(caseSummary) && !this.getActiveEvaluation(caseSummary);
    }
  }

  hasEvaluationConsent(caseSummary: CaseSummary) {
    return caseSummary?.learner?.eligibleInLegacySystemPartC === true ? true : caseSummary.hasEvaluationConsent;
  }

  onBeginEvaluation(caseSummary: CaseSummary) {
    if (!this.learnerHasWorkableCase) {
      return;
    }
    caseSummary.isBusy = true;
    this.evaluationService.add(caseSummary.id).subscribe(
      (result) => {
        this.router.navigate(['/', 'cases', caseSummary.id, EvaluationType.Evaluation.toLowerCase(), result.id, 'overview']).then();
      },
      (response: HttpErrorResponse) => {
        const noConsent = 'Consent not provided';
        if (response.error?.find((x) => x.description === noConsent)) {
          this.notificationService.error(noConsent);
        }
        caseSummary.isBusy = false;
      }
    );
  }

  onBeginReevaluation(caseSummary: CaseSummary) {
    if (!this.learnerHasWorkableCase) {
      return;
    }

    this.reevaluationService.add(caseSummary.id).subscribe(
      (result) => {
        this.router.navigate(['/', 'cases', caseSummary.id, EvaluationType.Reevaluation.toLowerCase(), result, 'overview']).then();
      },
      (error) => this.notificationService.error(error.error)
    );
  }

  onGoToEvaluation(evaluation: EvaluationForLeanerManagement) {
    if (!this.learnerHasWorkableCase || !evaluation || !evaluation.id) {
      return;
    }

    const summary = this.cases.find((x) => x.id === evaluation.caseId);
    const consentCheck = evaluation.type === EvaluationType.Evaluation && !summary?.hasEvaluationConsent;
    if (summary.learner.eligibleInLegacySystemPartC === true ? false : consentCheck) return;

    if (evaluation.isFinalized && evaluation.intakeType === IntakeType.PartB) {
      this.handleFinalizedEvaluationPartB(evaluation);
      return;
    }

    if (evaluation.intakeType == IntakeType.PartB) {
      this.goToEvaluationPartB(evaluation);
    } else {
      this.goToEvaluationPartC(evaluation);
    }
  }

  get readOnlycheck() {
    return this.authService.IsReadOnly;
  }

  canEditPartB(evaluation: EvaluationForLeanerManagement) {
    return this.authService.isAllowedByCaseId(
      evaluation.caseId,
      new Map<string, string[]>([['TeamMember', ['ServiceCoordinator']]]),
      AppPermissions.EditEvaluationPartB
    );
  }

  canEditEvaluation(evaluation: EvaluationForLeanerManagement) {
    if (evaluation.intakeType === IntakeType.PartB) {
      return (
        this.authService.isAllowedByCaseId(
          evaluation.caseId,
          new Map<string, string[]>([['TeamMember', ['ServiceCoordinator']]]),
          AppPermissions.EditEvaluationPartB
        ) || this.authService.isAllowedBySpecificPermissions(evaluation.id, AppPermissions.EditEvaluationPartB)
      );
    } else {
      return (
        this.authService.isAllowedByCaseId(evaluation.caseId, undefined, AppPermissions.EditEvaluationPartC) ||
        this.authService.isAllowedBySpecificPermissions(evaluation.id, AppPermissions.EditEvaluationPartC) ||
        this.authService.isSuperAdmin
      );
    }
  }

  private handleFinalizedEvaluationPartB(evaluation: EvaluationForLeanerManagement) {
    const hasEditPermission = this.authService.isAllowedByCaseId(
      evaluation.caseId,
      new Map<string, string[]>([['TeamMember', ['ServiceCoordinator']]]),
      AppPermissions.EditEvaluationPartB
    );
    const hasViewPermission = this.authService.isAllowedByCaseId(evaluation.caseId, undefined, AppPermissions.ViewEvaluationPartB);
    const canViewIfEligible =
      this.activeCasePartB.eligibilityDecision &&
      this.authService.isAllowedByCaseId(evaluation.caseId, undefined, AppPermissions.ViewEvaluationWhenEligible);

    if (hasEditPermission || hasViewPermission || canViewIfEligible) {
      openPdfWindow(this.learnerId, evaluation.eerDocumentId);
    }
  }

  private goToEvaluationPartB(evaluation: EvaluationForLeanerManagement) {
    const hasEditPermission =
      this.authService.isAllowedByCaseId(
        evaluation.caseId,
        new Map<string, string[]>([['TeamMember', ['ServiceCoordinator']]]),
        AppPermissions.EditEvaluationPartB
      ) || this.authService.isAllowedBySpecificPermissions(evaluation.id, AppPermissions.EditEvaluationPartB);
    const hasViewPermission = this.authService.isAllowedByCaseId(evaluation.caseId, undefined, AppPermissions.ViewEvaluationPartB);
    if (hasEditPermission) {
      this.navById(evaluation.id, evaluation.type, evaluation.caseId);
    } else if (hasViewPermission) {
      openPdfWindow(this.learnerId, evaluation.eerDocumentId);
    }
  }

  private goToEvaluationPartC(evaluation: EvaluationForLeanerManagement) {
    const hasEditPermission =
      this.authService.isAllowedByCaseId(evaluation.caseId, undefined, AppPermissions.EditEvaluationPartC) ||
      this.authService.isAllowedBySpecificPermissions(evaluation.id, AppPermissions.EditEvaluationPartC);
    const hasViewPermission = this.authService.isAllowedByCaseId(evaluation.caseId, undefined, AppPermissions.ViewEvaluationPartC);

    if (hasEditPermission) {
      this.navById(evaluation.id, evaluation.type, evaluation.caseId);
    }
  }

  private navById(id, evaluationType, caseId) {
    this.router.navigate(['/', 'cases', caseId, evaluationType.toLowerCase(), id, 'overview']).then();
  }

  showEditButton(activeCase: CaseSummary) {
    const evaluation = this.getActiveEvaluation(activeCase);
    const consentCheck =
      evaluation?.type === EvaluationType.Reevaluation ||
      (evaluation?.type === EvaluationType.Evaluation && this.hasEvaluationConsent(activeCase));
    return evaluation && this.canEditEvaluation(evaluation) && consentCheck;
  }
}
