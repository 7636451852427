import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import {
  DialogData,
  UploadDocumentationModalComponent,
} from 'src/app/shared/modals/upload-documentation-modal/upload-documentation-modal.component';
import { FileDocument } from 'src/app/shared/models/file-document';
import { HelpArea } from 'src/app/shared/services/help/help';
import { HelpModalConfig, HelpService } from 'src/app/shared/services/help/help.service';
import { LearnerService } from 'src/app/shared/services/learner/learner.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { Fba, FbaStatus } from '../../behavior-discipline/fba/fba';
import { FbaService } from '../../behavior-discipline/fba/services/fbaService.service';
import { BipServicesService } from '../../behavior-discipline/services/bip-services.service';
import { AppPermissions } from '../../permissions';
import { AreYouSureComponent } from '../../shared/components/are-you-sure-modal/are-you-sure.component';
import { IepCreateBipModalComponent } from '../modals/iep-create-bip-modal/iep-create-bip-modal.component';
import { IepStatus } from '../models/iep';
import { Plaafp, SpecialFactorHelper } from '../models/plaafp';
import { IepService } from '../services/iep.service';

enum SpecialFactorsSections {
  AEM = 'Accessible Education Materials',
  AssistiveTech = 'Assistive Technology',
  Vision = 'Vision',
  Communication = 'Communication',
  EnglishLearner = 'English Learner',
  Health = 'Health',
  Hearing = 'Hearing',
  SocialEmotional = 'Social Emotional Behavior',
}

@Component({
  selector: 'app-iep-special-factors-bar',
  templateUrl: './iep-special-factors-bar.component.html',
  styleUrls: ['./iep-special-factors-bar.component.scss'],
})
export class IepSpecialFactorsBarComponent implements OnInit, OnDestroy {
  @ViewChild('bipWillBeDevelopedRef') bipWillBeDevelopedRef;
  @ViewChild('fbaWillBeDevelopedRef') fbaWillBeDevelopedRef;

  @Input() formGroup: FormGroup;
  @Input() isAmendment = false;
  @Input() hasOpenAmendment = false;
  @Input() amendmentIsFinalized = false;
  @Input() lastFinalizedDate?: Date;
  @Input() amendingPlaafp = true;
  @Input() bipNeeded;
  @Input() caseId: string;
  @Input() bipId: string;
  @Input() fbaId: string;
  @Input() specialFactorHelpers: SpecialFactorHelper[];
  @Input() plaafpModel: Plaafp;

  permissions = AppPermissions;
  specialFactorsSections = SpecialFactorsSections;
  selectedSpecialFactor: number;
  subscription = new Subscription();
  iepId: string;
  previousPlaafp: Plaafp;
  activeCall = false;

  individualHealthPlanDocuments: FileDocument[];
  baseUrl: string;

  onSelectSpecialFactor(num: number) {
    this.selectedSpecialFactor = num;
  }

  constructor(
    private helpService: HelpService,
    private iepService: IepService,
    private bipService: BipServicesService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private fbaService: FbaService,
    private learnerService: LearnerService
  ) {}

  ngOnInit(): void {
    this.iepId = this.route.snapshot.paramMap.get('iepId')
      ? this.route.snapshot.paramMap.get('iepId')
      : this.route.parent.snapshot.paramMap.get('iepId');
    this.baseUrl = `api/iep/${this.iepId}/documents/`;
    this.iepService.getByCaseId(this.caseId).subscribe((ieps) => {
      const previousIep = ieps.find((x) => x.isIep && x.iepStatus === IepStatus.Active);
      if (previousIep) {
        this.iepService.getPlaafp(previousIep.id).subscribe((iep) => {
          this.previousPlaafp = iep;
        });
      }
    });

    const updateAllFactors = () => {
      if (
        [
          this.formGroup.controls.limitedEnglishAddressed,
          this.formGroup.controls.behaviorAddressed,
          this.formGroup.controls.accessibleMaterialsAddressed,
          this.formGroup.controls.visionAddressed,
          this.formGroup.controls.communicationLanguageAddressed,
          this.formGroup.controls.assistiveTechnologyAddressed,
          this.formGroup.controls.healthAddressed,
          this.formGroup.controls.healthPlan,
          this.formGroup.controls.healthPlanCreate,
          this.formGroup.controls.hearingAddressed,
        ].some((x) => x.value)
      ) {
        this.formGroup.controls.allConsideredNoneSelected.setValue(false);
        this.formGroup.controls.allConsideredNoneSelected.updateValueAndValidity();
        this.formGroup.controls.allConsideredNoneSelected.disable();
      } else {
        this.formGroup.controls.allConsideredNoneSelected.enable();
      }
    };

    this.subscription.add(
      this.formGroup.controls.limitedEnglishAddressed.valueChanges.subscribe(() => {
        updateAllFactors();
      })
    );

    this.subscription.add(
      this.formGroup.controls.behaviorAddressed.valueChanges.subscribe((value) => {
        updateAllFactors();
        if (value) {
          this.formGroup.get('behaviorNoLongerFactor').setValue(null);
        }
      })
    );

    this.subscription.add(
      this.formGroup.controls.accessibleMaterialsAddressed.valueChanges.subscribe(() => {
        updateAllFactors();
      })
    );

    this.subscription.add(
      this.formGroup.controls.visionAddressed.valueChanges.subscribe(() => {
        updateAllFactors();
      })
    );

    this.subscription.add(
      this.formGroup.controls.communicationLanguageAddressed.valueChanges.subscribe(() => {
        updateAllFactors();
      })
    );

    this.subscription.add(
      this.formGroup.controls.assistiveTechnologyAddressed.valueChanges.subscribe(() => {
        updateAllFactors();
      })
    );

    this.subscription.add(
      this.formGroup.controls.healthAddressed.valueChanges.subscribe((val) => {
        updateAllFactors();
        if (!val) {
          const healthControls = ['healthPlan', 'healthPlanCreate'];
          healthControls.forEach((x) => {
            this.formGroup.get(x).setValue(false);
          });
        }
      })
    );

    this.subscription.add(
      this.formGroup.controls.healthPlan.valueChanges.subscribe(() => {
        updateAllFactors();
      })
    );

    this.subscription.add(
      this.formGroup.controls.healthPlanCreate.valueChanges.subscribe(() => {
        updateAllFactors();
      })
    );

    this.subscription.add(
      this.formGroup.controls.hearingAddressed.valueChanges.subscribe(() => {
        updateAllFactors();
      })
    );
  }

  openHelpWindow(section: SpecialFactorsSections, term: string) {
    this.helpService.openHelpModal({
      nestedTerm: `${section}.${term}`,
      area: HelpArea.SpecialFactors,
      canBrowse: false,
      width: '728px',
    } as HelpModalConfig);
  }

  onOpenUpload() {
    const dialogRef = this.dialog.open(UploadDocumentationModalComponent, {
      data: {
        title: 'Upload Individual Health Plan',
        fileName: 'Individual Health Plan',
        multiple: false,
        type: 'IndividualHealthPlan',
        learnersName: this.learnerService?.learnerSummary?.fullName,
      } as DialogData,
      width: '728px',
    });

    dialogRef.afterClosed().subscribe((result: Array<any>) => {
      if (result) {
        const formData = new FormData();
        result.forEach((element) => {
          formData.append('title', element.title);
          formData.append('document', element.file, element.file.name);
        });
        this.iepService
          .uploadIndividualHealthPlanDocument(formData, this.iepId)
          .subscribe((res) => this.notificationService.success('Upload Successful!'));
      }
    });
  }

  onBehaviorAddressedChange() {
    const behaviorAddressed = this.formGroup.get('behaviorAddressed');
    const sebControlNames = ['hasFba', 'fbaWillBeConducted', 'hasBip', 'bipWillBeDeveloped'];

    if (behaviorAddressed.value) {
      if (this.bipId || this.fbaId) {
        const dialogRef = this.dialog.open(AreYouSureComponent, {
          data: {
            subQuestion:
              'Unselecting this as a special factor will remove any associated Behavior Intervention Plan, and all associated entered data.',
          },
        });

        dialogRef.afterClosed().subscribe((val) => {
          if (val) {
            sebControlNames.forEach((x) => {
              this.formGroup.get(x).setValue(false);
            });
            this.onBipWillBeDevelopedChange(null, true);
            this.onFbaWillBeDevelopedChange(null, true);
          } else {
            behaviorAddressed.setValue(true);
          }
        });
      } else {
        sebControlNames.forEach((x) => {
          this.formGroup.get(x).setValue(false);
        });
      }
    }
  }

  onFbaWillBeDevelopedChange(event?, behaviorUnchecked = false) {
    if (event?.checked && !behaviorUnchecked) {
      if (this.fbaWillBeDevelopedRef) {
        this.fbaWillBeDevelopedRef._checked = !this.fbaWillBeDevelopedRef._checked;
      }
      this.setFbaWillBeDevelopedControlValue(true);
    }
  }

  setFbaWillBeDevelopedControlValue(checked: boolean) {
    this.formGroup.controls.fbaWillBeConducted.setValue(checked);
    this.formGroup.controls.fbaWillBeConducted.markAsDirty();
  }

  onBipWillBeDevelopedChange(event?, behaviorUnchecked = false) {
    if (!event?.checked || behaviorUnchecked) {
      if (this.bipId) {
        this.confirmBipNoLongerNeeded(behaviorUnchecked ? false : true);
      } else {
        this.setBipWillBeDevelopedControlValue(false);
      }
    } else {
      if (this.bipWillBeDevelopedRef) {
        this.bipWillBeDevelopedRef._checked = !this.bipWillBeDevelopedRef?._checked;
      }

      this.setBipWillBeDevelopedControlValue(true);
    }
  }

  confirmBipNoLongerNeeded(showDialog = true) {
    if (showDialog) {
      const dialogRef = this.dialog.open(AreYouSureComponent, {
        width: '450px',
        data: {
          subQuestion: 'Clicking Yes will remove Behavioral Intervention Plan from the current IEP.',
        },
      });
      dialogRef.afterClosed().subscribe((confirmed) => {
        if (confirmed) {
          this.formGroup.get('bipWillBeDeveloped').setValue(false);
          this.setBipWillBeDevelopedControlValue(false);
          this.bipService.deleteBip(this.bipId).subscribe((deleteBipResponse) => {
            if (deleteBipResponse.succeeded) {
              this.notificationService.success('BIP has been removed.');
              this.bipId = null;
            } else {
              this.notificationService.success('Failed to remove the current BIP.');
            }
          });
        } else {
          this.setBipWillBeDevelopedControlValue(true);
        }
      });
    } else {
      this.bipService.deleteBip(this.bipId).subscribe((deleteBipResponse) => {
        if (deleteBipResponse.succeeded) {
          this.notificationService.success('BIP has been removed.');
          this.bipId = null;
        } else {
          this.notificationService.success('Failed to remove the current BIP.');
        }
      });
    }
  }

  setBipWillBeDevelopedControlValue(checked: boolean) {
    this.formGroup.controls.bipWillBeDeveloped.setValue(checked);
    this.formGroup.controls.bipWillBeDeveloped.markAsDirty();
  }

  createBip() {
    if (this.fbaId) {
      this.dialog.open(IepCreateBipModalComponent, {
        data: {
          caseId: this.caseId,
          iepId: this.iepId,
          bipId: this.bipId,
          fbaId: this.fbaId,
        },
        width: '728px',
      });
    } else {
      const dialogRef = this.dialog.open(AreYouSureComponent, {
        data: {
          subQuestion:
            'It is recommended that a Functional Behavior Assessment is completed prior to the creation of a Behavior Intervention Plan. ' +
            'If you continue, you will be unable to pull data entered on the FBA. Are you sure you want to create a BIP?',
        },
        width: '728px',
      });
      dialogRef.afterClosed().subscribe((val) => {
        if (val) {
          this.iepService.createBip(this.iepId, false).subscribe(
            (operationResponse) => {
              if (operationResponse.succeeded) {
                this.router.navigateByUrl(`/cases/${this.caseId}/behavior-discipline/bip/${operationResponse.value.id}/overview`);
              } else {
                this.notificationService.error('Failed to create BIP.');
              }
            },
            (failedResponse) => {
              this.notificationService.error(failedResponse.error?.title + ': Unable to create BIP.');
            }
          );
        }
      });
    }
  }

  onCreateFba() {
    this.activeCall = true;
    const newFba: Fba = {
      id: null,
      caseId: this.caseId,
      nonAchieveMembers: [],
      fbaAssessment: null,
      hypothesisStatements: [],
      finalizedOn: null,
      status: FbaStatus.Draft,
      dataCollections: [],
      commonResponses: null,
      commonTriggers: null,
      otherInformationLearned: null,
      bipNeeded: null,
      timeLikelyEngaging: null,
      reviews: [],
    };

    this.fbaService.addFba(this.caseId, newFba).subscribe((fbaResponse) => {
      if (fbaResponse) {
        this.activeCall = false;
        this.router.navigate([`cases/${this.caseId}/behavior-discipline/fba/${fbaResponse.id}/overview`]);
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
