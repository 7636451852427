import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import dayjs from 'dayjs';
import { DialogComingSoonComponent } from '../../../../../shared/components/coming-soon/coming-soon.component';
import { shortDateFormat } from '../../../../../shared/dateTimeHelpers';
import { NotificationService } from '../../../../../shared/services/notification.service';
import { IepActivitySupportsViewMoreComponent } from '../../../../modals/iep-activity-supports-view-more/iep-activity-supports-view-more.component';
import { IepAmendmentEndItemModalComponent } from '../../../../modals/iep-amendment-end-item-modal/iep-amendment-end-item-modal.component';
import { TrialPlacementTarget } from '../../../../models/iep';
import { IepActivitySupportDto } from '../../../../models/iep-activity-support';
import { IepAmendment } from '../../../../models/iep-amendment';
import { IepActivitySupportService } from '../../../../services/iep-activity-support.service';
import { IepChangeProviderComponent } from '../../../../shared/iep-change-provider/iep-change-provider.component';
import { IepSsaaActivitiesSupportsComponent } from '../../../iep-services-supports-activities/components/iep-ssaa-activities-supports/iep-ssaa-activities-supports.component';
import { IepActivitiesInfoComponent } from './iep-activities-info/iep-activities-info.component';

@Component({
  selector: 'app-iep-details-activities-supports',
  templateUrl: './iep-details-activities-supports.component.html',
  styleUrls: ['./iep-details-activities-supports.component.scss'],
})
export class IepDetailsActivitiesSupportsComponent implements OnInit {
  iepId: string;
  @Input() amendments: IepAmendment[] = [];
  @Input() iepIncludesTrialPlacement;
  @Input() trialPlacementTarget: TrialPlacementTarget;

  @Output() trialPlacementChange = new EventEmitter();
  @ViewChild('activitySupportComponent')
  activitySupportComponent: IepSsaaActivitiesSupportsComponent;

  displayedColumns = ['actions', 'activitysupport', 'description', 'frequency', 'providers'];
  dataSource = new MatTableDataSource([]);

  shortDateFormat = shortDateFormat;
  showActivitySupportForm = false;
  amendingActivitySupport: IepActivitySupportDto;
  isEditing = false;
  trialPlacementTargetEnum = TrialPlacementTarget;

  get amendment() {
    return this.amendments?.length > 0 ? this.amendments[0] : null;
  }

  get amendmentIsFinalized() {
    return this.amendment?.finalizeDate !== null;
  }

  get lastFinalizedDate() {
    if (this.amendments && this.amendments.length > 0) {
      const latest = this.amendments.reduce((r, a) => {
        return r.finalizeDate > a.finalizeDate ? r : a;
      });
      return latest?.finalizeDate;
    }
    return null;
  }

  get hasOpenAmendment() {
    return !!this.amendment && !this.amendmentIsFinalized;
  }

  get amendmentId() {
    return this.amendment?.id;
  }

  get showTrialPlacementCheckBox(): boolean {
    return this.iepIncludesTrialPlacement && this.amendment.trialPlacementTarget === TrialPlacementTarget.SomeItems;
  }

  constructor(
    private route: ActivatedRoute,
    private iepActivitySupportService: IepActivitySupportService,
    private dialog: MatDialog,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.iepId = this.route.snapshot.paramMap.get('iepId');
    this.dataSource = new MatTableDataSource([]);
    this.getActivitySupports();

    this.iepActivitySupportService.activitySupportUpdated$.subscribe(() => this.getActivitySupports());
    this.iepActivitySupportService.activitySupportAddNew$.subscribe(() => {
      this.showActivitySupportForm = true;
      this.isEditing = true;
      this.amendingActivitySupport = null;
    });
    this.iepActivitySupportService.activitySupportClose$.subscribe(() => {
      this.showActivitySupportForm = false;
      this.isEditing = false;
    });
    this.dataSource.sortingDataAccessor = (item: IepActivitySupportDto, columnId) => {
      switch (columnId) {
        case 'frequency':
          return item.frequencyPeriod;
        case 'providers':
          return this.getProviderString(item?.providers);
        default:
          return item[columnId];
      }
    };
  }

  getActivitySupports() {
    this.iepActivitySupportService.getAllForAmendment(this.iepId, this.amendment?.id).subscribe((activitySupports) => {
      activitySupports.forEach((activitySupport) => {
        if (activitySupport.requireDeviceType) {
          activitySupport.requireDeviceType = activitySupport.requireDeviceType.split(',');
          activitySupport.requireDeviceType = activitySupport.requireDeviceType.filter((x) => x !== null && x !== '');
        } else {
          activitySupport.requireDeviceType = [];
        }
      });
      this.dataSource.data = activitySupports;
    });
  }

  addNew() {
    this.iepActivitySupportService.activitySupportAddNew.next();
  }

  anyEndDatesInPast(activitySupport) {
    const amendmentEndDate = dayjs(activitySupport.amendmentEndDate).toDate().setHours(23, 59, 59, 9999);
    const today = dayjs().toDate().setHours(0, 0, 0, 9999);
    return amendmentEndDate < today && activitySupport.isActive;
  }

  anyEndDatesEqualOrPast(activitySupport) {
    const amendmentEndDate = dayjs(activitySupport.amendmentEndDate).toDate().setHours(23, 59, 59, 9999);
    const today = dayjs().toDate().setHours(0, 0, 0, 9999);
    return amendmentEndDate <= today;
  }

  canAmend(element) {
    return this.hasOpenAmendment && !this.anyEndDatesEqualOrPast(element) && !this.isEditing;
  }

  canEnd(element) {
    return (
      this.hasOpenAmendment &&
      !this.anyEndDatesInPast(element) &&
      !element.amendmentEndDate &&
      (element?.isActive || (!element?.isActive && !!element?.priorVersionId))
    );
  }

  canRemove(element) {
    return (
      this.hasOpenAmendment && !this.anyEndDatesInPast(element) && !element?.priorVersionId && element.amendmentId === this.amendmentId
    );
  }

  canUndo(element) {
    return this.hasOpenAmendment && !this.anyEndDatesInPast(element) && element?.priorVersionId && element.amendmentId === this.amendmentId;
  }

  canIncludeTrialPlacement(element) {
    return (
      this.hasOpenAmendment &&
      !this.anyEndDatesEqualOrPast(element) &&
      !this.isEditing &&
      !element.includesTrialPlacement &&
      this.iepIncludesTrialPlacement
    );
  }

  canRemoveTrialPlacement(element) {
    return (
      this.hasOpenAmendment &&
      !this.anyEndDatesInPast(element) &&
      !this.isEditing &&
      element.includesTrialPlacement &&
      this.iepIncludesTrialPlacement
    );
  }

  canViewAuditLog(element) {
    return (
      this.hasOpenAmendment &&
      !this.anyEndDatesInPast(element) &&
      !this.isEditing &&
      (element?.isActive || element?.priorVersionId) &&
      !element?.amendmentEndDate
    );
  }

  onEdit(activitySupport: IepActivitySupportDto) {
    const callback = (_activitySupport: IepActivitySupportDto) => {
      this.showActivitySupportForm = true;
      this.amendingActivitySupport = _activitySupport;
      this.iepActivitySupportService.setAmendingActivitySupport.next(_activitySupport);
      this.isEditing = true;
    };

    if (activitySupport.amendmentId === null || activitySupport.amendmentId !== this.amendmentId) {
      this.iepActivitySupportService.amend(this.iepId, activitySupport.id, this.amendment.id).subscribe((amendResult) => {
        callback(amendResult);
      });
    } else {
      callback(activitySupport);
    }
  }

  viewAuditLog(activitySupport) {
    const dialogRef = this.dialog.open(IepActivitiesInfoComponent, {
      width: '758px',
      data: {
        activitySupport,
        id: activitySupport.id,
        iepId: this.iepId,
      },
    });
  }

  onChangeProviders(element) {
    const dialogRef = this.dialog.open(IepChangeProviderComponent, {
      data: {
        id: element.id,
        caseId: this.route.snapshot.paramMap.get('caseId'),
        iepId: this.iepId,
        type: 'IEPActivitySupport',
        dataElement: element,
      },
      width: '364px',
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.getActivitySupports();
      }
    });
  }

  updateTrialPlacement(element: IepActivitySupportDto, includedInTrialPlacement: boolean) {
    this.iepActivitySupportService.updateTrialPlacement(element.iepId, element.id, includedInTrialPlacement).subscribe(() => {});
    this.dataSource.data.find((x) => x.id === element.id).includesTrialPlacement = includedInTrialPlacement;
    this.trialPlacementChange.emit();
  }

  onEnd(element) {
    this.dialog.open(IepAmendmentEndItemModalComponent, {
      width: '740px',
      data: {
        iepId: this.iepId,
        amendmentId: this.amendment?.id,
        type: 'Activity/Support',
        model: element,
      },
    });
  }

  onRemove(activitySupport, isUndo = false) {
    this.notificationService.confirmation(
      `Are you sure${isUndo ? '? This will delete this change.' : ' you want to delete this activity/support?'} `,
      () => {
        this.iepActivitySupportService.deleteActivitySupport(this.iepId, activitySupport.id, true).subscribe(() => {
          this.notificationService.success(`Activity/Support ${isUndo ? 'reverted' : 'deleted'}`);
        });
      }
    );
  }

  openComingSoon() {
    this.dialog.open(DialogComingSoonComponent, {
      width: '990px',
    });
  }

  getProviderString(providers, otherProvider?: string) {
    let returnString = '';
    if (providers) {
      let providerCount = 0;
      providers.forEach((provider) => {
        providerCount++;
        let name = provider.firstName;
        if (provider.lastName) {
          name = name + ' ' + provider.lastName;
        }
        returnString += name;
        if (providers.length !== providerCount) {
          returnString += ', ';
        }
      });
    }

    if (otherProvider) {
      return returnString ? returnString + `, ${otherProvider}` : `${otherProvider}`;
    }

    return returnString;
  }

  onViewMore(el) {
    el.includesTrialPlacement = this.trialPlacementTarget === this.trialPlacementTargetEnum.AllItems || el.includesTrialPlacement;
    this.dialog.open(IepActivitySupportsViewMoreComponent, {
      width: '728px',
      data: {
        activitySupport: el,
        amendmentId: this.amendmentId,
        amendmentIsFinalized: this.amendmentIsFinalized,
        lastFinalizedDate: this.lastFinalizedDate,
      },
    });
  }
}
