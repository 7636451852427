<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  {{ title }}
</h3>

<div mat-dialog-content class="py-2">
  <form [formGroup]="formGroup" autocomplete="off">
    <div class="row">
      <ng-container *ngIf="!this.data.defaultValues?.type">
        <div class="col-md-12">
          <app-autocomplete label="Type of Consent" formControlName="type" [options]="availConsentFormTypeOptions"></app-autocomplete>
        </div>
      </ng-container>

      <div class="col-md-12" *ngIf="aeaFields.enabled" formGroupName="aea">
        <app-autocomplete [options]="aeasOptions" label="AEA" formControlName="aeaId"></app-autocomplete>
      </div>

      <div class="col-md-12" *ngIf="districtFields.enabled" formGroupName="district">
        <app-autocomplete [options]="districtsOptions" label="School District" formControlName="districtId"></app-autocomplete>
      </div>

      <div class="col-md-12" *ngIf="agencyFields.enabled" formGroupName="agency">
        <div class="row">
          <div class="col-lg">
            <app-text-field label="Clinic / Agency Name" formControlName="name"></app-text-field>
          </div>
          <div class="col-lg">
            <app-masked-input label="Phone" formControlName="phone" mask="(000) 000-0000"></app-masked-input>
          </div>
          <div class="col-lg">
            <app-masked-input label="Fax" formControlName="fax" mask="(000) 000-0000"> </app-masked-input>
          </div>
        </div>
        <div class="row">
          <div class="col-lg">
            <app-text-field label="Contact" formControlName="contact"></app-text-field>
          </div>
          <div class="col-lg">
            <app-text-field label="Email" formControlName="email"></app-text-field>
          </div>
        </div>
        <div class="row">
          <div class="col-lg">
            <app-text-field label="Address" formControlName="address"></app-text-field>
          </div>
          <div class="col-lg">
            <app-text-field label="Zip Code" (blurMe)="populateLocationFromZipCode()" formControlName="zipCode"></app-text-field>
          </div>
          <div class="col-lg">
            <app-text-field label="City / Town" formControlName="city"></app-text-field>
          </div>
          <div class="col-lg">
            <app-autocomplete [options]="usStates" label="State" formControlName="state"></app-autocomplete>
          </div>
        </div>
      </div>

      <div class="col-md-12" *ngIf="reevaluationFields.enabled">
        <input type="hidden" formControlName="reevaluationId" />
        <button mat-raised-button aria-label="Complete Form" tabindex="0" (click)="openReevaluationForm()">Complete Form</button>
      </div>

      <ng-container *ngIf="!hideAll">
        <div class="col-md-12">
          <app-textarea formControlName="notes" label="Notes" [rows]="4"></app-textarea>
        </div>

        <div class="col-md-12">
          <app-autocomplete label="Status" formControlName="status" [options]="familyConsentStatusOptions"></app-autocomplete>
        </div>
        <div class="col-md-12">
          <app-date-picker
            controlName="dateReceived"
            [max]="today"
            label="{{ isNotDeniedOrRequested ? 'Date Received' : 'Date' }}"
          ></app-date-picker>
        </div>
        <ng-container *ngIf="isNotDeniedOrRequested">
          <div class="col-md-12">
            <app-text-field formControlName="signedBy" label="Signed By"> </app-text-field>
          </div>
          <div class="col-md-12" *ngIf="parentTypeField.enabled" formGroupName="parentType">
            <app-select formControlName="parentType" label="Parent Type" [options]="parentTypeOptions"></app-select>
          </div>
          <div class="col-md-12">
            <app-date-picker controlName="dateSigned" [max]="today" label="Date Signed"></app-date-picker></div
        ></ng-container>
      </ng-container>
    </div>
  </form>
</div>

<div mat-dialog-action class="display-flex justify-content-end align-items-center mt-4">
  <div class="display-flex">
    <button mat-raised-button aria-label="Cancel Button" class="background-color--muted mr-2" (click)="onCancel()" tabindex="0">
      Cancel
    </button>
    <button mat-raised-button aria-label="Complete" color="primary" (click)="onSave()" [disabled]="formGroup.invalid" tabindex="0">
      Complete
    </button>
  </div>
</div>
