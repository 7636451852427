import { Component, Input, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import dayjs from 'dayjs';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MeetingRollCallComponent } from 'src/app/shared/components/meeting-roll-call/meeting-roll-call.component';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { FamilyMeetingRead } from 'src/app/shared/models/case';
import { CaseService } from 'src/app/shared/services/case/case.service';
import { LearnerService } from 'src/app/shared/services/learner/learner.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { ReportingService } from 'src/app/shared/services/reporting/reporting.service';
import { openDocumentWindow, openPdfWindow, openPopup } from 'src/app/shared/windowHelpers';
import { AuthService } from '../../../auth/auth.service';
import { AppPermissions } from '../../../permissions';
import { AreYouSureComponent } from '../../../shared/components/are-you-sure-modal/are-you-sure.component';
import {
  DialogData,
  UploadDocumentationModalComponent,
} from '../../../shared/modals/upload-documentation-modal/upload-documentation-modal.component';
import { FileDocument } from '../../../shared/models/file-document';
import { KeyValuePair } from '../../../shared/models/key-value-pair';
import { DocumentService } from '../../../shared/services/document/document.service';
import { PrintDocumentsModalComponent } from '../../documentation/print-documents-modal/print-documents-modal.component';
import { FamilyMeetingService } from '../../family-meeting/family-meeting.service';

@Component({
  selector: 'app-family-documents',
  templateUrl: './family-documents.component.html',
  styleUrls: ['./family-documents.component.scss'],
})
export class FamilyDocumentsComponent implements OnInit, OnDestroy {
  @Input() learnerId: string;
  @Input() learnerHasWorkableCase = true;
  @Input() documentUpdated;
  documentUpdatedSubscription: Subscription;
  displayedColumns: string[] = ['actions', 'type', 'status', 'submittedOn'];
  dataSource = new MatTableDataSource<any>();
  familyMeetings: FamilyMeetingRead[] = [];
  caseId: string;
  isReadOnly = true;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  shortDateFormat = shortDateFormat;
  documents: any = [];
  formGroup: FormGroup;
  activeCall = false;
  statusOptions: KeyValuePair[] = [
    new KeyValuePair('', 'All'),
    new KeyValuePair('Complete', 'Complete'),
    new KeyValuePair('Incomplete', 'Incomplete'),
  ];

  get hasUploadPermission() {
    return this.authService.isAllowed(AppPermissions.UploadDocuments);
  }

  get hasSurrogatePermission() {
    return this.authService.canEditSurrogates;
  }

  get hasDocumentDeletePermission() {
    return this.authService.isSuperAdmin || this.authService.isDataLead || this.authService.isDataTechnician;
  }

  constructor(
    private readonly formBuilder: FormBuilder,
    private ngZone: NgZone,
    private dialog: MatDialog,
    private documentService: DocumentService,
    public authService: AuthService,
    private caseService: CaseService,
    private familyMeetingService: FamilyMeetingService,
    private readonly reportingService: ReportingService,
    private readonly notificationService: NotificationService,
    private readonly learnerService: LearnerService
  ) {}

  ngOnDestroy(): void {
    this.documentUpdatedSubscription?.unsubscribe();
    this.documentUpdatedSubscription = null;
  }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      type: [''],
      status: [''],
      quickDate: new FormControl({
        dateShortcut: 'AllTime',
        startDate: dayjs().subtract(100, 'year').startOf('day').toDate(),
        endDate: dayjs().add(2, 'day').startOf('day').toDate(),
      }),
    });

    this.dataSource.filterPredicate = (data, filter: any) => {
      const status = data.isSubmitted ? 'Complete' : 'Incomplete';
      if (filter.status && filter.status !== status) {
        return false;
      }

      if (filter.quickDate.dateShortcut !== 'AllTime') {
        const createdOn = dayjs(data.createdOn).startOf('day').toDate();
        if (createdOn < filter.quickDate.startDate || createdOn > filter.quickDate.endDate) {
          return false;
        }
      }
      return true;
    };

    this.formGroup.valueChanges.pipe(distinctUntilChanged(), debounceTime(250)).subscribe((value) => {
      this.dataSource.filter = value;
    });

    this.loadData();
  }

  async loadData() {
    this.documentUpdatedSubscription?.unsubscribe();
    this.documentUpdatedSubscription = this.documentUpdated?.subscribe(async () => {
      let data = await this.documentService.getAllForLearner(this.learnerId).toPromise();
      if (!this.hasSurrogatePermission) {
        data = data.filter((x) => x.fileName !== `surrogate-parent-confirmation_${this.learnerId}.pdf`);
      }
      this.dataSource.data = data;
    });

    let data = await this.documentService.getAllForLearner(this.learnerId).toPromise();
    if (!this.hasSurrogatePermission) {
      data = data.filter((x) => x.fileName !== `surrogate-parent-confirmation_${this.learnerId}.pdf`);
    }
    this.dataSource.data = data;
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'status':
          return item.isSubmitted;
        case 'submittedOn':
          return item.submittedOn ? item.submittedOn : item.createdOn;
        default:
          return item[property];
      }
    };

    this.sort.sort({ id: 'submittedOn', start: 'desc' } as MatSortable);
    this.dataSource.sort = this.sort;
    this.getFamilyMeeting();
  }

  onOpenPrintDocuments(e) {
    e.stopPropagation();
    this.dialog.open(PrintDocumentsModalComponent, {
      width: '728px',
      data: {
        documents: this.dataSource.data.filter((x) => !x.isUploaded),
      },
    });
  }

  getMeeting(familyMeetingId: string) {
    return this.familyMeetings.find((x) => x.id === familyMeetingId);
  }

  getDocStatus(element: FileDocument) {
    const status =
      element.isSubmitted && (element.type === 'Disability Suspected' || element.type === 'Manifestation Determination')
        ? 'Complete'
        : !element.isSubmitted && (element.type === 'Disability Suspected' || element.type === 'Manifestation Determination')
        ? 'Incomplete'
        : element.isUploaded
        ? 'Uploaded'
        : element.isSubmitted && element.type === 'Meeting Notice'
        ? 'Meeting Held'
        : !element.isSubmitted && element.type === 'Meeting Notice' && element.related?.status === 'Rescheduled'
        ? 'Meeting Rescheduled'
        : !element.isSubmitted && element.type === 'Meeting Notice' && element.related?.status === 'Cancelled'
        ? 'Meeting Canceled'
        : !element.isSubmitted && element.type === 'Meeting Notice'
        ? 'Meeting Pending'
        : element.isSubmitted && element.type === 'Behavior Intervention Plan'
        ? 'Complete'
        : !element.isSubmitted && element.type === 'Behavior Intervention Plan (Discontinued)'
        ? 'Discontinued'
        : element.isSubmitted
        ? 'Complete'
        : 'Incomplete';

    return status;
  }

  openRollCallModal(meeting: FamilyMeetingRead, caseId: string, readOnly = false) {
    const isReadOnly = this.isReadOnly || readOnly;
    this.caseService.getCaseSummary(caseId).subscribe((caseSummary) => {
      const dialogRef = this.dialog.open(MeetingRollCallComponent, {
        width: '1100px',
        data: {
          meeting,
          caseSummary,
          isReadOnly,
        },
      });
    });
  }

  getFamilyMeeting() {
    this.familyMeetingService.getMeetings(this.learnerId).subscribe((familyMeetings) => {
      this.familyMeetings = familyMeetings;
    });
  }

  onUploadDocuments(e) {
    this.activeCall = true;
    e.stopPropagation();

    const dialogRef = this.dialog.open(UploadDocumentationModalComponent, {
      data: {
        title: 'Upload',
        learnersName: this.learnerService?.learnerSummary?.fullName,
      } as DialogData,
      width: '728px',
    });

    dialogRef.afterClosed().subscribe((result: Array<any>) => {
      if (result) {
        const formData = new FormData();
        result.forEach((element) => {
          formData.append('titles', element.title);
          formData.append('documents', element.file, element.file.name);
          formData.append('types', 'User');
        });
        this.documentService.uploadDocuments(formData, this.learnerId).subscribe((newDocuments) => {
          this.activeCall = false;
          newDocuments.forEach((x) => (x.isUploaded = true));
          this.dataSource.data = [...this.dataSource.data, ...newDocuments];
        });
      } else {
        this.activeCall = false;
      }
    });
  }

  editItem(element: FileDocument) {
    if (element.type === 'Disability Suspected' && (element.canEdit || element.canView || element.canViewCompleted)) {
      openPopup(element.url);
      return;
    }

    if (element.type === 'Manifestation Determination') {
      if (!element.isSubmitted) {
        this.reportingService.createMdOutput(element.id).subscribe((docId) => {
          openPdfWindow(this.learnerId, docId);
        });
      } else {
        openPdfWindow(this.learnerId, element.additionalId);
      }
      return;
    }

    if (element.isUploaded) {
      if (element.type === 'User' && element.fileName.includes('pdf')) {
        openPdfWindow(this.learnerId, element.id);
      }

      return openDocumentWindow(this.learnerId, element.id);
    }

    switch (element.type) {
      case 'PWN':
        if (!element.url) {
          this.reportingService.createPartBPwnOutput(element.id).subscribe({
            next: (documentId: string) => openPdfWindow(this.learnerId, documentId),
            error: (err) =>
              this.notificationService.errorWithAction("Couldn't open output", 'Why?', () =>
                this.notificationService.alert(err.error, "Couldn't open output")
              ),
            complete: () => {},
          });
        } else {
          openPdfWindow(this.learnerId, element.id);
        }
        break;
      default:
        openPdfWindow(this.learnerId, element.id);
        break;
    }
  }

  editPwn(fileDocument: FileDocument) {
    if (fileDocument.isSubmitted) {
      openPdfWindow(this.learnerId, fileDocument.id);
    } else {
      const window = openPopup(fileDocument.url);
      window.addEventListener('beforeunload', (ev) => {
        this.ngZone.run(() => {
          this.loadData();
        });
      });
    }
  }

  deleteUserDocument(documentId: string) {
    const dialogRef = this.dialog.open(AreYouSureComponent, {
      width: '450px',
      data: {
        question: 'Are you sure?',
        subQuestion: 'Are you sure you want to delete this file?',
      },
    });
    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.documentService.deleteDocument(documentId).subscribe(
          () => {
            this.dataSource.data = this.dataSource.data.filter((d) => d.id != documentId);
          },
          () => {
            this.notificationService.error('Failed to delete file.');
          }
        );
      }
    });
  }
}
