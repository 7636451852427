import { Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { LearnerSummary } from '../../../models/learner';
import { RoutingService } from '../../../services/routing.service';

@Component({
  selector: 'app-learner-info',
  templateUrl: 'learner-info.component.html',
  styleUrls: ['learner-info.component.scss'],
})
export class LearnerInfoComponent {
  @ContentChild('primaryInfo', { read: TemplateRef })
  public primaryInfoTemplate: TemplateRef<any>;
  @ContentChild('secondaryInfo', { read: TemplateRef })
  public secondaryInfoTemplate: TemplateRef<any>;

  @Input() public learnerSummary: LearnerSummary;
  @Input() public condensed = false;
  @Input() public isPrint = false;
  @Input() public fewFields = false;
  @Input() public showAgeInMonths = false;
  @Input() public showFamilyMembersInPrimary = false;
  @Input() public showLearnerLink = true;

  shortDateFormat = shortDateFormat;

  get surrogates() {
    return this.learnerSummary.surrogates.filter((x) => x.discontinuedOn === null);
  }

  constructor(private readonly routingService: RoutingService) {}

  getLearnerManagementLink() {
    return this.routingService.learnerDashboardPath(this.learnerSummary.id);
  }
}
