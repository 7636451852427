import { AfterViewInit, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox/checkbox';
import { usStateOptions, yesNoOptions } from 'src/app/shared/formHelpers';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { FamilyMemberType } from 'src/app/shared/models/learner';
import { LocationService } from 'src/app/shared/services/location/location.service';
import * as AppValidators from 'src/app/shared/validators';

@Component({
  selector: 'app-family-member',
  templateUrl: './family-member.component.html',
  styleUrls: ['./family-member.component.scss'],
})
export class FamilyMemberComponent implements OnChanges, AfterViewInit {
  @Input() familyMemberType: FamilyMemberType;
  @Input() formGroup: FormGroup;
  @Input() parentForm: FormGroup;
  @Input() cardTitle: string;
  @Input() isParent = false;
  @Input() referralHowHearAboutUsOptions: KeyValuePair[];
  @Input() howHearAboutUsOtherId: string;
  @Input() relationshipOptions: KeyValuePair[];
  @Input() isPk: boolean;
  @Input() isFromFamilyContact: boolean;
  @Input() isPartCLearner = false;

  usStateOptions = usStateOptions;
  yesNoOptions = yesNoOptions;
  familyMemberTypes = [
    new KeyValuePair(FamilyMemberType.Parent1, 'Parent 1'),
    new KeyValuePair(FamilyMemberType.Parent2, 'Parent 2'),
    new KeyValuePair(FamilyMemberType.LivesWith1, 'Lives With 1'),
    new KeyValuePair(FamilyMemberType.LivesWith2, 'Lives With 2'),
    new KeyValuePair(FamilyMemberType.Relative, 'Relative'),
    new KeyValuePair(FamilyMemberType.Friend, 'Friend'),
    new KeyValuePair(FamilyMemberType.Guardian, 'Guardian'),
    new KeyValuePair(FamilyMemberType.Learner, 'Learner'),
  ];

  get livesWithChild() {
    return this.formGroup.get('livesWithChild')?.value;
  }

  get referralSource() {
    return this.parentForm.controls.referralSource as FormControl;
  }

  get referralSourceHowHearAboutUsId() {
    return this.parentForm.controls.referralSourceHowHearAboutUsId as FormControl;
  }

  get referralSourceEarlyAccessOtherText() {
    return this.parentForm.controls.referralSourceEarlyAccessOtherText as FormControl;
  }

  get legalGuardian() {
    return this.parentForm.controls.legalGuardian as FormControl;
  }

  get exchangeInformation() {
    return this.parentForm.controls.exchangeInformation as FormControl;
  }

  get isEdit() {
    return this.cardTitle === 'Edit';
  }

  get filteredRelationshipOptions() {
    if (this.formGroup.controls.id.value !== null && this.formGroup.controls.type.value !== FamilyMemberType.Learner) {
      return this.relationshipOptions.filter((r) => r.value !== FamilyMemberType.Learner);
    }
    return this.relationshipOptions;
  }

  constructor(private locationService: LocationService) {}

  ngAfterViewInit() {
    const ctrl = this.formGroup.get('livesWithChild');
    if (ctrl) {
      ctrl.valueChanges.subscribe(this.parentLivesWithChildChanged.bind(this));
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.formGroup) {
      if (this.isFromFamilyContact) {
        const checkParent = (x: FamilyMemberType) => {
          this.isParent = x === FamilyMemberType.Parent1 || x === FamilyMemberType.Parent2;
        };

        checkParent(this.formGroup.controls.type.value);

        this.formGroup.controls.type.valueChanges.subscribe((x: FamilyMemberType) => {
          checkParent(x);
        });
      }
    }
  }

  updateReferralSource(event: MatCheckboxChange) {
    this.referralSource.markAsDirty();
    if (event.checked) {
      (this.parentForm.get('parent1') as FormGroup).get('isPersonCompletingReferral').setValue(null);
      (this.parentForm.get('parent2') as FormGroup).get('isPersonCompletingReferral').setValue(null);
      (this.parentForm.get('livesWith1') as FormGroup).get('isPersonCompletingReferral').setValue(null);
      (this.parentForm.get('livesWith2') as FormGroup).get('isPersonCompletingReferral').setValue(null);
      this.formGroup.controls.isPersonCompletingReferral.setValue(true);
      this.referralSource.setValue(this.familyMemberType);
    } else {
      this.referralSource.setValue(null);
      this.formGroup.controls.isPersonCompletingReferral.setValue(null);
    }
    this.referralSourceHowHearAboutUsId.setValue(null);
  }

  populateLocationFromZipCode() {
    const zipCodeCtrl = this.formGroup.get('zipCode');
    if (zipCodeCtrl.valid && zipCodeCtrl.value) {
      this.locationService.getLocationData(zipCodeCtrl.value).subscribe((res) => {
        if (res) {
          this.formGroup.patchValue({
            city: res.city,
            county: res.county,
            state: 'IA',
          });
        }
      });
    }
  }

  parentLivesWithChildChanged() {
    setTimeout(() => {
      if (this.formGroup.get('livesWithChild').value === null) {
        return;
      }
      if (this.formGroup.get('livesWithChild').value) {
        this.removeParentExtraAddressValidators();
      } else {
        this.addParentExtraAddressValidators();
      }
    }, 0);
  }

  private removeParentExtraAddressValidators() {
    const fieldNames = ['streetAddress', 'city', 'zipCode', 'state'];
    fieldNames.forEach((f) => {
      const control = this.formGroup.get(f);
      control?.clearValidators();
      control?.updateValueAndValidity();
    });
  }

  private addParentExtraAddressValidators() {
    const fieldNames = ['streetAddress', 'city', 'state'];
    fieldNames.forEach((f) => {
      const control = this.formGroup.get(f);
      control.setValidators([Validators.required]);
      control.updateValueAndValidity();
    });
    this.formGroup.get('zipCode').setValidators([Validators.required, AppValidators.zipCodeValidator]);
    this.formGroup.get('zipCode').updateValueAndValidity();
  }
}
