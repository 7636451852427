import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription, forkJoin, of } from 'rxjs';
import { catchError, debounceTime, pairwise, startWith, takeUntil } from 'rxjs/operators';
import { IepActivitySupportService } from 'src/app/iep/services/iep-activity-support.service';
import { LearnerSummary } from 'src/app/shared/models/learner';
import { ProviderUser } from 'src/app/shared/models/user';
import { CaseService } from 'src/app/shared/services/case/case.service';
import { NewWindowConfig, openNewWindow } from 'src/app/shared/windowHelpers';
import { BaseComponent } from '../../../../../shared/components/base-component/base-component';
import { NotifySave } from '../../../../../shared/decorators/notify-save.decorator';
import { KeyValuePair } from '../../../../../shared/models/key-value-pair';
import { ServiceActivityType, ServiceActivityTypeCategory } from '../../../../../shared/models/service-activity-type';
import { DeactivationService } from '../../../../../shared/services/deactivation.service';
import { NotificationService } from '../../../../../shared/services/notification.service';
import { UserService } from '../../../../../shared/services/user/user.service';
import { TrialPlacementTarget } from '../../../../models/iep';
import { IepActivitySupportDto } from '../../../../models/iep-activity-support';
import { IepAmendment } from '../../../../models/iep-amendment';
import { ServiceActivityTypeQuestion } from '../../../../models/iep-service';
import { IepService } from '../../../../services/iep.service';
import { IepActivitiesSupportsTableComponent } from '../shared/iep-activities-supports-table/iep-activities-supports-table.component';

enum DeviceSettings {
  Home = 'Home',
  School = 'School',
  Other = 'Other',
}

@Component({
  selector: 'app-iep-ssaa-activities-supports',
  templateUrl: './iep-ssaa-activities-supports.component.html',
  styleUrls: ['./iep-ssaa-activities-supports.component.scss'],
})
export class IepSsaaActivitiesSupportsComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild('formDirective') private formDirective: NgForm;
  @ViewChild('activitySupportForEdit') activitySupportForEdit: ElementRef<any>;
  @ViewChild('activitySupportList')
  activitySupportList: IepActivitiesSupportsTableComponent;

  @Input() isGoalsPage = false;
  @Input() amendmentId: string;
  @Input() amendments: IepAmendment[];
  @Input() amendingActivitySupport: IepActivitySupportDto;
  @Input() iepIncludesTrialPlacement: boolean;
  @Input() trialPlacementTarget: TrialPlacementTarget;
  trialPlacementTargetEnum = TrialPlacementTarget;
  showOtherSettingField: boolean;
  aemIsSpecialFactor: boolean;
  aTIsSpecialFactor: boolean;
  unModifiedActivitySupport: IepActivitySupportDto;
  activeCall = false;
  learner: LearnerSummary;

  get amendment() {
    return this.amendments?.length > 0 ? this.amendments[0] : null;
  }

  get amendmentIsFinalized() {
    return this.amendment?.finalizeDate !== null;
  }

  get lastFinalizedDate() {
    if (this.amendments && this.amendments.length > 0) {
      const latest = this.amendments.reduce((r, a) => {
        return r.finalizeDate > a.finalizeDate ? r : a;
      });
      return latest?.finalizeDate;
    }
    return null;
  }

  get hasAmendment() {
    return !!this.amendmentId;
  }

  get hasPriorVersion() {
    return !!this.amendingActivitySupport?.priorVersionId;
  }

  activitySupport: IepActivitySupportDto;
  public iepId: string;
  public caseId: string;
  public activitySupportId: string;
  private submitted$ = new Subject();
  selectedSASType: ServiceActivityType[];
  isNew = true;
  autosaveSubscription = new Subscription();
  private stopAutosaving$ = new Subject();

  formGroup = new FormGroup({
    activitySupportTypeId: new FormControl('', Validators.required),
    description: new FormControl(null, Validators.required),
    frequencyPeriod: new FormControl(null, Validators.required),
    providers: new FormControl([], Validators.required),
    requireDeviceType: new FormControl([], Validators.required),
    otherDeviceSetting: new FormControl(null),
    deviceRequireAEM: new FormControl(null, Validators.required),
    otherProvider: new FormControl(null),
    otherProviderName: new FormControl(null),
    otherProviderAgency: new FormControl(null),
    otherProviderRole: new FormControl(null),
    includesTrialPlacement: new FormControl(null),
    taggedForPwn: new FormControl(null),
  });
  frequencyPeriodOptions: KeyValuePair[] = [
    new KeyValuePair('Daily', 'Daily'),
    new KeyValuePair('Weekly', 'Weekly'),
    new KeyValuePair('Monthly', 'Monthly'),
    new KeyValuePair('Yearly', 'Yearly'),
    new KeyValuePair('As outlined in the description', 'As outlined in the description'),
  ];
  providerOptions: KeyValuePair[] = [];
  sasTypes: ServiceActivityType[] = [];
  sasOptions: KeyValuePair[] = [];
  providers: ProviderUser[] = [];

  requireDeviceTypeOptions: KeyValuePair[] = [
    new KeyValuePair(DeviceSettings.Home, DeviceSettings.Home),
    new KeyValuePair(DeviceSettings.School, DeviceSettings.School),
    new KeyValuePair(DeviceSettings.Other, DeviceSettings.Other),
  ];

  yesNoOptions: KeyValuePair[] = [new KeyValuePair(true, 'Yes'), new KeyValuePair(false, 'No')];

  get isAssistiveEducationalMaterials() {
    return (
      this.formGroup.get('activitySupportTypeId').value === this.sasOptions.find((x) => x.value === 'Accessible Educational Materials')?.key
    );
  }

  get isAssistiveTechnologySupport() {
    return (
      this.formGroup.get('activitySupportTypeId').value === this.sasOptions.find((x) => x.value === 'Assistive Technology Supports')?.key
    );
  }

  get isLinkagesInterAgencyResponsibilities() {
    return (
      this.formGroup.get('activitySupportTypeId').value ===
      this.sasOptions.find((x) => x.value === 'Linkages/Inter-agency Responsibilities')?.key
    );
  }

  get serviceActivityTypeQuestion(): typeof ServiceActivityTypeQuestion {
    return ServiceActivityTypeQuestion;
  }

  get showRequireDeviceType() {
    return this.hasQuestion(this.serviceActivityTypeQuestion.RequireDeviceType);
  }

  get showDeviceRequireAEM() {
    return this.hasQuestion(this.serviceActivityTypeQuestion.DeviceRequireAEM);
  }

  constructor(
    private caseService: CaseService,
    private userService: UserService,
    private notificationService: NotificationService,
    private iepActivitySupportService: IepActivitySupportService,
    private iepService: IepService,
    private route: ActivatedRoute,
    private router: Router,
    private cd: ChangeDetectorRef,
    deactivationService: DeactivationService
  ) {
    super(deactivationService);
  }

  async ngOnInit() {
    this.caseId = this.route.parent?.snapshot.paramMap.get('caseId');
    this.iepId = this.route.parent?.snapshot.paramMap.get('iepId');
    if (!this.caseId) {
      this.caseId = this.route.snapshot.paramMap.get('caseId');
    }
    if (!this.iepId) {
      this.iepId = this.route.snapshot.paramMap.get('iepId');
    }

    this.iepService.getPlaafp(this.iepId).subscribe((plaafp) => {
      this.aemIsSpecialFactor = plaafp.accessibleMaterialsAddressed;
      this.aTIsSpecialFactor = plaafp.assistiveTechnologyAddressed;
    });

    await this.caseService.refreshCaseSummary(this.caseId);
    this.learner = this.caseService.caseSummary.learner;

    forkJoin([
      this.iepService.getPartBSASTypes(ServiceActivityTypeCategory.Activity),
      this.userService.getProvidersByAea(this.learner?.id).pipe(
        catchError(() => {
          return of([]);
        })
      ),
    ]).subscribe(([sasTypes, providers]) => {
      this.providers = providers;
      this.sasTypes = sasTypes;
      const otherOption = this.sasTypes.find((x) => x.label.indexOf('Other') > -1);
      const otherOptionIndex = this.sasTypes.findIndex((x) => x.label.indexOf('Other') > -1);
      if (otherOptionIndex > -1) {
        this.sasTypes.splice(otherOptionIndex, 1);
        this.sasTypes.push(otherOption);
      }
      this.sasOptions = this.sasTypes.map((x) => new KeyValuePair(x.id, x.label));
      this.providerOptions = providers.map((x) => new KeyValuePair(x.id, x.fullName));
      this.isNew = true;

      if (this.amendingActivitySupport) {
        this.onEdit(this.amendingActivitySupport);
      }
      this.iepActivitySupportService.setAmendingActivitySupport$.subscribe((result) => {
        this.amendingActivitySupport = result;
        this.onEdit(this.amendingActivitySupport);
      });

      this.resetTrialPlacementControl();

      this.startAutosaving();
      this.setupFormSubscriptions();
    });

    this.formGroup.controls.requireDeviceType.valueChanges.subscribe((types) => {
      const ctrl = this.formGroup.controls.otherDeviceSetting;
      if (types?.includes(DeviceSettings.Other)) {
        this.showOtherSettingField = true;
        ctrl.setValidators([Validators.required]);
      } else {
        this.showOtherSettingField = false;
        ctrl.clearValidators();
      }
      ctrl.updateValueAndValidity();
    });
  }

  viewSummary() {
    const config: NewWindowConfig = {
      path: `cases/${this.caseId}/iep/${this.iepId}/services/summary`,
      popup: true,
      width: '1480px',
    };
    openNewWindow(config);
  }

  onEdit(activitySupport: IepActivitySupportDto) {
    this.unModifiedActivitySupport = Object.assign({}, activitySupport);
    this.isNew = false;
    this.activitySupport = activitySupport;
    this.activitySupportId = activitySupport.id;
    const patchObj = Object.assign({}, this.activitySupport);
    patchObj.providers = patchObj.providers.map((x) => x.userId);
    if (patchObj.requireDeviceType !== null && patchObj.requireDeviceType instanceof String) {
      patchObj.requireDeviceType = patchObj?.requireDeviceType?.split(',');
    }

    // Few milliseconds delay is needed for the app-autocomplete control to properly bind data.
    setTimeout(() => {
      this.formGroup.patchValue(patchObj);
      this.setSelectedSASType();
      this.cd.detectChanges();
      this.scrollToSelectMethod(this.activitySupportForEdit);
      this.startAutosaving();
    }, 100);
  }

  saveAndClose() {
    this.activeCall = true;
    const callback = () => {
      this.resetForm();
      this.iepActivitySupportService.activitySupportClose.next();
      this.startAutosaving();
      this.activeCall = false;
    };
    this.stopAutosaving$.next(undefined);
    this.makeSaveRequest({ callback });
  }

  submitAndAddGoal() {
    const callback = () => this.router.navigate([`cases/${this.caseId}/iep/${this.iepId}/goals`]);
    this.onSubmit(callback);
  }

  onSubmit(callback?: () => void) {
    if (this.formGroup.valid) {
      this.stopAutosaving$.next(undefined);
      this.isNew = true;
      this.makeSaveRequest({
        submitting: true,
        callback,
      });
    }
  }

  submit() {
    this.activeCall = true;
    const callback = () => {
      this.iepActivitySupportService.activitySupportClose.next();
      this.startAutosaving();
      this.activeCall = false;
    };
    this.onSubmit(callback);
  }

  onDelete() {
    if (!this.activitySupportId || (this.hasAmendment && this.hasPriorVersion)) {
      this.stopAutosaving$.next(undefined);
      this.iepActivitySupportService.activitySupportClose.next();
      this.resetForm();
      return;
    }

    const deleteIt = () => {
      this.stopAutosaving$.next(undefined);
      this.iepActivitySupportService.deleteActivitySupport(this.iepId, this.activitySupportId).subscribe(() => {
        this.notificationService.success('Activity/Support deleted');
        this.resetForm();
        this.isNew = true;
        this.startAutosaving();
      });
    };

    if (this.isNew) {
      deleteIt();
    } else {
      this.notificationService.confirmation('Are you sure you want to delete this Activity/Support?', deleteIt);
    }
  }

  onCancel() {
    this.stopAutosaving$.next(undefined);
    const closeFormCallback = () => {
      this.resetForm();
      this.iepActivitySupportService.activitySupportClose.next();
      this.startAutosaving();
    };
    if (this.isNew && !this.hasPriorVersion) {
      closeFormCallback();
    } else {
      if (this.unModifiedActivitySupport) {
        this.onEdit(this.unModifiedActivitySupport);
      }

      setTimeout(() => {
        this.makeSaveRequest({
          submitting: true,
          callback: closeFormCallback,
        });
      }, 300);
    }
  }

  tag(tagFlag: boolean) {
    this.formGroup.controls.taggedForPwn.setValue(tagFlag);
    this.formGroup.controls.taggedForPwn.updateValueAndValidity();
    this.formGroup.markAsDirty();
  }

  resetForm() {
    this.formGroup.reset();
    this.formDirective.resetForm();
    this.formGroup.get('providers').setValue([]);
    this.activitySupport = null;
    this.activitySupportId = null;

    this.resetTrialPlacementControl();
  }

  resetTrialPlacementControl() {
    const isAllTrialPlacementSelected =
      this.iepIncludesTrialPlacement && this.trialPlacementTarget === this.trialPlacementTargetEnum.AllItems;
    this.formGroup.controls.includesTrialPlacement.setValue(isAllTrialPlacementSelected);
  }

  @NotifySave
  private async makeSaveRequest({ submitting = false, callback = null, showNotification = true }): Promise<void> {
    const service = {
      id: this.activitySupportId,
      ...this.formGroup.value,
      isComplete: submitting,
      amendmentId: this.amendmentId,
    };

    service.providers = this.providers
      .filter((x) => this.formGroup.get('providers').value.includes(x.id))
      .map((p) => {
        return {
          userId: p.id,
        };
      });

    const updateResult = await this.iepActivitySupportService.update(this.iepId, service).toPromise();
    this.activitySupportId = updateResult.value;

    if (submitting) {
      this.resetForm();
      if (showNotification) {
        this.notificationService.success('Completed Activity and Support successfully');
      }
      this.scrollToSelectMethod(this.activitySupportForEdit);
      this.startAutosaving();
    }
    if (callback) {
      callback();
    }
  }

  startAutosaving() {
    this.autosaveSubscription.unsubscribe();
    this.autosaveSubscription = this.formGroup.valueChanges
      .pipe(debounceTime(2000), takeUntil(this.stopAutosaving$))
      .subscribe(async () => {
        if (this.formGroup.dirty && this.formGroup.get('activitySupportTypeId').valid) {
          await this.makeSaveRequest({});
        }
      });
  }

  setSelectedSASType() {
    const activitySupport = this.formGroup.get('activitySupportTypeId').value;
    if (activitySupport) {
      this.selectedSASType = this.sasTypes.filter((x) => activitySupport.includes(x.id));
    }
  }

  setupFormSubscriptions() {
    this.subscriptions.add(
      this.formGroup
        .get('activitySupportTypeId')
        .valueChanges.pipe(startWith(''), pairwise())
        .subscribe(([prev, activitySupport]) => {
          this.setSelectedSASType();
          if (activitySupport) {
            this.resetAdditionalQuestionValidators();

            const requireDeviceTypeCtrl = this.formGroup.controls.requireDeviceType;
            if (this.hasQuestion(this.serviceActivityTypeQuestion.RequireDeviceType)) {
              requireDeviceTypeCtrl.setValidators([Validators.required]);
            } else {
              requireDeviceTypeCtrl.setValue([], { emitEvent: false });
            }
            requireDeviceTypeCtrl.updateValueAndValidity();

            const deviceRequireAEMCtrl = this.formGroup.controls.deviceRequireAEM;
            if (this.hasQuestion(this.serviceActivityTypeQuestion.DeviceRequireAEM)) {
              deviceRequireAEMCtrl.setValidators([Validators.required]);
            } else {
              deviceRequireAEMCtrl.setValue(null, { emitEvent: false });
            }
            deviceRequireAEMCtrl.updateValueAndValidity();

            if (prev === activitySupport) {
              return;
            }

            this.cd.detectChanges();
          }
        })
    );

    this.subscriptions.add(
      this.formGroup.get('otherProvider').valueChanges.subscribe((isOther) => {
        const providersControl = this.formGroup.get('providers');
        const providerNameControl = this.formGroup.get('otherProviderName');
        if (isOther) {
          providersControl.clearValidators();
          providerNameControl.setValidators(Validators.required);
        } else {
          providerNameControl.setValue(null);
          this.formGroup.get('otherProviderAgency').setValue(null);
          this.formGroup.get('otherProviderRole').setValue(null);
          providersControl.setValidators(Validators.required);
          providerNameControl.clearValidators();
        }
        providersControl.updateValueAndValidity({ emitEvent: false });
        providerNameControl.updateValueAndValidity({ emitEvent: false });
      })
    );
  }

  resetAdditionalQuestionValidators() {
    this.formGroup.get('requireDeviceType').clearValidators();
    this.formGroup.get('deviceRequireAEM').clearValidators();
  }

  hasQuestion(question: ServiceActivityTypeQuestion) {
    let returnVar = false;
    if (!this.selectedSASType) {
      return false;
    }
    for (const sasType of this.selectedSASType) {
      const questionIndex = sasType.additionalQuestions.findIndex((x) => x.question === question);
      if (questionIndex > -1) {
        returnVar = true;
        break;
      }
    }

    return returnVar;
  }

  getActivitySupportTypeLabel(value) {
    return this.sasOptions.find((x) => x.key === value)?.value;
  }

  getFrequencyPeriodLabel(value) {
    return this.frequencyPeriodOptions.find((x) => x.key === value)?.value;
  }

  getRequireDeviceTypeLabel(rdt) {
    const rdtLabels = [];
    if (Array.isArray(rdt) && rdt.length > 0) {
      rdt.forEach((_rdt) => {
        const rdtFind = this.requireDeviceTypeOptions.find((x) => x.key === _rdt);

        if (rdtFind) {
          rdtLabels.push(rdtFind.value);
        }
      });
    }

    return rdtLabels.join(', ');
  }

  getYesNoLabel(value) {
    return this.yesNoOptions.find((x) => x.key === value)?.value;
  }

  getProviderLabels(providers) {
    const providerLabels = [];

    if (providers) {
      providers.forEach((_provider) => {
        const providerFind = this.providerOptions.find((x) => x.key === _provider);

        if (providerFind) {
          providerLabels.push(providerFind.value);
        }
      });
    }

    return providerLabels.join(', ');
  }

  getPriorProviderLabels(providers) {
    const providerLabels = [];

    if (providers) {
      providers.forEach((_provider) => {
        const providerFind = this.providerOptions.find((x) => x.key === _provider.userId);

        if (providerFind) {
          providerLabels.push(providerFind.value);
        }
      });
    }

    return providerLabels.join(', ');
  }

  scrollToSelectMethod(element: ElementRef<any>) {
    element?.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  ngOnDestroy(): void {
    if (this.formGroup.dirty) {
      this.makeSaveRequest({
        submitting: false,
        callback: null,
        showNotification: false,
      });
    }
    super.ngOnDestroy();
  }
}
