<mat-label
  *ngIf="customLabel && label"
  class="custom-label"
  [ngClass]="{
    asterisk_input: control.hasError('required') || showAsterisk,
    'asterisk_input--hide': hideAsterisk
  }"
  >{{ label }}</mat-label
>
<mat-form-field
  class="w-100"
  *ngIf="!readOnly || showReadOnlyControl; else readOnlyTemplate"
  [ngClass]="{ 'mb-2': control.value?.length >= maxLength }"
>
  <mat-label
    *ngIf="!customLabel && label"
    [ngClass]="{
      asterisk_input: control.hasError('required') || showAsterisk,
      'asterisk_input--hide': hideAsterisk
    }"
    >{{ label }}</mat-label
  >
  <textarea
    matInput
    [class]="klass"
    id="{{ controlId }}"
    [formControl]="control"
    autocomplete="off"
    [placeholder]="placeholder"
    [attr.maxLength]="noMaxLength === false && maxLength > 0 ? maxLength : null"
    [readonly]="readOnly"
    [attr.disabled]="disabledState ? true : null"
    (blur)="internalBlur($event)"
    [rows]="rows ? rows : 2"
    attr.aria-required="{{ control.hasError('required') || showAsterisk ? 'true' : 'false' }}"
  ></textarea>
  <span matSuffix *ngIf="isBankableField">
    <button type="button" mat-icon-button aria-label="Bankable Text Icon" (click)="openBankableFieldOptions()" tabindex="0">
      <mat-icon class="bankable-icon">bookmark_add</mat-icon>
    </button>
  </span>
  <span matPrefix *ngIf="useInputGroup">
    <ng-content select="[prepend]"></ng-content>
  </span>
  <span matSuffix *ngIf="useInputGroup">
    <ng-content select="[append]"></ng-content>
  </span>
  <mat-error>
    <app-field-error-display [control]="control"></app-field-error-display>
  </mat-error>
  <mat-hint *ngIf="control.value?.length >= maxLength && !noMaxLength"
    ><p class="mb-5">Max character limit reached of {{ maxLength }}</p>
  </mat-hint>
  <mat-hint *ngIf="this.maxLength && this.showCharacterCount && !noMaxLength" align="end"
    >{{ control.value?.length || 0 }} / {{ this.maxLength }}</mat-hint
  >
</mat-form-field>

<ng-template #readOnlyTemplate>
  <p class="read-only-container">
    <strong *ngIf="label">{{ label }}</strong
    >{{ control.value }}
  </p>
</ng-template>
