import { AsyncSubject } from 'rxjs';
import { Base64Document } from './models/base64Document';

export function toBase64(file: File, title: string): AsyncSubject<Base64Document[]> {
  const result = new AsyncSubject<any>();
  if (file) {
    const url = URL.createObjectURL(file);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      result.next({
        fileName: file?.name,
        title: title,
        url: url,
        base64: reader?.result as string,
        contentType: file.type,
      });
      result.complete();
    };
    return result;
  } else {
    result.next(null);
    result.complete();
    return result;
  }
}

export function base64ToBlobUrl(base64: string, contentType: string): string {
  const byteCharacters = atob(base64.split(',')[1]);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: contentType });
  return URL.createObjectURL(blob);
}

export function toBase64WithBlobUrl(file: File, title: string): AsyncSubject<any[]> {
  const result = new AsyncSubject<any>();
  if (file) {
    const url = URL.createObjectURL(file);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      const base64 = reader?.result as string;
      const contentType = file.type;
      const blobUrl = base64ToBlobUrl(base64, contentType);
      result.next({
        fileName: file?.name,
        title: title,
        url: url,
        base64: base64,
        contentType: file.type,
        blobUrl: blobUrl,
      });
      result.complete();
    };
    return result;
  } else {
    result.next(null);
    result.complete();
    return result;
  }
}
