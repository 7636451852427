import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { UploadModalComponent } from '../upload-modal/upload-modal.component';
import { toBase64 } from 'src/app/shared/base64Helpers';

@Component({
  selector: 'app-document-upload-button',
  templateUrl: './document-upload-button.component.html',
  styleUrls: ['./document-upload-button.component.scss'],
})
export class DocumentUploadButtonComponent implements OnInit, OnDestroy {
  @Input() buttonText = 'Upload';
  @Input() allowedFileTypes = 'TXT, CSV, PDF, DOC, DOCX, ODT, PPTX, PPT, RTF, XLS, XLSX, PNG, JPEG, JPG, GIF, BMP';
  @Input() documentationTypes: KeyValuePair[];
  @Input() fileName: string;
  @Input() outputType: 'FormData' | 'Base64' = 'FormData';

  @Output() uploaded = new EventEmitter<any>();

  private modalSub: Subscription;

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {}

  openModal(): void {
    const dialogRef = this.dialog.open(UploadModalComponent, {
      data: {
        title: 'Upload Documentation',
        fileName: this.fileName,
        allowedFileTypes: this.allowedFileTypes,
        documentationTypes: this.documentationTypes,
      },
      width: '728px',
    });

    this.modalSub = dialogRef.afterClosed().subscribe(async (result: Array<any>) => {
      if (result) {
        if (this.outputType === 'FormData') {
          const formData = new FormData();
          result.forEach((element) => {
            formData.append('titles', element.title);
            formData.append('documents', element.file, element.file.name);
            formData.append('types', element.type);
            formData.append('DocumentTitles', element.title);
            formData.append('FileNames', element.file.name);
            formData.append('FileTypes', element.type);
            formData.append('Files', element.file, element.file.name);
          });
          this.uploaded.emit(formData);
        } else if (this.outputType === 'Base64') {
          const formFiles = [];
          for (const element of result) {
            const file = await toBase64(element.file as File, element.title).toPromise();
            formFiles.push(file);
          }
          this.uploaded.emit(formFiles);
        }
      }
    });
  }

  ngOnDestroy(): void {
    if (this.modalSub) {
      this.modalSub.unsubscribe();
    }
  }
}
