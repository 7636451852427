<app-learner-info [learnerSummary]="learnerSummary" [isPrint]="isPrint">
  <ng-template #primaryInfo>
    <app-key-value-label key="Grade" [value]="learnerSummary?.gradeDescription || '-'"> </app-key-value-label>
    <app-key-value-label class="text-md" key="State ID" [value]="learnerSummary.stateAssignedId || '-'"> </app-key-value-label>
    <app-key-value-label class="text-md" key="Resident District" [value]="learnerSummary.residentDistrict || '-'"> </app-key-value-label>
    <app-key-value-label class="text-md" key="Attending District" [value]="learnerSummary?.attendingDistrict || '-'"> </app-key-value-label>
    <app-key-value-label class="text-md" key="AEA" [value]="learnerSummary?.attendingAea || '-'"> </app-key-value-label>
    <app-key-value-label class="text-md" key="Attending Building" [value]="learnerSummary?.attendingBuilding || '-'"> </app-key-value-label>
  </ng-template>
  <ng-template #secondaryInfo>
    <app-key-value-label class="text-md" *ngIf="learnerSummary?.gender" key="Gender" [value]="learnerSummary?.gender">
    </app-key-value-label>
    <app-key-value-label
      class="text-md"
      *ngIf="learnerSummary?.iepInformation?.iepTeamContact"
      key="IEP Team Contact Name"
      [value]="learnerSummary?.iepInformation.iepTeamContact"
    >
    </app-key-value-label>
    <!-- Do not use dateFormat pipe here as it produces a result a day earlier than expected -->
    <app-key-value-label
      class="text-md"
      key="Date of 14th Birthday"
      [value]="fourteenthBirthday | date : shortDateFormat"
    ></app-key-value-label>
    <app-key-value-label
      class="text-md"
      key="Date Rights Transfer"
      [value]="rightsTransferDate | date : shortDateFormat"
    ></app-key-value-label>
    <app-key-value-label class="text-md" key="Review Due by Date" [value]="reviewByDate"> </app-key-value-label>
    <app-key-value-label class="text-md" key="Reevaluation Due by Date" [value]="learnerSummary.reevaluationDueOn | dateFormat">
    </app-key-value-label>
    <app-key-value-label class="text-md" key="Data Source" [value]="learnerSummary?.sifSource || '-'"></app-key-value-label>
    <app-key-value-label
      class="text-md"
      key="Data Updated On"
      [value]="(learnerSummary?.sifUpdatedOn | dateFormat) || '-'"
    ></app-key-value-label>
  </ng-template>
</app-learner-info>
