import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion, MatExpansionPanel } from '@angular/material/expansion';
import { ActivatedRoute, Router } from '@angular/router';
import dayjs from 'dayjs';
import { Subscription } from 'rxjs';
import { debounceTime, pairwise, startWith } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { AppPermissions } from 'src/app/permissions';
import { IncompleteDataReportItem } from 'src/app/shared/components/incomplete-data-report/incomplete-data-report-item';
import { ProceduralSafeguardsRead } from 'src/app/shared/components/procedural-safeguards/procedural-safeguards';
import { addWeekdays, shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { CaseSummary, FamilyMeetingRead } from 'src/app/shared/models/case';
import { CaseService } from 'src/app/shared/services/case/case.service';
import { I3IepHelp } from 'src/app/shared/services/help/models/i3.help';
import { RoutingService } from 'src/app/shared/services/routing.service';
import { TransitionPlanningService } from '../../../learner-management/transition-planning/services/transition-planning.service';
import { AreYouSureComponent } from '../../../shared/components/are-you-sure-modal/are-you-sure.component';
import { StringSizes } from '../../../shared/components/form/constants/constants';
import { NotifySave } from '../../../shared/decorators/notify-save.decorator';
import { KeyValuePair } from '../../../shared/models/key-value-pair';
import { NotificationService } from '../../../shared/services/notification.service';
import { IepSurveyInviteCreatorModalComponent } from '../../modals/iep-survey-invite-creator-modal/iep-survey-invite-creator-modal.component';
import { IepAddUpdate, IepType, IepView, TrialPlacementTarget } from '../../models/iep';
import { IepService } from '../../services/iep.service';
import { FeatureFlagService } from 'src/app/shared/components/feature-flags/feature-flag.service';
import { FeatureFlags } from 'src/app/shared/components/feature-flags/feature-flags';

@Component({
  selector: 'app-iep-profile',
  templateUrl: './iep-profile.component.html',
  styleUrls: ['./iep-profile.component.scss'],
})
export class IepProfileComponent implements OnInit {
  @ViewChildren(MatAccordion) serviceAccordions: QueryList<MatAccordion>;
  @ViewChild('consent') private consentPanel: MatExpansionPanel;
  @ViewChild('consent', { read: ElementRef })
  private consentPanelElem: ElementRef;
  @ViewChild('proceduralSafeguardPanel')
  private proceduralSafeguardPanel: MatExpansionPanel;
  @ViewChild('proceduralSafeguardPanel', { read: ElementRef })
  private proceduralSafeguardPanelElem: ElementRef;

  private subscriptions = new Subscription();
  dataLoaded = false;
  psgLoaded = false;
  i3IepHelp = I3IepHelp;
  isAccordionsExpanded = false;
  caseId: string;
  caseSummary: CaseSummary;
  iepId: string;
  formGroup = this.fb.group({
    includesTrialPlacement: [null],
    trialPlacementDate: [null, { updateOn: 'change' }],
    trialPlacementTarget: [null],
    transitionDelayReasonId: [{ value: null, disabled: true }, Validators.required],
    lateEvaluationRationale: [{ value: null, disabled: true }, Validators.required],
  });
  providedOn: Date;
  iepMeetings: FamilyMeetingRead[];
  mostRecentProceduralSafeguard: ProceduralSafeguardsRead;
  incompleteItems: IncompleteDataReportItem[];
  iep: IepView;
  notFinalized: boolean;
  shortDateFormat = shortDateFormat;
  permissions = AppPermissions;
  iepSurveysSent = 1;
  firstIepMeetingRollCallDate: Date;
  fourtyfiveWeekdaysFromNow = addWeekdays(dayjs(), 45);
  durationDate = dayjs().startOf('day').add(1, 'year');
  maxTrialPlacementDate = this.durationDate.toDate();
  iepTypeInitial = IepType.Initial;
  tenDaysPrior = dayjs().startOf('day').add(-10, 'day').toDate();
  allowIvrs: boolean;
  stringSizes = StringSizes;

  trialPlacementOptions: KeyValuePair[] = [
    new KeyValuePair(TrialPlacementTarget.AllItems, 'All Services and Supports'),
    new KeyValuePair(TrialPlacementTarget.SomeItems, 'Some Services and Supports'),
  ];

  delayReasonOptions: Array<KeyValuePair>;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private iepService: IepService,
    private caseService: CaseService,
    public authService: AuthService,
    public routingService: RoutingService,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private transitionPlanningService: TransitionPlanningService,
    private featureFlagService: FeatureFlagService,
    private router: Router
  ) {}

  async ngOnInit(): Promise<void> {
    this.caseId = this.route.parent?.snapshot.paramMap.get('caseId');
    this.iepId = this.route.parent?.snapshot.paramMap.get('iepId');
    this.allowIvrs = this.featureFlagService.featureOn(FeatureFlags.ShowIvrsBanner);
    this.caseSummary = await this.caseService.getCaseSummary(this.caseId).toPromise();
    this.delayReasonOptions = await this.transitionPlanningService.delayReasons(this.caseSummary?.learner?.isMigrated).toPromise();

    const iepResponse = await this.getIep();
    if (!iepResponse) return;

    this.getIepMeetings();
    this.loadFormData();

    if (this.formGroup.get('includesTrialPlacement').value) {
      this.updateTrialPlacementDateValidator(this.formGroup.get('includesTrialPlacement').value);
    }

    this.subscriptions.add(
      this.formGroup.controls.includesTrialPlacement.valueChanges.subscribe((value) => {
        this.updateTrialPlacementDateValidator(value);
      })
    );
  }

  getIepMeetings() {
    this.iepService.getIepMeetings(this.caseId).subscribe((res) => {
      this.iepMeetings = res;
      this.firstIepMeetingRollCallDate = this.iepMeetings?.filter((x) => x.status != 'Cancelled')?.reverse()[0]?.meetingRollCallDate;
    });
  }

  onMostRecentProceduralSafeguardChanged(proceduralSafeguard: ProceduralSafeguardsRead) {
    this.mostRecentProceduralSafeguard = proceduralSafeguard;
    if (this.dataLoaded && this.psgLoaded) {
      this.getIncompleteItems();
    }
    if (!proceduralSafeguard) {
      this.proceduralSafeguardPanel.open();
    } else {
      if (dayjs(proceduralSafeguard.providedOn).isBefore(dayjs().subtract(3, 'month'))) {
        this.proceduralSafeguardPanel.open();
      } else {
        this.proceduralSafeguardPanel.close();
      }
    }
    this.psgLoaded = true;
  }

  toggleAccordion() {
    this.isAccordionsExpanded = !this.isAccordionsExpanded;
    this.serviceAccordions.forEach((a) => (this.isAccordionsExpanded ? a.openAll() : a.closeAll()));
  }

  refreshIncompleteItems(e: MouseEvent) {
    e.stopPropagation();
    this.getIncompleteItems();
    this.getIepMeetings();
  }

  refreshData() {
    this.getIepMeetings();
    this.getIncompleteItems();
  }

  onSendSurvey() {
    const dialogRef = this.dialog.open(IepSurveyInviteCreatorModalComponent, {
      width: '728px',
      data: {
        teamMembers: this.caseSummary.caseUsers.map((caseUser) => new KeyValuePair(caseUser.id, caseUser.fullName)),
        learnerId: this.caseSummary.learnerId,
        iepId: this.iepId,
        caseSummary: this.caseSummary,
        isFirstIep: this.iep.isFirstIep,
      },
    });
    dialogRef.afterClosed().subscribe(() => {});
  }

  private async getIep(): Promise<boolean> {
    const iep = await this.iepService
      .get(this.iepId)
      .toPromise()
      .catch((error: HttpErrorResponse) => {
        if (error.status === HttpStatusCode.NotFound) {
          this.router.navigate(['/not-found']);
        }
        return undefined;
      });
    if (iep === undefined) return false;
    this.iep = iep;
    this.notFinalized = !this.iep.activatedOn;
    this.setDelayReasonAvailability();
    this.setLateEvaluationRationale();
    return true;
  }

  private loadFormData(): void {
    this.formGroup.patchValue(this.iep, { emitEvent: false });
    if (this.iep.durationDate) {
      this.durationDate = dayjs(this.iep.durationDate);
      let max: Date;
      if (this.fourtyfiveWeekdaysFromNow.isBefore(this.durationDate)) {
        max = this.fourtyfiveWeekdaysFromNow.toDate();
      } else {
        max = this.durationDate.toDate();
      }
      if (max !== this.maxTrialPlacementDate) {
        this.maxTrialPlacementDate = max;
      }
    }
    this.addSubscriptions();
  }

  private getIncompleteItems() {
    this.iepService.getIncompleteDataReport(this.iepId).subscribe((res) => {
      this.incompleteItems = res;
      this.dataLoaded = true;
    });
  }

  scheduleMeeting(): void {
    this.routingService.openScheduleMeeting(this.caseSummary.learnerId);
  }

  scrollAndOpenConsent() {
    this.consentPanel.open();
    this.consentPanelElem.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  scrollAndOpenProceduralSafeguards() {
    this.proceduralSafeguardPanel.open();
    this.proceduralSafeguardPanelElem.nativeElement.scrollIntoView({
      behavior: 'smooth',
    });
  }

  private setDelayReasonAvailability(): void {
    if (this.iep.delayed) {
      this.formGroup.get('transitionDelayReasonId').enable();
    } else {
      this.formGroup.get('transitionDelayReasonId').disable();
    }
  }

  private setLateEvaluationRationale() {
    if (this.iep.showLateEvaluationRationale) {
      this.formGroup.get('lateEvaluationRationale').enable();
    } else {
      this.formGroup.get('lateEvaluationRationale').disable();
    }
  }

  private updateTrialPlacementDateValidator(includesTrialPlacement: boolean) {
    const trialPlacementDate = this.formGroup.get('trialPlacementDate');
    if (includesTrialPlacement) {
      trialPlacementDate.setValidators(Validators.required);
    } else {
      trialPlacementDate.clearValidators();
      trialPlacementDate.setValue(null);
      this.formGroup.get('trialPlacementTarget').setValue(null);
      this.formGroup.get('transitionDelayReasonId').setValue(null);
    }
    trialPlacementDate.updateValueAndValidity({ emitEvent: false });
  }

  @NotifySave
  private async saveIep(iep: IepAddUpdate): Promise<IepView> {
    const result = await this.iepService.updateIEP(iep).toPromise();
    if (!result.succeeded) {
      this.notificationService.error("IEP couldn't be updated.");
    }
    return result.value;
  }

  private addSubscriptions() {
    this.subscriptions.add(this.iepService.iepUpdated$.subscribe(() => this.getIep()));
    this.subscriptions.add(
      this.formGroup.controls.trialPlacementDate.valueChanges.subscribe(() => {
        if (!this.formGroup.controls.includesTrialPlacement.value) {
          this.formGroup.controls.trialPlacementDate.setValue(null, {
            emitEvent: false,
          });
          this.formGroup.controls.trialPlacementTarget.setValue(null, {
            emitEvent: false,
          });
        }

        if (this.formGroup.controls.includesTrialPlacement.value) {
          if (dayjs(this.formGroup.get('trialPlacementDate').value).isAfter(this.fourtyfiveWeekdaysFromNow)) {
            const dialogRef = this.dialog.open(AreYouSureComponent, {
              data: {
                subQuestion: 'This date is greater than 45 week days. Are you sure this is the correct date?',
              },
            });
            dialogRef.afterClosed().subscribe(async (res) => {
              if (!res) {
                this.formGroup.get('trialPlacementDate').setValue(null, { emitEvent: false });

                this.iep = await this.saveIep({
                  id: this.iep.id,
                  ...this.formGroup.value,
                } as IepAddUpdate);
              }
            });
          }
        }
      })
    );
    this.subscriptions.add(
      this.formGroup.valueChanges.pipe(debounceTime(1000), startWith(''), pairwise()).subscribe(async ([prev, next]) => {
        if (!this.iep) {
          return;
        }

        const updatedIep = {
          id: this.iep.id,
          ...this.formGroup.value,
        } as IepAddUpdate;

        this.iep = await this.saveIep(updatedIep);
        if (prev.trialPlacementTarget !== next.trialPlacementTarget && this.dataLoaded) {
          this.getIncompleteItems();
        }
      })
    );
    this.getIncompleteItems();
  }
}
