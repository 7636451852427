<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Are you sure?</h3>
<ng-container [formGroup]="formGroup">
  <div mat-dialog-content class="py-3">
    <mat-card class="my-0">
      <p>Are you sure you want to continue?</p>
      <p>If either of the following conditions apply, please check an applicable option.</p>
      <p>
        <app-checkbox-single
          formControlName="movingFromOutOfState"
          attr.aria-label="{{ learnersName }} is moving in from out-of-state and has an active IEP."
          label="{{ learnersName }} is moving in from out-of-state and has an active IEP."
        ></app-checkbox-single>
        <app-checkbox-single
          formControlName="eligibleInLegacySystem"
          attr.aria-label="{{ learnersName }} was found eligible in the legacy system."
          label="{{ learnersName }} was found eligible in the legacy system."
        ></app-checkbox-single>
      </p>
    </mat-card>
  </div>

  <div mat-dialog-actions class="display-flex justify-content-end align-items-center">
    <button mat-raised-button aria-label="Cancel Button" (click)="onCancel()" cdkFocusInitial type="button" class="ml-2" tabindex="0">
      Cancel
    </button>
    <button
      mat-raised-button
      aria-label="Submit"
      color="primary"
      class="ml-2"
      [isBusy]="activeCall"
      (click)="onSubmit()"
      tabindex="0"
      [disabled]="formGroup.invalid"
    >
      Submit
    </button>
  </div>
</ng-container>
