import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  Self,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ControlContainer, FormControl, FormGroupDirective, NgControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AutocompleteGroup } from 'src/app/shared/models/autocomplete-group';
import { KeyValuePair } from '../../../models/key-value-pair';
import { LoggerService } from '../../../services/logger/logger.service';
import { BaseWrapperComponent } from '../base-wrapper.component';
import { StringSizes } from '../constants/constants';

interface GroupIndexItem {
  allIndex: number;
  optionIndex: number;
  option: KeyValuePair;
}

@Component({
  selector: 'app-autocomplete-chips-groups',
  templateUrl: './autocomplete-chips-groups.component.html',
  styleUrls: ['./autocomplete-chips-groups.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class AutocompleteChipsGroupsComponent extends BaseWrapperComponent implements OnInit, OnChanges {
  @ViewChild('optionInput') optionInput: ElementRef<HTMLInputElement>;
  @ViewChild(MatAutocompleteTrigger) autoComplete: MatAutocompleteTrigger;

  @Output() selectChip = new EventEmitter<any>();
  @Output() removeChip = new EventEmitter<any>();
  @Output() selected = new EventEmitter<{ value: Array<string>; group: string }>();
  allOptions: AutocompleteGroup[] = [];
  readOnlyOptions: GroupIndexItem[] = [];
  @Input()
  set options(value: AutocompleteGroup[]) {
    this.allOptions = value;
  }
  @Input() max: number;
  @Input() showAsterisk: boolean;
  stringSizes = StringSizes;

  optionAutocompleteChipsCtrl = new FormControl('');
  chipListCtrl = new FormControl([]);
  filteredOptions: Observable<AutocompleteGroup[]>;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(
    logger: LoggerService,
    @Self()
    @Optional()
    ngControl: NgControl,
    changeDetectorRef: ChangeDetectorRef
  ) {
    super(logger, ngControl, changeDetectorRef);
  }

  ngOnInit() {
    super.ngOnInit();

    if (this.allOptions) {
      this.chipListCtrl.setValue(
        this.allOptions.flatMap((group) => group.options.filter((option) => this.control.value.includes(option.key)))
      );
    }

    this.setFilteredOptions();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.options && changes.options.currentValue !== changes.options.previousValue) {
      this.allOptions = changes.options.currentValue;
      if (this.control.value) {
        this.chipListCtrl.setValue(
          this.allOptions.filter((group) => group.options.filter((option) => this.control.value.includes(option.key)))
        );
      }
      this.setFilteredOptions();
    }
  }

  setFilteredOptions() {
    this.filteredOptions = this.optionAutocompleteChipsCtrl.valueChanges.pipe(
      startWith(''),
      map((value) => this.filterGroup(value))
    );
  }

  private filterGroup(value: string): AutocompleteGroup[] {
    if (value && typeof value === 'string') {
      return this.allOptions
        .map((group) => ({
          label: group.label,
          options: this.filter(group.options, value),
        }))
        .filter((group) => group.options.length > 0);
    }

    return this.allOptions;
  }

  private filter(opt: KeyValuePair[], value: string): KeyValuePair[] {
    const filterValue = value?.toLowerCase();
    return opt.filter((item) => item.value.toLowerCase().indexOf(filterValue) === 0);
  }

  onRemove(option, index): void {
    const options = this.control.value;
    this.control.setValue([...options.slice(0, index), ...options.slice(index + 1)]);

    const chipOptions = this.chipListCtrl.value;
    this.chipListCtrl.setValue([...chipOptions.slice(0, index), ...chipOptions.slice(index + 1)]);

    const item = this.readOnlyOptions.find((x) => x.option.key === option.key);
    if (item) {
      this.allOptions[item.allIndex].options.splice(item.optionIndex, 0, item.option);
    }

    this.selected.emit(this.control.value);
    this.removeChip.emit(option);

    this.setFilteredOptions();
    setTimeout(() => {
      this.autoComplete.closePanel();
    }, 5);
  }

  selectedChip(event: MatAutocompleteSelectedEvent): void {
    if (this.max && (this.chipListCtrl.value as Array<KeyValuePair>).length >= this.max) {
      return;
    }
    const option = event.option.value;
    const options = this.control.value;
    this.control.setValue([...options, option.key]);

    const indexArray = this.allOptions.map((x) => x.options.indexOf(option));
    indexArray.forEach((index, i) => {
      if (index > -1) {
        this.readOnlyOptions.push({ allIndex: i, optionIndex: index, option });
        this.allOptions[i].options.splice(index, 1);
      }
    });

    const chipOptions = this.chipListCtrl.value;
    this.chipListCtrl.setValue([...chipOptions, option]);

    this.optionInput.nativeElement.blur();
    this.optionInput.nativeElement.value = '';
    this.optionAutocompleteChipsCtrl.setValue(null);
    this.selected.emit({ value: this.control.value, group: event.option?.group?.label });
    this.selectChip.emit(event.option.value);
  }
}
